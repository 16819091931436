import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/icons/svg/feide.svg'
import _imports_1 from '../../assets/icons/svg/canvas.svg'


const _hoisted_1 = {
  key: 0,
  class: "w-24",
  src: _imports_0,
  alt: "feide logo"
}
const _hoisted_2 = {
  key: 1,
  class: "w-24",
  src: _imports_1,
  alt: "canvas logo"
}
const _hoisted_3 = {
  key: 2,
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 144.2 69.6",
  class: "fill-current w-6"
}
const _hoisted_4 = {
  key: 0,
  d: "M3.5,31.3h127.3L105.4,6c-1.4-1.4-1.4-3.6,0-4.9c1.4-1.4,3.6-1.4,4.9,0l33.8,33.8l-33.8,33.8c-0.7,0.7-1.6,1-2.5,1\n\ts-1.8-0.3-2.5-1c-1.4-1.4-1.4-3.6,0-4.9l25.3-25.3H3.5c-1.9,0-3.5-1.6-3.5-3.5C0,32.9,1.6,31.3,3.5,31.3z"
}
const _hoisted_5 = {
  key: 1,
  d: "M140.7,31.3H13.4L38.7,6c1.4-1.4,1.4-3.6,0-4.9c-1.4-1.4-3.6-1.4-4.9,0L0,34.8l33.8,33.8c0.7,0.7,1.6,1,2.5,1s1.8-0.3,2.5-1\n\tc1.4-1.4,1.4-3.6,0-4.9L13.4,38.3h127.3c1.9,0,3.5-1.6,3.5-3.5S142.6,31.3,140.7,31.3z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["flex justify-center items-center py-2 rounded-full border pointer-events-auto", [
      _ctx.textcolour,
      _ctx.backgroundcolour && !_ctx.disabled ? _ctx.backgroundcolour : '',
      _ctx.disabled ? 'text-gray-400 bg-viva-grey-450' : '',
      _ctx.childclass ? _ctx.childclass : 'w-56',
    ]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.logo == 'feide')
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("v-if", true),
    (_ctx.logo == 'canvas')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("v-if", true),
    (_ctx.logo != '')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_3, [
          (_ctx.logo === 'rightArrow')
            ? (_openBlock(), _createElementBlock("path", _hoisted_4))
            : _createCommentVNode("v-if", true),
          (_ctx.logo === 'leftArrow')
            ? (_openBlock(), _createElementBlock("path", _hoisted_5))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}