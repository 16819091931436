
import { defineComponent, PropType, ref, Ref, computed, watch, toRefs } from 'vue'
import { Game, Sett, SpecialRequestData } from '@/types/main'
import { consentStates, masteryBaselineID } from '@/constants'
import { useGameStore } from '@/store/useGameStore'
import SetResultItem from './SetResultItem.vue'
import SlButton from '@/components/base/SlButton.vue'
// import SelectionBox from '@/components/base/SelectionBox.vue'

export default defineComponent({
  name: 'MonitorParticipantMastery',
  components: {
    SetResultItem,
    SlButton,
    // SelectionBox,
  },
  props: {
    game: {
      type: Object as PropType<Game>,
      required: true,
    },
    cmsroot: {
      type: Object as PropType<Sett[]>,
      required: true,
    },
    results: {
      type: Object as PropType<SpecialRequestData['data']>,
      required: true,
    },
  },
  setup(props) {
    const { cmsroot } = toRefs(props)
    const {
      actions: gameActions,
      // getters: gameGetters,
    } = useGameStore()

    const showSave = ref(false)
    const g = new Game(props.game)
    const theGame = ref(g)
    const selectedSet: Ref<HAA | undefined> = ref()

    // Create a structure to track selected Setts in a flattened sequence
    interface HAA {
      id: string // An empty ID means no set is allowed access
      parentId: string
      completed: boolean
      accessible: boolean
      item?: Sett
    }
    const hierarchyAsArray: HAA[] = []
    const hierarchyAsDict: Ref<Record<string, HAA>> = ref({})

    const newHAA = (id: string, parentId: string): HAA => {
      const completed = theGame.value.itemIsComplete(id, parentId)
      return {
        id,
        parentId,
        completed,
        accessible: theGame.value.status.allowedSets.indexOf(id) > -1,
      }
    }

    const flatten = (array: Sett[], level: number) => {
      array.forEach((sett) => {
        const h = newHAA(sett._id, sett.parent?._id || '')
        hierarchyAsArray.push(h)
        hierarchyAsDict.value[h.id] = h

        // Traverse to the next level down
        if (sett.sets && sett.sets.length > 0) flatten(sett.sets, level + 1)
      })
    }

    // 'No Access' list option
    const nah = newHAA('noaccess', '')
    hierarchyAsArray.push(nah)
    hierarchyAsDict.value[nah.id] = nah

    // 'Mastery Baseline' list option
    const mbh = newHAA(masteryBaselineID, '')
    hierarchyAsArray.push(mbh)
    hierarchyAsDict.value[mbh.id] = mbh

    flatten(cmsroot.value, 0)

    const initialiseMasterySet = (t: Game) => {
      theGame.value = new Game(t)
      /* selectedSet.value = hierarchyAsArray.find(
        (i) => i.id === theGame.value.progress.limitSet
      ) */
    }
    // During initial setup phase 'watch' does not fire
    initialiseMasterySet(props.game)

    watch(
      () => props.game,
      (newValue: Game) => {
        initialiseMasterySet(newValue)
      },
    )

    // ----------------- Mastery functions -------------------

    // Adjust the access to a set
    const selectMasterySet = (value: HAA) => {
      selectedSet.value = value

      // Reset completion progress to correct level
      // renewProgress()
      showSave.value = true
    }

    const selectMasterySetByID = (id: string) => {
      const set = hierarchyAsArray.find((item) => item.id === id)
      if (set) selectMasterySet(set)
    }

    const toggleAccessMasterySetByID = (id: string) => {
      const mIndex = theGame.value.status.allowedSets.indexOf(id)
      if (mIndex > -1) {
        theGame.value.status.allowedSets.splice(mIndex, 1)
      } else {
        theGame.value.status.allowedSets.push(id)
      }
    }

    // Set the given Sett and all previous Setts to 'accessible'
    // In the case of SLPlus project, a set is accessible if it comes before or equal to the latest 'allowed set'
    // In terms of data structure, accessible sets are those included in   mastery.allowedSets
    // SLPlus sets are accessed in order, by traversing the Sett tree hierarchy in a down-then-forward direction
    const settAccess = (id: string) => {
      theGame.value.status.allowedSets.length = 0
      Object.values(hierarchyAsDict.value).forEach((v) => (v.accessible = false))
      let i = -1
      if (id) {
        do {
          const item: HAA = hierarchyAsArray[++i]
          if (item) {
            theGame.value.status.allowedSets.push(item.id)
            hierarchyAsDict.value[item.id].accessible = true
          }
        } while (hierarchyAsArray[i].id !== id)
      }
      showSave.value = true
    }

    // When a given Sett is to be marked completed, we also need to complete Questions inside the Sett
    // As we use v-model on the template input, the changed value is waiting for us in hierarchyAsDict
    const settComplete = (id: string) => {
      // At this point, 'had' will contain the updated completion state
      const had = hierarchyAsDict.value[id]
      console.log(`Marking task completions for Set ID: ${id} to ${had.completed}`)
      // The ID of the Sett is the parent part of the Question's ID/PARENT ID pair from the progress.records Map
      // We need to brak apart the Progress keys to check if the parent part matches
      let foundExactSet = false // true if we find a record for this Sett ID in Progress
      theGame.value.progress.forEach((value, key) => {
        const keys = key.split(':')
        const parentKey = keys.length > 1 ? keys[1] : ''
        let match = keys[0] === id || parentKey === id
        if (keys[0] === id) foundExactSet = true
        if (match) {
          console.log(`ItemKey: ${keys[0]} ParentKey: ${parentKey} ValueCompleted: ${value.completed} NewCompleted: ${had.completed}`)
          value.completed = had.completed
        }
      })
      // If we did not find the Sett itself, and want to mark completed: true we need to create a new Progress entry
      // Only mark completed: true. We do not add completion timestamp here... TODO: Is it correct to do this?
      if (!foundExactSet && had.completed) {
        theGame.value.createProgress(had.id, had.parentId).completed = true
        console.log('Created new Progress for set')
      }
      showSave.value = true
    }

    // Example:
    // To allow access to all of Week Sett 'loud', we must
    /* const renewProgress = () => {
      // Which sets are to be marked 'completed'
      const accessibleSetIDs = hierarchyAsArray
        .filter((s) => s.selected)
        .map((s) => s.id)
      theGame.value.progress.records.forEach((value, key) => {
        const setSearchKey = key.split(':')[0]
        // const parentSearchKey =
        value.completed = accessibleSetIDs.includes(setSearchKey)
      })
    } */

    // ------------------------------------------------------

    const consentStateList = Object.values(consentStates).map((v, i) => ({
      id: i,
      title: v,
    }))

    // Update Mastery status of the current Barn
    const saveParticipant = async () => {
      if (!showSave.value) return
      // Update a selected Barn
      await gameActions.updateGameControl(theGame.value)
      showSave.value = false
    }

    const deleteParticipant = async () => {
      console.log('Delete not yet active!')
      // await gameActions.deleteParticipant(theGame.value)
      showSave.value = false
    }

    const saveActive = () => {
      showSave.value = true
    }

    const selectGame = () => {
      gameActions.selectGame(theGame.value)
    }

    return {
      // Computed
      showSave: computed(() => showSave.value),
      theGame,
      consentStateList,
      masteryBaselineID,
      selectedSet,

      hierarchyAsArray,
      hierarchyAsDict,

      // Methods
      saveParticipant,
      deleteParticipant,
      saveActive,
      selectGame,
      selectMasterySet,
      selectMasterySetByID,
      toggleAccessMasterySetByID,
      settAccess,
      settComplete,
    }
  },
})
