
import { defineComponent, PropType, ref, toRefs, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { playSounds, stopSounds, soundIsPlaying, capitalizeFirstLetter } from '@/utilities'
import { SevenseasItem, Thumbs } from '@/templates/slplus/structure/questionModels'
import Button from '@/components/base/Button.vue'
import bgFishedImage from '@/assets/tasks/sevenseas/Bg-Element_fished.svg'
import bgTextImage from '@/assets/tasks/sevenseas/Flat_3.svg'
import coralImage from '@/assets/tasks/sevenseas/coraux.svg'
import lightBulb from '@/assets/tasks/sevenseas/Omar-lightball.svg'
import yes from '@/assets/tasks/sevenseas/happy.svg'
import no from '@/assets/tasks/sevenseas/sad.svg'
import x from '@/assets/tasks/sevenseas/X-doesnotbelong.svg'

import ihavetSound from '@/assets/tasks/sevenseas/syvhav_ihavet_S5.mp3'
import prateSound from '@/assets/tasks/sevenseas/syvhav_prate_S8.mp3'
import whatWasFished from '@/assets/tasks/sevenseas/syvhav_krok_S4.mp3'

const messages = {
  no: {
    youFished: 'Du hjalp Omar fiske',
    wordFocus: 'ordfokus',
    letsDiscuss: 'La oss snakke litt sammen',
  },
  en: {
    youFished: 'You helped Omar fish a',
    wordFocus: 'Word Focus',
    letsDiscuss: "Let's all talk a little together",
  },
}

enum MODES {
  intro = 'intro',
  quizz = 'quizz',
  wordFocus = '',
  done = 'done',
}

export default defineComponent({
  name: 'ItemDetailSubtask',
  components: {
    Button,
  },
  props: {
    item: {
      type: Object as PropType<SevenseasItem>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ messages })
    const { item } = toRefs(props)
    const boxText = ref('')
    const mode: Ref<MODES> = ref(MODES.intro)
    const backgroundColour = ref('#2266ff')
    const downBackgroundColour = ref('#ffffff')
    const downForegroundColour = ref('#0199FE')
    const upBackgroundColour = ref('#ffffff')
    const upForegroundColour = ref('#0199FE')
    const answeredCorrectly: Ref<Thumbs | undefined> = ref()
    const focusPage = ref(-1)
    boxText.value = capitalizeFirstLetter(`${item.value.name}!`)

    const itemSoundURL = whatWasFished // item.value.sound
    playSounds([itemSoundURL])

    const clickNextIntro = () => {
      if (item.value.question) {
        // optional quizz question
        mode.value = MODES.quizz
        boxText.value = item.value.question
        if (itemSoundURL && ihavetSound) playSounds([ihavetSound])
      } else {
        mode.value = MODES.wordFocus
      }
    }

    const clickAnswer = (value: Thumbs) => {
      stopSounds()
      if (answeredCorrectly.value) return
      if ((item.value.correctAnswer === Thumbs.down && value === Thumbs.down) || (item.value.correctAnswer === Thumbs.up && value === Thumbs.up)) {
        backgroundColour.value = '#2266ff'
        downBackgroundColour.value = ''
        upBackgroundColour.value = ''
        if (value === Thumbs.down) {
          downBackgroundColour.value = '#42e05d'
          downForegroundColour.value = '#9CF998'
        } else if (value === Thumbs.up) {
          upBackgroundColour.value = '#42e05d'
          upForegroundColour.value = '#9CF998'
        }
        answeredCorrectly.value = value
        boxText.value = item.value.answer
        playSounds([item.value.quizzSound])
      } else {
        if (value === Thumbs.down) {
          downBackgroundColour.value = '#EB4F2E'
          downForegroundColour.value = '#F19390'
        } else if (value === Thumbs.up) {
          upBackgroundColour.value = '#EB4F2E'
          upForegroundColour.value = '#F19390'
        }
        backgroundColour.value = '#ff8a70'
      }
    }

    const playFishedItemSound = () => {
      stopSounds()
      const itemSoundURL = whatWasFished //item.value.sound
      const discussionSound = focusPage.value === item.value.wordFocus.length - 1 ? prateSound : ''
      if (mode.value === MODES.wordFocus) playSounds([item.value.wordFocus[focusPage.value].sound, discussionSound])
      else if (mode.value === MODES.intro) playSounds([itemSoundURL])
      else if (answeredCorrectly.value) playSounds([item.value.quizzSound])
      else if (itemSoundURL && ihavetSound) playSounds([itemSoundURL, ihavetSound])
    }

    const clickNext = () => {
      stopSounds()
      if (mode.value === MODES.quizz) {
        focusPage.value = -1
        mode.value = MODES.wordFocus
      }
      focusPage.value++
      const wf = item.value.wordFocus
      if (focusPage.value <= wf.length - 1) {
        boxText.value = wf[focusPage.value].text
        const discussionSound = focusPage.value === wf.length - 1 ? prateSound : ''
        playSounds([wf[focusPage.value].sound, discussionSound])
      }
    }

    return {
      t,
      boxText,
      MODES,
      Thumbs,
      mode,
      backgroundColour,
      downBackgroundColour,
      downForegroundColour,
      upBackgroundColour,
      upForegroundColour,
      answeredCorrectly,
      focusPage,

      // events
      clickAnswer,
      clickNext,
      clickNextIntro,
      playFishedItemSound,
      soundIsPlaying,
      stopSounds,

      // Assets
      bgFishedImage,
      bgTextImage,
      coralImage,
      lightBulb,
      yes,
      no,
      x,
    }
  },
})
