// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader-v16/dist/stylePostLoader.js!../../../node_modules/@vue/cli-service/node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader-v16/dist/stylePostLoader.js!../../../node_modules/@vue/cli-service/node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".tabSelected[data-v-793ddc60] {\n  border-top-left-radius: 0.25rem;\n  border-top-right-radius: 0.25rem;\n  border-top-width: 1px;\n  border-right-width: 1px;\n  border-bottom-width: 0px;\n  border-left-width: 1px;\n  --tw-text-opacity: 1;\n  color: rgba(8, 159, 255, var(--tw-text-opacity));\n}\n", ""]);
// Exports
module.exports = exports;
