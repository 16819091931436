import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-793ddc60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col cursor-default relative h-screen" }
const _hoisted_2 = { class: "flex flex-col p-4" }
const _hoisted_3 = { class: "flex border-b" }
const _hoisted_4 = { class: "flex flex-col p-4 flex-grow-0" }
const _hoisted_5 = { class: "absolute top-0 right-0 m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/monitor/profile" }, {
          default: _withCtx(() => [
            (_ctx.hasMonitorRole)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  id: "li-userstab",
                  class: _normalizeClass(["-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold", { tabSelected: _ctx.selectedTab === 'users' }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTab('users', 'profile')))
                }, " Users ", 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_router_link, { to: "/monitor/groups" }, {
          default: _withCtx(() => [
            (_ctx.hasAdminRole)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  id: "li-groupstab",
                  class: _normalizeClass(["-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold", { tabSelected: _ctx.selectedTab === 'groups' }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectTab('groups', 'groups')))
                }, " Kindergartens ", 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_router_link, { to: "/monitor/projects" }, {
          default: _withCtx(() => [
            (_ctx.hasAdminRole)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  id: "li-projectstab",
                  class: _normalizeClass(["-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold", { tabSelected: _ctx.selectedTab === 'projects' }]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectTab('projects', 'projects')))
                }, " Projects ", 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createCommentVNode("router-link :to=\"{ name: 'MonitorGames' }\">\n          <li\n            class=\"-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold\"\n            v-if=\"hasMonitorRole\"\n            id=\"li-gamestab\"\n            :class=\"{ tabSelected: selectedTab === 'games' }\"\n            @click=\"selectTab('games', 'games')\"\n          >\n            Games\n          </li>\n        </router-link"),
        _createVNode(_component_router_link, { to: "/monitor/mastery" }, {
          default: _withCtx(() => [
            (_ctx.hasMonitorRole)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  id: "li-masterytab",
                  class: _normalizeClass(["-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold", { tabSelected: _ctx.selectedTab === 'mastery' }]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectTab('mastery', 'mastery')))
                }, " Mastery ", 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_router_link, { to: "/monitor/logs" }, {
          default: _withCtx(() => [
            (_ctx.hasLogsRole)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  id: "li-masterytab",
                  class: _normalizeClass(["-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold", { tabSelected: _ctx.selectedTab === 'logs' }]),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectTab('logs', 'logs')))
                }, " Logs ", 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        id: "button-return",
        class: "bg-white inline-block py-2 px-4 text-green-600 hover:sl-blue-800 font-semibold cursor-pointer",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.restart()))
      }, "Return")
    ])
  ]))
}