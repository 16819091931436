
import { defineComponent, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { useGameStore } from '@/store/useGameStore'
import { useUserStore } from '@/store/useUserStore'
import ItemSelection from '@/components/base/ItemSelection.vue'
import Button from '@/components/base/Button.vue'
import Avatar from '@/components/base/Avatar.vue'

/* Interfaces */
import { Game, User, Player } from '@/types/main'
import { USER_ROLE } from '@/constants'

const messages = {
  no: {
    selectPlayers: 'Velg opptil fire aktive spillere i dette spillet',
    playersInGame: 'Deltager i dette spillet',
    noPlayersInGame: 'Ingen spillere i spill',
    noPlayersForUser: 'Ingen spillere tilgjengelig for din bruker',
    otherPlayersAvailable: 'Andre spillere',
    available: 'tilgjengelig',
    notAvailable: '(administrert av admin)',
    begin: 'Begynne!',
    checkedPlayersMeans: 'Sjekk opptil fire spillere som skal spille i dag',
  },
  en: {
    selectPlayers: 'Select up to four Active Players in this Game',
    playersInGame: 'Players in this Game',
    noPlayersInGame: 'No Players assigned to this game',
    noPlayersForUser: 'No Players available for your user',
    otherPlayersAvailable: 'Other players',
    available: 'available',
    notAvailable: '(managed by Admin)',
    begin: 'Begin!',
    checkedPlayersMeans: 'Check up to four players who will play today',
  },
}

export default defineComponent({
  name: 'TogglePlayer',
  components: {
    Avatar,
    Button,
    ItemSelection,
  },
  setup() {
    const { t } = useI18n({ messages })
    const { actions: gameActions, getters: gameGetters } = useGameStore()
    const { getters: userGetters, actions: userActions } = useUserStore()
    const gSelected = computed(() => g.activePlayers.length > 0)
    const u = new User(userGetters.selectedUser.value)
    const g = new Game(gameGetters.selectedGame.value)
    const theUser = ref(u)

    const done = async () => {
      if (g.activePlayers.length > 0) {
        await gameActions.savePlayers()
        await gameActions.updateGame(g)
        await gameActions.getGameDetails(g._id)
        router.push('/dashboard/layout/yes')
      }
    }

    // Players in the game (max 4)
    const playerList = computed(() => {
      const players = gameGetters.players.value.filter((p) => g.players.includes(p._id))
      players.forEach((p) => (p.selected = g.activePlayers.includes(p._id)))
      return players
    })

    const beginActive = computed(() => {
      return playerList.value.some((p) => p.selected)
    })

    // Players available to add to the game
    const otherPlayers = computed(() => {
      const players = gameGetters.players.value.filter((p) => !g.players.includes(p._id))
      players.forEach((p) => (p.selected = false))
      return players
    })

    const canSeeMonitor = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))

    function togglePlayerActive(p: Player) {
      const pIndex = g.activePlayers.indexOf(p._id)
      if (pIndex > -1) g.activePlayers.splice(pIndex, 1)
      else if (g.activePlayers.length < 4) g.activePlayers.push(p._id)
    }

    function addPlayer(p: Player) {
      if (g.players.length < 4) g.players.push(p._id)
    }
    function removePlayer(p: Player) {
      const pIndex = g.players.indexOf(p._id)
      g.players.splice(pIndex, 1)
    }
    function back() {
      router.push('/dashboard/games')
    }

    return {
      t,
      monitor: () => router.push('/monitor'),
      g,
      user: userGetters.selectedUser,
      gSelected,
      beginActive,
      theUser,
      canSeeMonitor,
      togglePlayerActive,
      playerList,
      otherPlayers,
      done,
      back,
      addPlayer,
      removePlayer,
    }
  },
  methods: {},
})
