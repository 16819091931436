
import { defineComponent, ref, Ref, computed, onMounted, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { fetchToken } from '@/api/cmsService'
import { useCMSStore } from '../store/useCMSStore'
import { useAppStore } from '@/store/useAppStore'
import { useUserStore } from '@/store/useUserStore'
import { useProjectStore } from '@/store/useProjectStore'
import { useDeviceService } from '@/composition/useDevice'
import { useGameStore } from '@/store/useGameStore'
import {
  // AugmentedQuestionType,
  Sett,
  Question,
  Project,
  Game,
  Player,
  TrackingDetails,
  TrackingDetailsData,
  TRACKING_TYPE,
  DISPLAY_MODE,
  QUESTION_TYPES,
} from '@/types/main'
import { shuffleItems } from '@/utilities'
import { assetRoot, PROJECT_NAME, PROJECT_TYPE, LanguageCodes } from '@/constants'
import SlButton from '@/components/base/SlButton.vue'

// Project-specific Types
import * as questionClasses from './questions'
import { Day } from '../structure/generalModels'

export default defineComponent({
  name: 'Question',
  components: { ...questionClasses, SlButton },
  props: {
    // These props are provided by the router when a Sample task is called for
    projectname: {
      type: String as PropType<PROJECT_NAME>,
      default: '',
    },
    projecttype: {
      type: String,
      default: '',
    },
    schema: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: 'no',
    },
  },
  setup(props) {
    const { locale } = useI18n({ useScope: 'global' })
    const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
    const { getters: appGetters, actions: appActions } = useAppStore()
    const { getters: projectGetters, actions: projectActions } = useProjectStore()
    const { getters: gameGetters, actions: gameActions } = useGameStore()
    const { getters: deviceGetters, actions: deviceActions } = useDeviceService()
    const { getters: userGetters } = useUserStore()

    // For TESTING ONLY. This is used to remove delibrate delays in the UI
    const disableDelays = appGetters.disableDelays.value

    // Data
    let questions: Question[]
    const tracking: Ref<TrackingDetails | undefined> = ref()
    const selectedSet: Sett = cmsGetters.selectedSet.value.set as Sett
    const recordAudioToday = selectedSet instanceof Day && selectedSet.recordAudio
    const selectedQuestion: Ref<Question | undefined> = ref()
    const selectedQuestionTemplateRef = ref() // This is a 'ref' template reference to the child
    const selectedSetIndex = cmsGetters.selectedSet.value.index
    const assets: string = assetRoot

    // Control
    const questionIndex = ref(0)
    let audioIntroduction: HTMLAudioElement
    const loaded = ref(false)
    const disableInteractionShowSpeaker = ref(false)
    const disableInteraction = ref(false)
    const taskIsFinished = ref(false)
    const showInternalForwardArrow = ref(false)
    const pulseForwardArrow = ref(false)
    const pulseInternalForwardArrow = ref(false)

    //console.dir(questionClasses)

    appActions.setFade(false)
    // Determine is this Question part of a 'consolidation' Week?
    const consolidation = computed(() => {
      return selectedSet && selectedSet.parent && selectedSet.parent.consolidation
    })

    // Prepare this local Question component for the new incoming question
    function setupQuestion() {
      const selectedSet = cmsGetters.selectedSet.value.set as Sett
      const game = gameGetters.selectedGame.value
      const project = projectGetters.selectedProject.value
      questionIndex.value = questions.findIndex((q) => q._id === selectedQuestion.value?._id)
      if (game && project && selectedQuestion.value) {
        let trackingType = TRACKING_TYPE.question
        if (selectedQuestion.value.type === QUESTION_TYPES.mastery) trackingType = TRACKING_TYPE.mastery
        if (selectedQuestion.value.type === QUESTION_TYPES.picturebook) trackingType = TRACKING_TYPE.picturebook
        tracking.value = new TrackingDetails({
          itemID: selectedQuestion.value._id,
          gameID: game._id,
          playerIDs: gameGetters.playersInSelectedGame.value.map((p) => p._id),
          projectID: project._id,
          type: trackingType,
          details: {},
        })
        if (selectedQuestion.value.recordAudio && recordAudioToday) {
          deviceActions.createAudio(tracking.value.oid).then(() => deviceActions.startRecordingAudio())
        }
        gameActions.registerAttemptForItem(selectedQuestion.value._id, selectedSet._id)
        if (questionIndex.value === questions.length - 1) {
          seenSet(selectedSet)
        }
        taskIsFinished.value = false
      } else tracking.value = undefined
      loaded.value = true
    }

    // When we load this component, or if the list of questions changes,
    // this will load them to a local array, shuffle if called for, and select the first item
    function configureQuestions() {
      questions = cmsGetters.questions.value
      selectedQuestion.value = cmsGetters.selectedQuestion.value.question
      // Possibly shuffle questions
      if (selectedSet && selectedSet.displayMode === DISPLAY_MODE.shuffle) {
        // shuffleItems returns a new array
        questions = shuffleItems(cmsGetters.questions.value)
      }
      setupQuestion()
    }

    /* If this is a Sample question, check the props to find out.
     * The Sample question comes from a Squidex redirect of this format:
     *   https://[app.host]/#/sample/[projectType]/[projectName]/${schemaName}/${id}
     *
     * '#' Include the # character if the app router is not in 'history' mode (Engagelab server)
     * projectType -- determines templates used to display data
     * projectName -- determines project queried for data
     * ${schemaName} -- interpolated from schema data **must include ${} for Squidex**
     * ${id} -- interpolated from schema data **must include ${} for Squidex**
     */
    onMounted(async () => {
      // Set up for a CMS sample question
      if (props.projectname in PROJECT_NAME && props.schema && props.id && props.language) {
        // Create a sample Project. Depends on the Squiex project having anonynous read-only access
        const p = new Project({
          projectName: props.projectname,
          projectType: props.projecttype as unknown as PROJECT_TYPE,
          cmsClient: '',
          cmsSecret: '',
          tsdGroupName: '',
          tsdGroupID: '',
          interventionName: '',
        })
        projectActions.addProject(p)
        projectActions.selectProject(p)
        projectActions.overrideCMS() // Ensure we refresh CMS content
        await fetchToken(p).then((newToken: string) => {
          console.log('Fetched new CMS token')
          localStorage.setItem('squidex-token', newToken)
        })

        cmsActions.selectSet(new Sett(), 0, 0)

        // Create sample Players for the Game
        const playerIDs = ['1', '2', '3', '4']
        const players = playerIDs.map((id) => {
          const p = new Player()
          p._id = id
          p.profile.name = `Player #${id}`
          return p
        })
        gameActions.setPlayers(players)

        // Create a sample Game
        const g = new Game()
        g.players = [...playerIDs]
        g.activePlayers = [...playerIDs]
        gameActions.setGames([g])
        gameActions.selectGame(g)

        // Retrieve the question from CMS and display it
        appActions.setLanguageCode(props.language as LanguageCodes)
        locale.value = props.language as string
        cmsActions.getQuestionByID(props.schema, props.id, appGetters.languageCode.value).then((question) => {
          if (question) {
            cmsActions.selectQuestion(question, 0)
            configureQuestions()
          }
        })
      } else {
        configureQuestions()
      }
    })

    // If we were using a 'general' Question tracking, complete it here
    const completeQuestionTracking = () => {
      const question = selectedQuestion.value
      const game = gameGetters.selectedGame.value
      if (question && game && tracking.value) {
        // Stop the TrackingDetails and save that (also using the participant store)
        tracking.value.audioFile = recordAudioToday ? deviceGetters.audioFilename.value : undefined
        const rootIndex = cmsGetters.root.value.indexOf(selectedSet.root)
        const myUser = userGetters.myUser.value
        const trackingDetails: TrackingDetailsData['details'] = {
          user: myUser.profile.username,
          groupName: myUser.groups[0]?.name || '(unknown)',
          project: `${userGetters.selectedUserProject.value.projectName} - ${userGetters.selectedUserProject.value.interventionName}`,
          rootName: selectedSet.root.name,
          rootIndex,
          taskType: question.type,
          taskInfo: question.name,
          rootID: selectedSet.root._id,
          taskAttempts: game.itemAttempts(question._id, selectedSet._id),
          setCompletions: game.itemCompletions(selectedSet._id, selectedSet.parent?._id || ''),
          setID: selectedSet._id,
        }
        tracking.value.details = trackingDetails
        tracking.value.complete()
        gameActions.commitNewTracking(tracking.value)
      }
    }

    // Complete the Sett containing this Question
    const completeSet = (sett: Sett) => {
      gameActions.completeProgressForItem(sett._id, sett.parent?._id || '')
      // If this was the last Sett in a series, we should also complete the grandparents recursively
      if (
        sett.parent && // Has a parent
        sett.parent.sets.indexOf(sett) === sett.parent.sets.length - 1 // This was the final child in its parent sett list
      ) {
        completeSet(sett.parent)
      }
    }

    // Mark the Sett containing this Question as 'seen'
    const seenSet = (sett: Sett) => {
      gameActions.registerAttemptForItem(sett._id, sett.parent?._id || '')
      // If this was the last Sett in a series, we should also mark the grandparents recursively
      if (
        sett.parent && // Has a parent
        sett.parent.sets.indexOf(sett) === sett.parent.sets.length - 1 // This was the final child in its parent sett list
      ) {
        seenSet(sett.parent)
      }
    }

    // Post-processing a completed question, before moving to the next or back to the Dashboard
    // If we exited the question early, 'finished' is false, and we don't store progression info
    // Here we have a chance to sequence the next question shown based on data from the previous & Participant progress
    const completeQuestion = async (finished: boolean) => {
      if (selectedQuestion.value) {
        // If recording audio, stop recording now
        if (selectedQuestion.value.recordAudio && recordAudioToday) {
          await deviceActions.stopRecordingAudio()
        }

        // Mark the current Question (or Picturebook) as complete
        if (finished) gameActions.completeProgressForItem(selectedQuestion.value._id, selectedSet._id)

        // Finish and commit the tracking object
        // Mastery Trackings depend on the Participant first being marked completed,
        //    including Mastery 'allowedSets' & 'setAttempts' info
        // see Participant.complete()
        completeQuestionTracking()
        await gameActions.updateGameProgress(false)
        // TODO: If we 'await' sendTrackings, it creates a long UI delay.
        // Is it safe to allow it to run in background?
        gameActions.sendTrackings()

        // If we exited early, don't advance the question
        if (!finished) {
          appActions.setFade(false)
          return router.push('/dashboard/layout')
        }

        // Otherwise move to the next question, if there is one
        switch (selectedSet.displayMode) {
          // For now, linear movement through Questions
          case DISPLAY_MODE.linear:
            {
              if (questionIndex.value < questions.length - 1) {
                // More questions exist, select the next one
                questionIndex.value++
                selectedQuestion.value = questions[questionIndex.value]
                cmsActions.selectQuestion(questions[questionIndex.value], questionIndex.value)
                setupQuestion()
              } else {
                // The set of Questions is finished, complete the containing Set(s) and return to dashboard
                // Check if this was a 'Mastery'-type of Sett based on the question type
                //const questionType = selectedQuestion.value.type
                //const picturebookIsOnlyItem = questionType === QUESTION_TYPES.picturebook && !selectedSet.questions.length // The picturebook is the only task to do in this Sett
                //const completedAllQuestions = questionType != QUESTION_TYPES.picturebook && questionType === QUESTION_TYPES.mastery // Or there are mastery probes, and they have been completed now

                //if (picturebookIsOnlyItem || completedAllQuestions) {
                completeSet(selectedSet)
                //}
                router.push('/dashboard/layout')
              }
            }
            appActions.setFade(false)
        }
      }
    }

    // Show reminder if the book has been read and this is day 2 or 3 but not Consolidation
    /*     const showReminder = () => {
      const b: boolean = selectedSetIndex.value > 0
      const c = !this.dataService.getBookReadToday()
      const d = !this.consolidation
      if (b && c && d) {
        this.showingPanel = 'reminder'
        this.showForwardReminderArrow = false
        const handle = this
        this.dataService.setInteractionEndActivateTaskCallback(() => {
          handle.showForwardReminderArrow = true
        })
        const audioUrl = 'content/common/audio/reminder.mp3'
        this.dataService.setupAudioIntroduction(audioUrl)
        this.dataService.playAudioIntroduction(2000)
      } else {
        this.showingPanel = 'circle'
      }
    } */

    // ------------  Local control to local template -----------------

    // Call the dynamic child component (the Question Type) to move forward internally
    // For Questions with more than one 'page'. This is a separate 'internal forward' arrow
    const internalForwardArrowClicked = () => {
      pulseInternalForwardArrow.value = false
      showInternalForwardArrow.value = false
      const child = selectedQuestionTemplateRef.value
      if (child) {
        child.forwardInternal() // Call child function to continue the question's state
      }
    }

    // ------------  Child control called from this template -----------------

    const audioIntroductionEnded = () => {
      disableInteraction.value = false
      const child = selectedQuestionTemplateRef.value
      if (child) child.onIntroductionEnd()
    }

    const setupAudioIntroduction = (url: string) => {
      audioIntroduction = new Audio(url)
      audioIntroduction.addEventListener('ended', audioIntroductionEnded)
    }

    const startIntroduction = (delay: boolean) => {
      if (delay && !appGetters.disableDelays.value) {
        disableInteraction.value = true
      }
      if (audioIntroduction) {
        audioIntroduction.play().catch(() => audioIntroductionEnded())
        const child = selectedQuestionTemplateRef.value
        if (child) child.onIntroductionStart()
      }
    }

    const disableUserInteraction = () => {
      disableInteraction.value = true
    }

    const showInternalForward = () => {
      showInternalForwardArrow.value = true
      pulseInternalForwardArrow.value = true
      setTimeout(() => {
        pulseInternalForwardArrow.value = false
      }, 5000)
    }

    // After this signal from the Question, we wait for the user to click 'forward'
    const completed = (finished: boolean) => {
      showInternalForwardArrow.value = false
      taskIsFinished.value = true
      appActions.setFade(true)
      setTimeout(() => {
        loaded.value = false
        completeQuestion(finished)
      }, 1000)
    }

    return {
      // getters
      loaded,
      assets,
      selectedQuestion,
      selectedSet,
      selectedSetIndex,
      consolidation,

      // local control variables
      QUESTION_TYPES,
      questionIndex,
      selectedQuestionTemplateRef,
      disableInteractionShowSpeaker,
      disableInteraction,
      taskIsFinished,
      showInternalForwardArrow,
      pulseForwardArrow,
      pulseInternalForwardArrow,
      disableDelays,

      // local control methods
      internalForwardArrowClicked,

      // child control
      setupAudioIntroduction,
      startIntroduction,
      disableUserInteraction,
      showInternalForward,
      completed,
    }
  },
})
