
import { defineComponent, PropType, ref, Ref } from 'vue'
import { Player } from '@/types/main'
import { Thumbs } from '@/templates/slplus/structure/questionModels'

import no from '@/assets/tasks/enig/glasses2.svg'

import berry from '@/assets/tasks/enig/blueberrie.svg'
import spinach from '@/assets/tasks/enig/spinach.svg'
import mushroom from '@/assets/tasks/enig/mushroom.svg'
import flower from '@/assets/tasks/enig/flower.svg'

export default defineComponent({
  name: 'QuizzControl',
  props: {
    player: { required: true, type: Object as PropType<Player> },
    imageIndex: { required: true, type: Number },
  },
  emits: ['yes', 'no'],
  setup(props, context) {
    const images = {
      0: berry,
      1: spinach,
      2: mushroom,
      3: flower,
    }
    const answer: Ref<Thumbs | undefined> = ref()
    const imageIndex = props.imageIndex as keyof typeof images
    const click = (a: Thumbs) => {
      if (answer.value) return // Disallow answering more than once
      answer.value = a
      a === Thumbs.up ? context.emit('yes') : context.emit('no')
    }
    return {
      Thumbs,
      no,
      click,
      answer,
      imageByCode: images[imageIndex],
    }
  },
})
