/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { Sett, Question, Player, QuestionData, QUESTION_TYPES } from '@/types/main'
import { Day } from './generalModels'
//import { QUESTION_CLASS_NAMES } from './helpers'
import { LanguageCodes } from '@/constants'

/* import { i18n } from '@/i18n'
const lt = i18n.global.fallbackLocale
console.log(`DOES IT WORK????? ${lt}`) // Change "Locale" */

/*
export enum DATA_INTERFACE_NAMES {
  'Q100' = 'Q100Data',
  'Q101' = 'Q101Data',
  'Q102' = 'Q102Data',
  'Q103' = 'Q103Data',
  'Q104' = 'Q104Data',
  'Q105' = 'Q105Data',
  'Q106' = 'Q106Data',
  'Q107' = 'Q107Data',
  'Q200' = 'Q200Data',
  'Q201' = 'Q201Data',
  'Q202' = 'Q202Data',
  'Q203' = 'Q203Data',
  'Q204' = 'Q204Data',
  'Q205' = 'Q205Data',
  'Q206' = 'Q206Data',
  'Q207' = 'Q207Data',
  'Q300' = 'Q300Data',
  'Q301' = 'Q301Data',
  'Q302' = 'Q302Data',
  'Q303' = 'Q303Data',
  'Q304' = 'Q304Data',
  'Q305' = 'Q305Data',
  'Q306' = 'Q306Data',
  'Q307' = 'Q307Data',
}
*/
// -------------- Regular Question Types ---------

type LC<T> = {
  [key in LanguageCodes]: T
}

export enum Thumbs {
  up = 'up',
  down = 'down',
}

// Seven Seas Task Type
export interface SevenseasDataItem {
  name: string
  question: string
  quizzSound: [{ url: string }] | null
  answer: string
  correctAnswer: string
  image: [{ url: string }] | null
  sound: [{ url: string }] | null
  wordFocus: {
    pages: { description: string; sound: [{ url: string }] | null }[]
  }
  guidance: string
  discussionSound: [{ url: string }] | null
}
export interface SevenseasItem {
  name: string
  question: string
  quizzSound: string
  answer: string
  correctAnswer: Thumbs
  image: string
  sound: string
  wordFocus: { text: string; sound: string }[]
  guidance: string
  discussionSound: string
}

export interface SevenseasData extends QuestionData {
  data: {
    name: LC<string>
    word: LC<string>
    fishingSound: LC<[{ url: string }] | null> // Sound url
    items: LC<SevenseasDataItem[]>
  }
}

// NOTE: The main class name should be first-letter capitalised, the rest lowercase
// This is needed to match the schema name supplied by Squidex
export class Sevenseas extends Question {
  type: TASK_TYPES | QUESTION_TYPES
  word: string // The 'main' word for this task instance e.g. 'sortere'
  fishingIntro: string
  items: SevenseasItem[]

  constructor(spec: SevenseasData, language: LanguageCodes, parent?: Sett) {
    let name = ''
    const data = spec.data
    if (typeof data.name === 'string') name = data.name
    else name = data.name.iv
    super({ ...spec, name }, parent)
    this.type = TASK_TYPES.sevenseas
    this.word = data.word[language]
    this.fishingIntro = data.fishingSound[language]?.[0].url || ''
    this.items = []
    data.items[language].forEach((i) => {
      const wordFocus: { text: string; sound: string }[] =
        i.wordFocus && i.wordFocus.pages
          ? i.wordFocus.pages.map((p) => {
              const sound = p.sound && p.sound.length ? p.sound[0].url : ''
              return { text: p.description, sound }
            })
          : []
      this.items.push({
        name: i.name,
        question: i.question || '',
        quizzSound: i.quizzSound && i.quizzSound.length ? i.quizzSound[0].url : '',
        answer: i.answer || '',
        correctAnswer: (i.correctAnswer as Thumbs) || Thumbs.up,
        image: i.image && i.image.length ? i.image[0].url : '',
        sound: i.sound && i.sound.length ? i.sound[0].url : '',
        wordFocus,
        guidance: i.guidance,
        discussionSound: i.discussionSound && i.discussionSound.length ? i.discussionSound[0].url : '',
      })
    })
  }
}

export interface EnigDataItem {
  omarStatement: string
  omarStatementAudio: [{ url: string }] | null
  statementCorrect: boolean
  discussion: string
}
export interface EnigData extends QuestionData {
  data: {
    name: LC<string>
    video: LC<[{ url: string }] | null> // Video url
    image: LC<[{ url: string }] | null> // Image url
    movieQuestion: LC<string>
    mening: LC<EnigDataItem[]>
  }
}

export interface EnigItem {
  omarStatementAudio: string // Audio URL
  statementCorrect: boolean
}

export class Enig extends Question {
  type: TASK_TYPES | QUESTION_TYPES
  video: string // Video url
  image?: string // Image url
  movieQuestion: string
  mening: EnigItem[]

  constructor(spec: EnigData, language: LanguageCodes, parent?: Sett) {
    let name = ''
    const data = spec.data
    if (typeof data.name === 'string') name = data.name
    else name = data.name.iv
    super({ ...spec, name }, parent)
    this.type = TASK_TYPES.enig
    this.video = (data.video && data.video[language]?.[0].url) || ''
    this.image = (data.image && data.image[language]?.[0].url) || ''
    this.movieQuestion = data.movieQuestion[language]
    this.mening = []
    if (data.mening?.[language]) {
      data.mening[language].forEach((i) => {
        this.mening.push({
          omarStatementAudio: i.omarStatementAudio && i.omarStatementAudio.length ? i.omarStatementAudio[0].url : '',
          statementCorrect: !!i.statementCorrect,
        })
      })
    }
  }
}

export interface RetellDataPage {
  image: { url: string }[] | null
  sound: { url: string }[] | null
  question: string
}
export interface RetellPage {
  question: string
  image: string
  sound: string

  // Front end
  id: string
  index: number
  active: boolean
}

export interface RetellData extends QuestionData {
  data: {
    name: LC<string>
    pages: LC<RetellDataPage[]>
  }
}
export class Retell extends Question {
  type: TASK_TYPES | QUESTION_TYPES
  pages: RetellPage[]

  constructor(spec: RetellData, language: LanguageCodes, parent?: Sett) {
    let name = ''
    const data = spec.data
    if (typeof data.name === 'string') name = data.name
    else name = data.name.iv
    super({ ...spec, name }, parent)
    this.type = TASK_TYPES.retell
    this.pages = []
    data.pages[language].forEach((p, i) => {
      this.pages.push({
        id: 'page-' + i,
        index: i,
        active: false,
        image: p.image && p.image.length ? p.image[0].url : '',
        sound: p.sound && p.sound.length ? p.sound[0].url : '',
        question: p.question || '',
      })
    })
  }
}

export interface PlayerWordlist {
  player: Player
  wordlist: WordChaosWordlist
  playCount: number // Record of how many times this word was played
  playedWith: string[] // Record of other word IDs this one has been played alongside
}

export interface WordChaosWordlistData {
  word: string
  important: boolean
  sound1: { url: string }[] | null // URL
  sound2: { url: string }[] | null // URL
  sound3: { url: string }[] | null //URL
}
export interface WordChaosWordlist {
  word: string
  important: boolean
  sound1: string // URL
  sound2: string // URL
  sound3: string //URL

  // Front end
  id: string
  sound1audio: HTMLAudioElement
  sound2audio: HTMLAudioElement
  sound3audio: HTMLAudioElement
}
export interface WordChaosData extends QuestionData {
  data: {
    name: LC<string>
    minDelay: LC<number>
    wordlist: LC<WordChaosWordlistData[]>
  }
}
export class Wordchaos extends Question {
  type: TASK_TYPES | QUESTION_TYPES
  wordlist: WordChaosWordlist[]
  minDelay: number // Seconds

  constructor(spec: WordChaosData, language: LanguageCodes, parent?: Sett) {
    let name = ''
    const data = spec.data
    if (typeof data.name === 'string') name = data.name
    else name = data.name.iv
    super({ ...spec, name }, parent)
    this.type = TASK_TYPES.wordchaos
    this.minDelay = data.minDelay[language] || 5 // seconds
    this.wordlist = []
    data.wordlist[language].forEach((w, i) => {
      const sound1 = w.sound1 && w.sound1.length ? w.sound1[0].url : ''
      const sound2 = w.sound2 && w.sound2.length ? w.sound2[0].url : ''
      const sound3 = w.sound3 && w.sound3.length ? w.sound3[0].url : ''
      this.wordlist.push({
        id: 'word-' + i,
        word: w.word || '',
        important: !!w.important,
        sound1,
        sound2,
        sound3,
        sound1audio: new Audio(sound1),
        sound2audio: new Audio(sound2),
        sound3audio: new Audio(sound3),
      })
    })
  }
}

export interface HorerDuOrdetSentenceData {
  sentence: string
  wordExists: boolean
  sound: { url: string }[] | null // URL
  conclusionAudio: { url: string }[] | null // URL
}
export interface HorerDuOrdetSentence {
  sentence: string
  sound: string //URL
  wordExists: boolean
  conclusionAudio: string

  // Front end
  id: string
}
export interface HorerDuOrdetData extends QuestionData {
  data: {
    name: LC<string>
    instructionSound: LC<[{ url: string }] | null>
    instructionSound2: LC<[{ url: string }] | null>
    instructionSound3: LC<[{ url: string }] | null>
    wordlist: LC<HorerDuOrdetSentenceData[]>
  }
}
export class Horerduordet extends Question {
  type: TASK_TYPES | QUESTION_TYPES
  sentencelist: HorerDuOrdetSentence[]
  instructionSound: string // Audio URL
  instructionSound2: string // Audio URL
  instructionSound3: string // Audio URL

  constructor(spec: HorerDuOrdetData, language: LanguageCodes, parent?: Day) {
    let name = ''
    const data = spec.data
    if (typeof data.name === 'string') name = data.name
    else name = data.name.iv
    super({ ...spec, name }, parent)
    this.type = TASK_TYPES.horerduordet
    this.word = parent ? parent.word : ''

    const instruction = data.instructionSound[language]
    this.instructionSound = instruction && instruction.length ? instruction[0].url : ''
    const instruction2 = data.instructionSound2[language]
    this.instructionSound2 = instruction2 && instruction2.length ? instruction2[0].url : ''
    const instruction3 = data.instructionSound3[language]
    this.instructionSound3 = instruction3 && instruction3.length ? instruction3[0].url : ''

    this.sentencelist = []
    data.wordlist[language].forEach((w, i) => {
      this.sentencelist.push({
        id: 'sentence-' + i,
        wordExists: !!w.wordExists,
        sentence: w.sentence || '',
        sound: w.sound && w.sound.length ? w.sound[0].url : '',
        conclusionAudio: w.conclusionAudio && w.conclusionAudio.length ? w.conclusionAudio[0].url : '',
      })
    })
  }
}

export enum TASK_TYPES {
  sevenseas = 'sevenseas',
  enig = 'enig',
  retell = 'retell',
  wordchaos = 'wordchaos',
  horerduordet = 'horerduordet',
}
