
import { defineComponent, ref, watch, toRefs, PropType, nextTick } from 'vue'
interface OptionItem {
  item: unknown
  itemName: string
}
export default defineComponent({
  name: 'SelectionBox',
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Object as PropType<OptionItem>,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    options: {
      // SelectionBox should take options as: [{ item: any, itemName: string }]
      type: Object as PropType<OptionItem[]>,
      required: true,
    },
    resetOnChoose: {
      type: Boolean,
      default: false,
    },
  },
  // Emits the selected option in its original form: { item: any, itemName: string }
  emits: ['change', 'update:modelValue'],
  setup(props, context) {
    const { modelValue, id } = toRefs(props)
    const updated = ref(false)
    const displayValue = ref(modelValue.value)
    const elementId = id ? id.value : 'select-' + Math.floor(Math.random() * 10000000)

    watch(
      () => props.modelValue,
      (newValue) => {
        if (!updated.value) {
          displayValue.value = newValue
        }
        updated.value = true
      },
    )

    const updateSelection = ($event: Event) => {
      const ie = $event.target as HTMLSelectElement
      const selection: OptionItem | undefined = props.options.find((o) => o.itemName === ie.value)
      if (selection) {
        displayValue.value = selection
        nextTick(() => {
          context.emit('change', selection)
        })
        context.emit('update:modelValue', selection)
        updated.value = true
        if (props.resetOnChoose) {
          displayValue.value = { item: undefined, itemName: '' }
        }
      }
    }
    return {
      displayValue,
      updateSelection,
      elementId,
    }
  },
})
