import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/svg/speaker-off.svg'
import _imports_1 from '@/assets/icons/svg/speaker.svg'


const _hoisted_1 = {
  class: "relative flex flex-row justify-center items-center w-full h-full text-center text-white overflow-hidden",
  style: {"background-color":"#f7f7f7"}
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute -top-26 -left-26 w-96 h-96" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "w-6 h-6",
  src: _imports_0,
  alt: "close"
}
const _hoisted_8 = {
  key: 1,
  class: "w-6 h-6",
  src: _imports_1,
  alt: "close"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "absolute left-0 top-1/2 transform -translate-y-1/2" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 0,
  class: "absolute top-4 left-2 transform rotate-180"
}
const _hoisted_15 = {
  key: 1,
  class: "absolute bottom-4 left-2"
}
const _hoisted_16 = {
  key: 2,
  class: "absolute top-4 right-5 transform rotate-180 z-30"
}
const _hoisted_17 = {
  key: 3,
  class: "absolute bottom-4 right-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Main bubble and item image "),
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode(" Chosen player images "),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.imageKeys, (i) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: 'chosenAgreeImage-' + i
            }, [
              ($setup.results[i]?.answer === $setup.Thumbs.up)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: $setup.agreeImages[i].src,
                    alt: "agree image",
                    class: "absolute w-36",
                    style: _normalizeStyle({ top: $setup.agreeImages[i].loc.y, left: $setup.agreeImages[i].loc.x })
                  }, null, 12 /* STYLE, PROPS */, _hoisted_5))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 64 /* STABLE_FRAGMENT */)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.imageKeys, (i) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: 'chosenDisagreeImage-' + i
            }, [
              ($setup.results[i]?.answer === $setup.Thumbs.down)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: $setup.disagreeImages[i].src,
                    alt: "glasses image",
                    class: "absolute w-36",
                    style: _normalizeStyle({ top: $setup.disagreeImages[i].loc.y, left: $setup.disagreeImages[i].loc.x })
                  }, null, 12 /* STYLE, PROPS */, _hoisted_6))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 64 /* STABLE_FRAGMENT */))
        ])
      ]),
      _createCommentVNode(" Speaker "),
      _createVNode($setup["Button"], {
        class: "absolute pointer-events-auto bg-white p-2 rounded-full top-4 right-4",
        childclass: 'w-12 h-12',
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.soundIsPlaying ? $setup.stopSounds() : _ctx.$emit('playsound')))
      }, {
        default: _withCtx(() => [
          ($setup.soundIsPlaying)
            ? (_openBlock(), _createElementBlock("img", _hoisted_7))
            : (_openBlock(), _createElementBlock("img", _hoisted_8))
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("img", {
        class: "w-72",
        src: $setup.omarSpeaksImage,
        alt: "intoduction main image"
      }, null, 8 /* PROPS */, _hoisted_9)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", {
        class: "rounded-r-4xl p-3 pr-5 h-16 w-16 flex flex-row justify-end",
        style: {"background-color":"#d9eefb"},
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('review', 'movie')))
      }, [
        _createElementVNode("img", {
          src: $setup.video,
          class: "w-6"
        }, null, 8 /* PROPS */, _hoisted_11)
      ]),
      _createElementVNode("div", {
        class: "rounded-r-4xl p-3 pr-5 mt-2 h-16 w-16 flex flex-row justify-end",
        style: {"background-color":"#d9eefb"},
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('review', 'places')))
      }, [
        _createElementVNode("img", {
          src: $setup.placement,
          class: "w-4"
        }, null, 8 /* PROPS */, _hoisted_12)
      ]),
      _createElementVNode("div", {
        class: "rounded-r-4xl p-3 pr-5 mt-2 h-16 w-16 flex flex-row justify-end",
        style: {"background-color":"#d9eefb"},
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('review', 'quizzInstruction')))
      }, [
        _createElementVNode("img", {
          src: $setup.instructions,
          class: "w-6"
        }, null, 8 /* PROPS */, _hoisted_13)
      ])
    ]),
    _createCommentVNode(" Teacher guidance "),
    _createCommentVNode("div\n      class=\"absolute z-10 pointer-events-none bg-left bg-no-repeat bg-fill p-6 bottom-4 left-1/2 transform -translate-x-1/2\"\n      :style=\"{ backgroundImage: 'url(' + bgTextImage + ')' }\"\n    >\n      <div class=\"relative text-left\">\n        <div class=\"text-black text-sm p-4\" v-html=\"question.mening[round].omarStatement\"></div>\n      </div>\n    </div"),
    _createCommentVNode(" Avatars "),
    ($setup.players.length >= 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createVNode($setup["QuizzControl"], {
            "image-index": 0,
            player: $setup.players[0],
            onYes: _cache[4] || (_cache[4] = ($event: any) => ($setup.clickAnswer(0, $setup.Thumbs.up))),
            onNo: _cache[5] || (_cache[5] = ($event: any) => ($setup.clickAnswer(0, $setup.Thumbs.down)))
          }, null, 8 /* PROPS */, ["player"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.players.length >= 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode($setup["QuizzControl"], {
            "image-index": 2,
            player: $setup.players[2],
            onYes: _cache[6] || (_cache[6] = ($event: any) => ($setup.clickAnswer(2, $setup.Thumbs.up))),
            onNo: _cache[7] || (_cache[7] = ($event: any) => ($setup.clickAnswer(2, $setup.Thumbs.down)))
          }, null, 8 /* PROPS */, ["player"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.players.length >= 4)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createVNode($setup["QuizzControl"], {
            "image-index": 3,
            player: $setup.players[3],
            onYes: _cache[8] || (_cache[8] = ($event: any) => ($setup.clickAnswer(3, $setup.Thumbs.up))),
            onNo: _cache[9] || (_cache[9] = ($event: any) => ($setup.clickAnswer(3, $setup.Thumbs.down)))
          }, null, 8 /* PROPS */, ["player"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.players.length >= 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createVNode($setup["QuizzControl"], {
            "image-index": 1,
            player: $setup.players[1],
            onYes: _cache[10] || (_cache[10] = ($event: any) => ($setup.clickAnswer(1, $setup.Thumbs.up))),
            onNo: _cache[11] || (_cache[11] = ($event: any) => ($setup.clickAnswer(1, $setup.Thumbs.down)))
          }, null, 8 /* PROPS */, ["player"])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}