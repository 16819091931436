
import { defineComponent, ref, Ref, toRefs, ComputedRef, PropType, computed, watch, onMounted, nextTick } from 'vue'
import { useAppStore } from '@/store/useAppStore'
import { Overlay } from '../../structure/picturebookModels'

// Lottie Animation imports
import { LottieOptions } from '@/types/main'
import Lottie from '@/components/base/Lottie.vue'
import { AnimationItem } from 'lottie-web'

/* Overlay to present an image or sound upon interaction
 * This component aligns with the OverlayAppear model */
export default defineComponent({
  name: 'OverlayAppear',
  components: {
    Lottie,
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    showPulse: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 0,
    },
    overlay: {
      type: Object as PropType<Overlay>,
      required: true,
    },
  },
  emits: ['complete'],
  setup(props, context) {
    /* Private Variables */
    const appStore = useAppStore()
    const { overlay } = toRefs(props)
    let audio: HTMLAudioElement
    const overlayVisible = ref(false)
    const overlayInteractive = ref(false)
    const showThePulse = ref(false)
    const transformStyle: Ref<Record<string, string>> = ref({})

    let activate: () => void = () => undefined

    const setTransitionStyle = (activate: boolean) => {
      if (activate) {
        transformStyle.value[
          '-webkit-transform'
        ] = `scale(${overlay.value.transition.scale}, ${overlay.value.transition.scale}) translate(${overlay.value.transition.x}%,${overlay.value.transition.y}%)` // Safari
        transformStyle.value[
          'transform'
        ] = `scale(${overlay.value.transition.scale}, ${overlay.value.transition.scale}) translate(${overlay.value.transition.x}%,${overlay.value.transition.y}%)` // Standard syntax
      } else {
        transformStyle.value['-webkit-transform'] = 'scale(1,1) translate(0px,0px)' // Safari
        transformStyle.value['transform'] = 'scale(1,1) translate(0px,0px)' // Standard syntax
      }
    }

    const setDurationStyle = (duration: number) => {
      transformStyle.value['-webkit-transition'] = duration + 's linear'
      transformStyle.value['-moz-transition'] = duration + 's linear'
      transformStyle.value['-o-transition'] = duration + 's linear'
      transformStyle.value['transition'] = duration + 's linear'
    }

    // Setup

    if (overlay.value.type === 'sound') {
      audio = new Audio(overlay.value.file)
      audio.load()
      audio.onended = () => {
        context.emit('complete', overlay.value)
      }
      activate = () => {
        showThePulse.value = false
        if (audio) audio.play().catch()
      }
      if (overlay.value.autoStart) activate()
    } else if (overlay.value.type === 'image') {
      activate = () => {
        showThePulse.value = false
        overlayVisible.value = true
        if (overlay.value.transition.duration > 0) {
          setDurationStyle(overlay.value.transition.duration)
          setTransitionStyle(true)
          setTimeout(() => {
            context.emit('complete', overlay.value)
          }, overlay.value.transition.duration * 1000)
        } else {
          context.emit('complete', overlay.value)
        }
      }
    } else if (overlay.value.type === 'animation') {
      overlayVisible.value = overlay.value.autoVisible
      if (overlay.value.sound) {
        audio = new Audio(overlay.value.sound)
        audio.load()
      }
      activate = () => {
        showThePulse.value = false
        overlayVisible.value = true
        nextTick(() => {
          if (overlay.value.sound) audio.play()
          playAnimation()
        })
      }
    }

    // ------------- Animation settings ------------
    let animation: AnimationItem
    const lottieOptions: ComputedRef<LottieOptions> = computed(() => {
      return {
        path: overlay.value.file,
        autoplay: false,
        loop: overlay.value.loop,
      }
    })
    const handleAnimation = (animationItem: AnimationItem) => {
      animation = animationItem
      animation.addEventListener('complete', () => {
        if (overlay.value.autoHide) animation.goToAndStop(0)
        context.emit('complete', overlay.value)
      })
    }
    const playAnimation = () => {
      if (animation && animation.isPaused) animation.play()
      else animation.stop()
    }

    // ---------------------------------------------

    const backgroundImage = () => {
      return overlay.value.type === 'image' ? `background-image: url(${overlay.value.file})` : ''
    }

    const overlayStyle = computed(() => ({
      width: overlay.value.coords.w + '%',
      height: overlay.value.coords.h + '%',
      top: overlay.value.coords.y + '%',
      left: overlay.value.coords.x + '%',
      ...transformStyle.value,
    }))

    const triggerStyle = computed(() => ({
      width: overlay.value.trigger.w + '%',
      height: overlay.value.trigger.h + '%',
      top: overlay.value.trigger.y + '%',
      left: overlay.value.trigger.x + '%',
    }))

    const setupOverlay = () => {
      overlayInteractive.value = false
      overlayVisible.value = false
      setDurationStyle(0)
      setTransitionStyle(false)
      setTimeout(
        () => {
          overlayInteractive.value = true
          if (overlay.value.autoStart) activate()
        },
        overlay.value.triggerDelay * 1000,
        //disableDelays ? 0 : overlay.value.triggerDelay * 1000 // For TESTING, reduce delay to 0
      )
    }

    const reset = () => {
      overlayVisible.value = false
      overlayInteractive.value = false
      if (animation) animation.goToAndStop(0)
      if (audio) audio.pause()
    }

    onMounted(() => {
      if (props.enabled) setupOverlay()
    })

    watch(
      () => props.showPulse,
      (curr) => {
        showThePulse.value = curr
      },
    )

    watch(
      () => props.enabled,
      (enabled) => {
        if (enabled) setupOverlay()
      },
    )

    watch(
      () => props.page,
      () => {
        reset()
      },
    )

    return {
      overlayStyle,
      triggerStyle,
      overlayInteractive,
      overlayVisible,
      backgroundImage,
      lottieOptions,
      activate,
      disableDelays: appStore.getters.disableDelays,
      handleAnimation,
      playAnimation,
      showThePulse,
    }
  },
})
