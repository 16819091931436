
import { defineComponent, computed } from 'vue'
import { useAppStore } from './store/useAppStore'
import { useUserStore } from './store/useUserStore'
import { useRouter } from 'vue-router'
import { USER_ROLE } from '@/constants'
import DialogBox from './components/base/DialogBox.vue'

export default defineComponent({
  name: 'App',
  components: {
    DialogBox,
  },
  setup() {
    const router = useRouter()
    const appStore = useAppStore()
    const userStore = useUserStore()
    appStore.actions.detectOldApp()

    const fadeState = computed(() => {
      return { opacity: appStore.getters.fade.value ? '0' : '1' }
    })
    const canSeeMonitor = computed(() => {
      const hasRights =
        userStore.getters.myUser.value.profile.role === USER_ROLE.monitor ||
        userStore.getters.myUser.value.profile.role === USER_ROLE.admin ||
        userStore.getters.myUser.value.profile.role === USER_ROLE.logs
      return hasRights && !router.currentRoute.value.path.includes('monitor')
    })

    // If we arrived here from a page reload, make sure to set up again
    if (router.currentRoute.value.path !== '/') {
      router.push('/postlogin')
    }

    return {
      fadeState,
      canSeeMonitor,
      disableDelays: appStore.getters.disableDelays,
      monitor: () => router.push('/monitor'),
      dialogConfig: appStore.getters.dialogConfig,
    }
  },
})
