export default function doWorkerTask(
  workerFunction: () => void,
  input: { channels: Float32Array[]; speed?: number },
  buffers: ArrayBuffer[],
): Promise<Float32Array[]> {
  // Create worker
  const fnString = '(' + workerFunction.toString().replace('"use strict";', '') + ')();'
  const workerBlob = new Blob([fnString], { type: 'application/javascript; charset=utf-8' })
  const workerBlobURL = window.URL.createObjectURL(workerBlob)
  const worker = new Worker(workerBlobURL)

  // Run worker
  return new Promise(function (resolve) {
    worker.onmessage = function (e) {
      resolve(e.data)
    }
    worker.postMessage(input, buffers)
  })
}
