
import { defineComponent, ref, computed, onMounted, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { useGameStore } from '@/store/useGameStore'
import { useUserStore } from '@/store/useUserStore'
import ItemSelection from '@/components/base/ItemSelection.vue'
import Button from '@/components/base/Button.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import Avatar from '@/components/base/Avatar.vue'

/* Interfaces */
import { Game, User } from '@/types/main'

const messages = {
  no: {
    selectGame: 'Velg et spill eller lag et nytt',
    playersInGame: 'Deltager i dette spillet',
    otherPlayersAvailable: 'Andre spillere tilgjengelig',
    enterDetails: 'Vennligst skriv inn dine opplysninger',
    newGame: 'Nytt spill',
    noGames: 'Ingen spill funnet',
    begin: 'Begynne!',
    name: 'navn',
    sharing: 'del til grupper',
  },
  en: {
    selectGame: 'Select a Game or create a new one',
    playersInGame: 'Players in this Game',
    otherPlayersAvailable: 'Other players available',
    enterDetails: 'Please enter your details',
    newGame: 'New game',
    noGames: 'No games found',
    begin: 'Begin!',
    name: 'name',
    sharing: 'share to groups',
  },
}

interface GroupShareOptionListItem {
  title: string
  id: string
}

export default defineComponent({
  name: 'SelectGame',
  components: {
    Button,
    Avatar,
    ItemSelection,
    AnswerInput,
  },
  setup() {
    const { t } = useI18n({ messages })
    const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    const telRegex = new RegExp(/^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2,6}$/im)
    const gameStore = useGameStore()
    const userStore = useUserStore()
    const gameSelected = ref(false)
    const usersGroups: Ref<GroupShareOptionListItem[]> = ref([])
    const editingGame: Ref<Game> = ref(new Game())
    const validators: Ref<Record<string, boolean>> = ref({})
    const theUser: Ref<User> = ref(new User())
    const lackingDetails = ref(false)

    onMounted(() => {
      const u = userStore.getters.selectedUser.value
      theUser.value.update(u)
      lackingDetails.value =
        u.profile.username === 'username unknown' || !emailRegex.test(u.profile.email as string) || !telRegex.test(u.profile.mobil as string)
      if (gameStore.getters.selectedGame.value) gameSelected.value = true
      usersGroups.value = theUser.value.groups.map((g) => {
        return {
          id: g._id,
          title: g.name,
        }
      })
    })

    const done = () => {
      // This is an attempt to activate sound in the browser..
      /*       const triggerAudioData =
        'data:audio/mpeg;base64,/+MYxAAAAANIAUAAAASEEB/jwOFM/0MM/90b/+RhST//w4NFwOjf///PZu////9lns5GFDv//l9GlUIEEIAAAgIg8Ir/JGq3/+MYxDsLIj5QMYcoAP0dv9HIjUcH//yYSg+CIbkGP//8w0bLVjUP///3Z0x5QCAv/yLjwtGKTEFNRTMuOTeqqqqqqqqqqqqq/+MYxEkNmdJkUYc4AKqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq'
      const triggerAudio = new Audio()
      if (triggerAudio.canPlayType('audio/mpeg') == 'probably') console.log('Can play audio!')
      triggerAudio.addEventListener(
        'canplaythrough',
        function () {
          console.log('Can play audio!')
        },
        false,
      )
      triggerAudio.addEventListener(
        'error',
        function () {
          console.log('Cant play audio :(')
        },
        false,
      )
      triggerAudio.addEventListener(
        'loaded',
        () => {
          triggerAudio.play()
        },
        false,
      )
      triggerAudio.src = triggerAudioData
      triggerAudio.load() */

      const sp = gameStore.getters.selectedGame.value
      if (sp) gameStore.actions.getGameDetails(sp._id).then(() => router.push('/dashboard/players'))
    }

    const usernameDone = () => {
      userStore.actions.updateUser(theUser.value).then(() => {
        userStore.actions.selectUser(theUser.value)
        lackingDetails.value = false
      })
    }

    const allValid = computed(() => {
      return Object.values(validators.value).every((v) => v)
    })

    const gameList = computed(() => {
      const currentProject = userStore.getters.selectedUserProject.value
      const games = gameStore.getters.games.value.filter((g) => g.project.id === currentProject._id)
      return games.filter((game) => {
        // Mobile device may have stored previous games that should not be shown anymore
        return (
          game.owner === theUser.value._id ||
          theUser.value.groups.some((grp) => game.sharing.groups.includes(grp._id) || game.sharing.users.some((us) => us === theUser.value._id))
        )
      })
    })

    const playerListForGame = computed(() => {
      const players = gameStore.getters.players.value
      return (g: Game) => players.filter((p) => g.players.includes(p._id))
    })

    function selectGame(item: Game) {
      gameSelected.value = true
      gameStore.actions.selectGame(item)
    }
    gameSelected.value = gameList.value.some((g) => g.selected)

    function newGame() {
      gameStore.actions.addGame()
    }

    function back() {
      router.push('/dashboard/games')
    }

    function editGame(g: Game) {
      editingGame.value.update(g)
    }

    async function saveEditedGame() {
      await gameStore.actions.updateGame(editingGame.value)
      editingGame.value = new Game()
    }

    function testme(value: Event) {
      console.log(value.target)
    }

    return {
      t,
      user: userStore.getters.selectedUser,
      gameSelected,
      lackingDetails,
      theUser,
      selectGame,
      gameList,
      playerListForGame,
      back,
      done,
      usernameDone,
      editingGame,
      newGame,
      editGame,
      saveEditedGame,
      validators,
      allValid,
      usersGroups,
      testme,
    }
  },
  methods: {},
})
