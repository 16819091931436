
import { defineComponent, PropType } from 'vue'
import { Player } from '@/types/main'
import { Thumbs } from '@/templates/slplus/structure/questionModels'
import Avatar from '@/components/base/Avatar.vue'

import yes from '@/assets/tasks/enig/yes_enig.svg'
import no from '@/assets/tasks/enig/no_enig.svg'

export default defineComponent({
  name: 'PlaceItem',
  components: {
    Avatar,
  },
  props: {
    player: { required: true, type: Object as PropType<Player> },
  },
  emits: ['yes', 'no'],
  setup(props, context) {
    const click = (answer: Thumbs) => {
      answer === Thumbs.up ? context.emit('yes') : context.emit('no')
    }
    return {
      Thumbs,
      yes,
      no,
      click,
    }
  },
})
