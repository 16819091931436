import Jungle from '../lib/jungle'

export default async function pitchTransform(
  audioBuffer: AudioBuffer,
  opts: { pitchMod: number } /*nagive=lower, positive=higher*/,
): Promise<AudioBuffer> {
  const ctx = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate)

  const source = ctx.createBufferSource()
  source.buffer = audioBuffer

  const pitchChangeEffect = new Jungle(ctx)

  const compressor = ctx.createDynamicsCompressor()
  //let filter = ctx.createBiquadFilter();
  //filter.type = "lowpass";
  //filter.frequency.value = 10000;

  source.connect(pitchChangeEffect.input)
  pitchChangeEffect.output.connect(compressor)
  pitchChangeEffect.setPitchOffset(opts.pitchMod)

  compressor.connect(ctx.destination)
  //filter.connect(ctx.destination);

  source.start(0)
  return await ctx.startRendering()
}
