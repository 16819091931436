
import { defineComponent, PropType, ref, reactive, computed } from 'vue'
import { Group } from '../../types/main'
import { useUserStore } from '../../store/useUserStore'
import AnswerInput from '../base/AnswerInput.vue'
import SlButton from '../base/SlButton.vue'

export default defineComponent({
  name: 'MonitorGroupItem',
  components: {
    AnswerInput,
    SlButton,
  },
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    edit: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
  },
  emits: ['selected'],
  setup(props, context) {
    const { actions: userActions } = useUserStore()
    const showSave = ref(false)

    const g = new Group(props.group)
    const editableGroup = reactive<Group>(g)

    const saveGroup = async () => {
      await userActions.updateGroup(editableGroup)
      showSave.value = false
    }

    const deleteGroup = async () => {
      await userActions.deleteGroup(editableGroup)
      showSave.value = false
    }

    const saveActive = () => {
      showSave.value = true
    }

    const selectMe = () => {
      context.emit('selected')
    }

    return {
      // Computed
      showSave: computed(() => showSave.value),
      editableGroup,

      // Methods
      saveGroup,
      saveActive,
      selectMe,
      deleteGroup,
    }
  },
})
