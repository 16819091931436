
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SLButton',
  props: {
    logo: {
      type: String,
      default: '',
    },
    childclass: {
      type: String,
      default: '',
    },
    textcolour: {
      type: String,
      default: 'text-black',
    },
    backgroundcolour: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['vclick'],
  setup(props, context) {
    function click($event: Event) {
      if (!props.disabled) {
        context.emit('vclick', $event)
      }
    }
    return {
      click,
    }
  },
})
