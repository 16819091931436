import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-369019c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute top-0 left-0 flex w-full mt-12 mx-5 justify-start" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col flex-grow justify-center items-center h-full p-4"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col self-start items-start ml-10 mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SlButton = _resolveComponent("SlButton")!
  const _component_GridItem = _resolveComponent("GridItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-full relative", _ctx.backgroundClassName])
  }, [
    _createCommentVNode("div\n      v-if=\"atLevel === 0\"\n      class=\"absolute top-24 right-4 w-16 h-16 bg-contain bg-no-repeat\"\n      :style=\"{ backgroundImage: `url(${BookImage})` }\"\n      @click=\"showLibrary()\"\n    ></div"),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.atLevel > 0)
        ? (_openBlock(), _createBlock(_component_SlButton, {
            key: 0,
            "background-class": "bg-red-button",
            "extra-classes": "w-12 h-12",
            class: _normalizeClass({ levelCompleted: 'bg-white, text-teal-background' }),
            logo: "leftArrow",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.upALevel()))
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode(_Transition, {
      mode: "out-in",
      name: _ctx.transitionName
    }, {
      default: _withCtx(() => [
        (_ctx.layoutVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createCommentVNode("MediaLibrary v-if=\"libraryVisible\"></MediaLibrary"),
              (_ctx.atLevel === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_ctx.selectedSet)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          class: _normalizeClass(["text-5xl text-blue-button", { levelCompleted: 'bg-white, text-teal-title' }])
                        }, _toDisplayString(_ctx.selectedSet.word), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("p", {
                      class: _normalizeClass(["text-red-button text-lg pl-1", { levelCompleted: 'text-white' }])
                    }, _toDisplayString(_ctx.t('week')) + " " + _toDisplayString(_ctx.selectedIndex + 1), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.selectedList.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["grid hideScroll", _ctx.gridLevelClassName])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedList, (item, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: item._id
                      }, [
                        (!_ctx.isMobileApp && _ctx.atLevel === 0 && index % 2 === 1)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(`${_ctx.gridLevelClassName}-item`)
                            }, null, 2 /* CLASS */))
                          : _createCommentVNode("v-if", true),
                        _createVNode(_component_GridItem, {
                          class: _normalizeClass(`${_ctx.gridLevelClassName}-item`),
                          data: item,
                          level: _ctx.atLevel,
                          index: index,
                          disabled: _ctx.itemDisabled(item, index),
                          completed: _ctx.itemWasCompleted(item),
                          onClickgriditem: ($event: any) => (_ctx.downALevel(item, index))
                        }, null, 8 /* PROPS */, ["class", "data", "level", "index", "disabled", "completed", "onClickgriditem"]),
                        (!_ctx.isMobileApp && _ctx.atLevel === 0 && index % 2 === 0)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: _normalizeClass(`${_ctx.gridLevelClassName}-item`)
                            }, null, 2 /* CLASS */))
                          : _createCommentVNode("v-if", true)
                      ], 64 /* STABLE_FRAGMENT */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ], 2 /* CLASS */))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["name"])
  ], 2 /* CLASS */))
}