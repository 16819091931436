
import { defineComponent, ref, Ref, computed } from 'vue'
import { USER_ROLE } from '@/constants'
import { Group, Game, User } from '@/types/main'
import { useAppStore } from '@/store/useAppStore'
import { useUserStore } from '@/store/useUserStore'
import { useGameStore } from '@/store/useGameStore'
import SelectionBox from '@/components/base/SelectionBox.vue'
import SlButton from '@/components/base/SlButton.vue'
import MonitorGameItem from '@/components/admin/MonitorGameItem.vue'

interface GroupOptionListItem {
  itemName: string
  item: Group | undefined
}
interface UserOptionListItem {
  itemName: string
  item: User | undefined
}
export default defineComponent({
  name: 'MonitorGames',
  components: {
    MonitorGameItem,
    SelectionBox,
    SlButton,
  },
  setup() {
    const { getters: appGetters } = useAppStore()
    const { getters: userGetters, actions: userActions } = useUserStore()
    const { getters: gameGetters, actions: gameActions } = useGameStore()
    const showSave = ref(false)
    const hasAdminRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.admin))

    const selectedGroup: Ref<GroupOptionListItem> = ref({
      item: undefined,
      itemName: 'no group selected',
    })
    const selectedUser: Ref<UserOptionListItem> = ref({
      item: undefined,
      itemName: 'no user selected',
    })

    const games = computed(() => {
      return gameGetters.games.value
        .filter((g) => !selectedGroup.value.item || (selectedGroup.value.item && g.sharing.groups.includes(selectedGroup.value.item._id)))
        .filter((g) => !selectedUser.value.item || (selectedUser.value.item && g.owner == selectedUser.value.item._id))
    })

    gameActions.getPlayers('', '')

    // --------------- Participant functions -----------------

    const selectGame = (g: Game) => {
      gameActions.selectGame(g)
    }

    const addGame = () => {
      gameActions.addGame().then((g) => selectGame(g))
    }

    const filterGames = () => {
      const groupId = selectedGroup.value.item ? selectedGroup.value.item._id : ''
      const userId = selectedUser.value.item ? selectedUser.value.item._id : ''
      gameActions.getGames(groupId, userId).then(() => {
        if (games.value.length > 0) selectGame(games.value[0])
      })
    }

    const removeParticipant = async () => {
      //participantAction.removeParticipant(selectedGame.value._id)
      // TODO:  Remove participant at server, Update user front end, update user
      // !!!  LOW PRIORITY.  THIS SHOULD INCLUDE CONFIRMATION / WARNING !!!
    }

    const groupOptionList = computed((): GroupOptionListItem[] => {
      const gol: GroupOptionListItem[] = userGetters.allGroups.value.map((g) => ({
        item: g,
        itemName: g.name,
      }))
      gol.unshift({
        item: undefined,
        itemName: '(none)',
      })
      return gol
    })

    const userOptionList = computed((): UserOptionListItem[] => {
      const uol: UserOptionListItem[] = userGetters.allUsers.value.map((u) => ({
        item: u,
        itemName: u.profile.fullName,
      }))
      uol.unshift({
        item: undefined,
        itemName: '(none)',
      })
      return uol
    })

    // Fetch participants inital value for the default location
    gameActions.getGames('', '')

    return {
      // Computed
      games,
      status: appGetters.status,
      hasAdminRole,
      showSave,
      groupOptionList,
      userOptionList,
      selectedUser,
      selectedGroup,
      selectedGame: gameGetters.selectedGame,

      // Methods
      filterGames,
      selectGame,
      addGame,
      removeParticipant,
      // schoolWasSelected,
    }
  },
})
