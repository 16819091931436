import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bg-gray-200 h-full" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col items-center h-full pb-8"
}
const _hoisted_3 = { class: "flex flex-col flex-grow justify-center items-center" }
const _hoisted_4 = { class: "mt-8 text-gray-type" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex flex-row mx-6 pt-12 sm:pt-4" }
const _hoisted_7 = { class: "h-12 inline-flex items-center p-4" }
const _hoisted_8 = { class: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 m-6 max-h-128 overflow-y-auto" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["id", "onClick"]
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col justify-center items-center py-1"
}
const _hoisted_12 = {
  key: 1,
  class: "flex flex-col h-full"
}
const _hoisted_13 = { class: "flex flex-grow" }
const _hoisted_14 = { class: "grid grid-cols-2 place-items-center place-content-center w-full" }
const _hoisted_15 = { class: "flex place-items-center justify-center h-10 font-semibold text-lg" }
const _hoisted_16 = {
  key: 0,
  class: "flex flex-row justify-center py-1"
}
const _hoisted_17 = { class: "flex flex-row justify-between px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnswerInput = _resolveComponent("AnswerInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_ItemSelection = _resolveComponent("ItemSelection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.lackingDetails)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('enterDetails')), 1 /* TEXT */),
            _createVNode(_component_AnswerInput, {
              modelValue: _ctx.theUser.profile.fullName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.theUser.profile.fullName) = $event)),
              valid: _ctx.validators.fullname,
              "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.validators.fullname) = $event)),
              mode: "text",
              label: "name",
              "custom-size": "24",
              underline: false,
              class: "my-8"
            }, null, 8 /* PROPS */, ["modelValue", "valid"]),
            _createVNode(_component_AnswerInput, {
              modelValue: _ctx.theUser.profile.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.theUser.profile.email) = $event)),
              valid: _ctx.validators.email,
              "onUpdate:valid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.validators.email) = $event)),
              mode: "email",
              label: "email",
              placeholder: "user@example.com",
              "custom-size": "24",
              underline: false,
              class: "mt-4 mb-12"
            }, null, 8 /* PROPS */, ["modelValue", "valid"]),
            _createVNode(_component_AnswerInput, {
              modelValue: _ctx.theUser.profile.mobil,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.theUser.profile.mobil) = $event)),
              valid: _ctx.validators.mobil,
              "onUpdate:valid": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.validators.mobil) = $event)),
              mode: "tel",
              label: "mobil",
              placeholder: "91234567",
              "custom-size": "24",
              underline: false,
              class: "mt-4 mb-12"
            }, null, 8 /* PROPS */, ["modelValue", "valid"]),
            _createVNode(_component_Button, {
              disabled: !_ctx.allValid,
              childclass: 'w-32',
              backgroundcolour: 'bg-white',
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.usernameDone()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('continue')) + "...", 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createCommentVNode("Button class=\"w-12\" :childclass=\"'w-12'\" :backgroundcolour=\"'bg-red-button'\" logo=\"leftArrow\" @click=\"back()\"></Button"),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_ctx.t('selectGame')), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.gameList.length === 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.t('noGames')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gameList, (g) => {
              return (_openBlock(), _createBlock(_component_ItemSelection, {
                key: g._id,
                class: "w-48",
                corners: true,
                selected: g.selected
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    id: `div-selectGame-${g.name}`,
                    class: "cursor-pointer flex flex-col h-full",
                    onClick: ($event: any) => (_ctx.selectGame(g))
                  }, [
                    (_ctx.editingGame._id == g._id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_AnswerInput, {
                            modelValue: _ctx.editingGame.name,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editingGame.name) = $event)),
                            mode: "text",
                            label: _ctx.t('name'),
                            border: true,
                            placeholder: "Sample name",
                            "custom-size": "8",
                            underline: false,
                            class: "m-2 w-36"
                          }, null, 8 /* PROPS */, ["modelValue", "label"]),
                          _createVNode(_component_AnswerInput, {
                            "multi-choice-value": _ctx.editingGame.sharing.groups,
                            "onUpdate:multi-choice-value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editingGame.sharing.groups) = $event)),
                            label: _ctx.t('sharing'),
                            mode: "multiChoice",
                            options: _ctx.usersGroups,
                            underline: false,
                            class: "m-2 w-36",
                            onChange: _ctx.testme
                          }, null, 8 /* PROPS */, ["multi-choice-value", "label", "options", "onChange"]),
                          _createVNode(_component_Button, {
                            id: "button-monitor",
                            childclass: 'w-32',
                            backgroundcolour: 'bg-white',
                            textcolour: 'text-black',
                            onVclick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.saveEditedGame()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('save')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playerListForGame(g), (p) => {
                                return (_openBlock(), _createBlock(_component_Avatar, {
                                  id: `game-${g._id}-player-${p._id}`,
                                  key: `game-${g._id}-player-${p._id}`,
                                  layout: p.profile.avatar,
                                  class: "w-12 h-12"
                                }, null, 8 /* PROPS */, ["id", "layout"]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ])
                          ]),
                          _createElementVNode("p", _hoisted_15, _toDisplayString(g.name), 1 /* TEXT */),
                          (g.owner === _ctx.theUser._id)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                (_ctx.user.status.canEditGames)
                                  ? (_openBlock(), _createBlock(_component_Button, {
                                      key: 0,
                                      id: "button-monitor",
                                      childclass: 'w-32',
                                      backgroundcolour: 'bg-white',
                                      textcolour: 'text-black',
                                      onVclick: ($event: any) => (_ctx.editGame(g))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t('edit')), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onVclick"]))
                                  : _createCommentVNode("v-if", true)
                              ]))
                            : _createCommentVNode("v-if", true)
                        ]))
                  ], 8 /* PROPS */, _hoisted_10)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["selected"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_17, [
            (_ctx.user.status.canEditGames)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  backgroundcolour: 'bg-white',
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.newGame()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('newGame')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _withDirectives(_createVNode(_component_Button, {
              id: "button-done",
              class: "mr-4 place-self-center",
              childclass: 'w-32',
              backgroundcolour: 'bg-blue-button',
              textcolour: 'text-black',
              onVclick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.done()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('continue')) + "...", 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 512 /* NEED_PATCH */), [
              [_vShow, _ctx.gameSelected]
            ])
          ])
        ]))
  ]))
}