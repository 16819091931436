
import { defineComponent, PropType, toRefs, Ref, ref, computed, onMounted, nextTick } from 'vue'
import { shuffleItems, playSounds, stopSounds, soundIsPlaying } from '@/utilities'
import { ControlPosition, DraggableEvent } from '@braks/revue-draggable'
import { Retell, RetellPage } from '@/templates/slplus/structure/questionModels'
import Button from '@/components/base/Button.vue'
import inst1Sound from '@/assets/tasks/retell/Gjenfort_instruks1.mp3'

interface DraggablePage {
  page: RetellPage
  position: ControlPosition
  element?: HTMLElement
}

export default defineComponent({
  name: 'SequenceSubtask',
  components: {
    Button,
  },
  props: {
    question: {
      type: Object as PropType<Retell>,
      required: true,
    },
  },
  setup(props) {
    const { question } = toRefs(props)
    const shuffledPages: Ref<DraggablePage[]> = ref([])
    let draggedItem: HTMLElement | undefined
    const activeDrags = ref(0)
    const omarActive = ref(false)

    onMounted(() => {
      question.value.pages.forEach((p) => (p.active = false))
      shuffledPages.value = shuffleItems(question.value.pages).map((p) => ({ page: p, position: { x: 0, y: 0 } }))
    })

    // Drop box
    function onStart(e: DraggableEvent, page: DraggablePage) {
      activeDrags.value++
      const el = e && (e.event.target as HTMLElement)
      page.element = el
      if (el && el.classList.contains('drag-item')) {
        draggedItem = el
        console.log(`Dragging item: ${draggedItem.id}`)
      }
    }

    function onDrop(e: DraggableEvent, dPage?: DraggablePage) {
      activeDrags.value--
      let x = 0
      let y = 0
      if (e.event.type.includes('touch')) {
        const te = e.event as TouchEvent
        x = te.changedTouches[0].clientX
        y = te.changedTouches[0].clientY
      } else {
        const me = e.event as MouseEvent
        x = me.clientX
        y = me.clientY
      }
      const el = document.elementFromPoint(x, y)
      if (dPage && el && el.classList.contains('drop-target')) {
        const id = el.id
        console.log(`Dropped page index ${dPage.page.index} onto box ${id}.`)
        el.classList.remove('hovered')
        nextTick(() => {
          const p = question.value.pages.find((p) => p.id === dPage.page.id)
          const index = parseInt(id.slice(-1))
          if (p && index === p.index) p.active = true
          else dPage.position = { x: 0, y: 0 }
          if (allPagesDone.value) omarActive.value = true
        })
      } else if (dPage) dPage.position = { x: 0, y: 0 }
    }

    function onDropAreaMouseEnter(e: MouseEvent | TouchEvent) {
      const el = e && (e.target as HTMLElement)
      if (activeDrags.value && el) {
        el.classList.add('hovered')
      }
    }

    function onDropAreaMouseLeave(e: MouseEvent | TouchEvent) {
      const el = e && (e.target as HTMLElement)
      if (activeDrags.value && el) {
        el.classList.remove('hovered')
      }
    }

    function activateOmar() {
      omarActive.value = true
      playSounds([inst1Sound], () => {
        omarActive.value = false
      })
    }

    const allPagesDone = computed(() => question.value.pages.every((p) => p.active))

    const playInstruction = () => {
      activateOmar()
    }

    return {
      activeDrags,
      shuffledPages,
      omarActive,
      activateOmar,
      allPagesDone,
      playInstruction,
      stopSounds,
      soundIsPlaying,

      // Drag events
      onStart,
      onDrop,
      onDropAreaMouseEnter,
      onDropAreaMouseLeave,
    }
  },
})
