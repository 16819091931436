
import { defineComponent, ref, computed } from 'vue'
import router from '../../router'
import { USER_ROLE } from '../../constants'
import { useUserStore } from '../../store/useUserStore'
import { useGameStore } from '../../store/useGameStore'
import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_LICENSE_KEY as string)

export default defineComponent({
  name: 'MonitorComponent',
  setup() {
    const { actions: userActions, getters: userGetters } = useUserStore()
    const { actions: gameActions } = useGameStore()

    const selectedTab = ref('users')
    const selectedSubTab = ref('profile')

    const selectTab = (main: string, secondary: string) => {
      selectedTab.value = main
      selectedSubTab.value = secondary
    }

    const restart = () => {
      const project = userGetters.selectedUserProject
      gameActions.getGames()
      if (project) router.push(`/postlogin`)
    }

    /*Computed functions */
    const hasAdminRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.admin))
    const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))
    const hasLogsRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.logs))

    return {
      // Computed
      hasAdminRole,
      hasMonitorRole,
      hasLogsRole,
      selectedTab,
      selectedSubTab,

      // Methods
      restart,
      selectTab,
    }
  },
})
