import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full h-full absolute top-0 left-0" }
const _hoisted_2 = { class: "w-full h-full relative" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lottie = _resolveComponent("Lottie")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        id: "placement-container",
        class: "absolute flex justify-center items-center bg-no-repeat bg-cover bg-top",
        style: _normalizeStyle(_ctx.overlayStyle)
      }, [
        (_ctx.overlay.type === 'image' && (_ctx.overlay.autoVisible || _ctx.overlayVisible))
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "absolute top-0 left-0 w-full pointer-events-none",
              src: _ctx.overlay.file,
              alt: "overlay image"
            }, null, 8 /* PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true),
        (_ctx.lottieOptions.path)
          ? _withDirectives((_openBlock(), _createBlock(_component_Lottie, {
              key: 1,
              class: "absolute top-0 left-0 w-full pointer-events-none",
              options: _ctx.lottieOptions,
              onAnimationCreated: _ctx.handleAnimation
            }, null, 8 /* PROPS */, ["options", "onAnimationCreated"])), [
              [_vShow, _ctx.overlay.type === 'animation' && (_ctx.overlay.autoVisible || _ctx.overlayVisible)]
            ])
          : _createCommentVNode("v-if", true)
      ], 4 /* STYLE */),
      _withDirectives(_createElementVNode("div", {
        id: "trigger-container",
        class: "absolute flex justify-center items-center z-50",
        style: _normalizeStyle(_ctx.triggerStyle),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activate && _ctx.activate(...args)))
      }, null, 4 /* STYLE */), [
        [_vShow, _ctx.overlayInteractive]
      ])
    ])
  ]))
}