
import { defineComponent, computed, ref, Ref, onBeforeUnmount, onMounted } from 'vue'
import router from '@/router'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import SlButton from '@/components/base/SlButton.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
// import WebAudioTest from '@/components/WebAudioTest.vue'
import { baseUrl, appVersion, LanguageCodes } from '../../constants'
import { useAppStore } from '../../store/useAppStore'
import { useDeviceService } from '../../composition/useDevice'
import { LocalUser } from '../../types/main'

const jwtExpiryConstant = process.env.VUE_APP_JWT_EXPIRY || '0'
const jwtExpiry = Number.parseInt(jwtExpiryConstant)

const messages = {
  no: {
    openidLoginButton: 'Logg inn med FEIDE ID Porten',
    enterMobilNumber: 'Mobilnummer',
    enterPass: 'Ditt passord',
    enterMobilOTC: 'Engangskode (fra SMS)',
    invalidEntry: 'Mangler mobilnummer eller passord',
    phoneNumberError: 'Error med nummer',
    'User not found': 'Bruker ikke funnet',
    'LOGIN: Incorrect password': 'Feil passord',
    'wrong otc': 'Feil engangspassord',
    'sms login accepted': 'Det kan ta litt tid før du får engangskode med SMS.',
  },
  nn: {
    openidLoginButton: 'Logg inn med FEIDE ID Porten',
    enterMobilNumber: 'Mobilnummer',
    enterPass: 'Ditt passord',
    enterMobilOTC: 'Engangskode (fra SMS)',
    invalidEntry: 'Mangler mobilnummer eller passord',
    phoneNumberError: 'Error med nummer',
    'User not found': 'Bruker ikke funnet',
    'LOGIN: Incorrect password': 'Feil passord',
    'wrong otc': 'Feil engangspassord',
    'sms login accepted': 'Det kan ta litt tid før du får engangskode med SMS.',
  },
  en: {
    openidLoginButton: 'Log in with FEIDE ID Porten',
    enterMobilNumber: 'Mobile phone number',
    enterPass: 'Your password',
    enterMobilOTC: 'One time code (from SMS)',
    invalidEntry: 'Invalid number or password',
    phoneNumberError: 'Error processing phone number',
    'User not found': 'User not found',
    'LOGIN: Incorrect password': 'Incorrect password',
    'wrong otc': 'Wrong one time code',
    'sms login accepted': 'It may take some time before you get the one time code by SMS.',
  },
}

interface LocalUserWithExpiry extends LocalUser {
  expiresIn: Ref<string>
  valid: Ref<boolean>
}

export default defineComponent({
  name: 'LandingPage',
  components: {
    SlButton,
    AnswerInput,
    // WebAudioTest,
  },
  setup() {
    const { t, locale } = useI18n({ messages })
    const { getters: appGetters, actions: appActions } = useAppStore()
    const { getters: deviceGetters } = useDeviceService()
    const devMode = process.env.NODE_ENV === 'development'
    const testMode = process.env.NODE_ENV === 'testing' || process.env.NODE_ENV === 'development'
    const mode = ref(devMode ? 'login' : 'smsLogin')
    const pinCode = ref('')
    const mobileNumber = ref('')
    const otc = ref('')
    const message = ref('')
    const showTestLogin = ref(false)
    const showLearningLogin = ref(false)
    const selectedUser = ref()
    const validators: Ref<Record<string, boolean>> = ref({})
    let timer: ReturnType<typeof setTimeout>
    const localUsers = Object.values(appGetters.persistedLocalUsers.value)
    const localUsersWithExpiry: LocalUserWithExpiry[] = localUsers.map((lu) => ({
      ...lu,
      expiresIn: ref(''),
      valid: ref(false),
    }))

    onMounted(() => {
      if (Object.keys(LanguageCodes).some((language) => language == locale.value)) {
        const lang: LanguageCodes = locale.value as LanguageCodes
        appActions.setLanguageCode(lang)
      } else appActions.setLanguageCode(LanguageCodes.no)

      if (router.currentRoute.value.params.admin === 'admin') {
        mode.value = 'login'
      }
    })

    function momentFormat(date: Date) {
      return moment(date).format('ddd, hA')
    }

    showTestLogin.value = devMode
    showLearningLogin.value = testMode

    function PINlogin() {
      mode.value = 'pin-user'
    }

    function DPlogin(testing = false, username = 'testuser1') {
      let feideLoginUrl = `${baseUrl}/auth/dataporten/login`
      // If using Cordova, activate the cordova-oauth plugin
      const testingString = testing ? `&testing=${username}` : ''
      if (window.cordova) {
        feideLoginUrl += `?&client=mobileApp&remember=true${testingString}`
        window.OAuth(feideLoginUrl, 'oauth:dataporten', undefined, (success) => {
          console.log(success)
        })
      } else {
        feideLoginUrl += `?&client=webApp${testingString}`
        window.location.href = feideLoginUrl
      }
      // NOTE: Call OAuth without 'oauth:' name to open the system browser
      // window.OAuth('https://www.uio.no/tjenester/it/lagring-samarbeid/gsuite/', '_system', 'location=yes');
    }
    // This logs in the user without going through the FIEDE process, so long as they have a valid token
    function localUserLogin() {
      if ((selectedUser.value.pin === pinCode.value && deviceGetters.deviceOnline) || process.env.NODE_ENV == 'development') {
        selectedUser.value.lastLogin = new Date()
        appActions.setCurrentLocalUser(selectedUser.value)
        appActions.tokenLogin().then((success: boolean) => {
          if (!success) mode.value = 'login'
        })
      }
    }

    const allValid = computed(() => {
      return Object.values(validators.value).every((v) => v)
    })

    function feedbackMessage(m: string) {
      clearTimeout(timer)
      message.value = m
      timer = setTimeout(() => {
        message.value = ''
      }, 1500)
    }

    function selectUser(u: LocalUser) {
      localUsers.forEach((user) => (user.selected = false))
      u.selected = true
      selectedUser.value = u
      mode.value = 'pp'
    }

    async function sendPhoneNumber() {
      if (!allValid.value || !mobileNumber.value) {
        feedbackMessage(t('invalidEntry'))
      } else {
        try {
          await appActions.smsLogin(mobileNumber.value)
        } catch (error) {
          console.log(error)
          return feedbackMessage(t('phoneNumberError'))
        }
        mode.value = 'smsCode'
        feedbackMessage(t('sms login accepted'))
      }
    }

    async function sendOTC() {
      if (!allValid.value || !otc.value) {
        feedbackMessage(t('invalidEntry'))
      } else {
        let smsLoginUrl = `${baseUrl}/auth/sms/code`
        if (window.cordova) {
          smsLoginUrl += `?&client=mobileApp&remember=true&otc=${otc.value}&mobil=${mobileNumber.value}`
          window.OAuth(smsLoginUrl, 'oauth:dataporten', undefined, (success) => {
            console.log(success)
          })
        } else {
          smsLoginUrl += `?&client=webApp&otc=${otc.value}&mobil=${mobileNumber.value}`
          window.location.href = smsLoginUrl
        }
      }
    }

    const calculateCountdown = () => {
      const dateNow = new Date()
      localUsersWithExpiry.forEach((lu: LocalUserWithExpiry) => {
        const countdown = jwtExpiry - moment(dateNow).diff(lu.lastLogin, 'seconds')
        lu.expiresIn.value = moment.duration(countdown, 'seconds').humanize()
        lu.valid.value = countdown > 0
      })
    }
    calculateCountdown()
    const countDownInterval = setInterval(() => {
      calculateCountdown()
    }, 10000)
    onBeforeUnmount(() => {
      clearInterval(countDownInterval)
    })

    const filteredLocalUsersWithExpiry = computed(() => localUsersWithExpiry.filter((u) => u.valid.value))

    return {
      t,
      status: appGetters.status,
      filteredLocalUsersWithExpiry,
      deviceReady: deviceGetters.deviceOnline,
      appVersion,
      momentFormat,
      showTestLogin,
      showLearningLogin,
      sendPhoneNumber,
      sendOTC,

      selectUser,
      pinCode,
      DPlogin,
      PINlogin,
      localUserLogin,
      mode,
      mobileNumber,
      otc,
      message,
      selectedUser,
      validators,
    }
  },
})
