<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="blob h-6 w-6"></div>
</template>

<style type="postcss" scoped>
.blob {
  border-radius: 50%;
  background-color: rgba(9, 137, 255, 0.75);
  box-shadow: 0 0 0 0 rgba(9, 137, 255, 0.75);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.7);
    box-shadow: 0 0 0 0 rgba(9, 137, 255, 0.75);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.7);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
