import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4037bbca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col justify-evenly w-full h-full sl-intro-background text-center text-white" }
const _hoisted_2 = { class: "font-bold text-white" }
const _hoisted_3 = { class: "flex flex-row justify-center" }
const _hoisted_4 = { class: "flex flex-col items-center" }
const _hoisted_5 = { class: "font-bold text-black mt-2" }
const _hoisted_6 = { class: "flex flex-row justify-center mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.description), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.players, (p) => {
        return (_openBlock(), _createElementBlock("div", {
          key: p._id,
          class: "rounded-lg bg-gray-300 w-48 h-64 m-2",
          style: _normalizeStyle({ backgroundColor: p.profile.avatar.backgroundColour || '#aaaaaa' })
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Avatar, {
              layout: p.profile.avatar,
              class: "w-48 h-48"
            }, null, 8 /* PROPS */, ["layout"]),
            _createElementVNode("p", _hoisted_5, _toDisplayString(p.profile.name), 1 /* TEXT */)
          ])
        ], 4 /* STYLE */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" Get started button goes here"),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}