
import { defineComponent, PropType, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useGameStore } from '@/store/useGameStore'
import { SevenseasItem } from '@/templates/slplus/structure/questionModels'
import { playSounds, stopSounds, soundIsPlaying } from '@/utilities'
import Avatar from '@/components/base/Avatar.vue'
import Button from '@/components/base/Button.vue'
import omarChat from '@/assets/omar/omar_chat1.svg'
import bgFishedImage from '@/assets/tasks/sevenseas/Bg-Element_fished.svg'
import bgTextImageBlue from '@/assets/tasks/sevenseas/Flat_discussiontime_blue.svg'
import bgTextImage from '@/assets/tasks/sevenseas/Flat_3.svg'
import bgAvatar1 from '@/assets/tasks/sevenseas/Chat_1.svg'
import bgAvatar2 from '@/assets/tasks/sevenseas/Chat_2.svg'
import bgAvatar3 from '@/assets/tasks/sevenseas/Chat_3.svg'
import bgAvatar4 from '@/assets/tasks/sevenseas/Chat_4.svg'

const messages = {
  no: {
    letsDiscuss: 'Diskusjonstid!',
  },
  en: {
    letsDiscuss: 'Discussion time!',
  },
}

export default defineComponent({
  name: 'DiscussionTimeSubtask',
  components: {
    Avatar,
    Button,
  },
  props: {
    item: {
      type: Object as PropType<SevenseasItem>,
      required: true,
    },
    word: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { t } = useI18n({ messages })
    const { item } = toRefs(props)
    const gameStore = useGameStore()
    const players = gameStore.getters.playersInSelectedGame

    const playSoundAgain = () => {
      stopSounds()
      const soundURL = item.value.discussionSound
      if (soundURL) {
        playSounds([soundURL])
      }
    }

    return {
      t,
      players,
      playSoundAgain,
      stopSounds,
      soundIsPlaying,

      // Assets
      omarChat,
      bgTextImageBlue,
      bgTextImage,
      bgFishedImage,
      bgAvatar1,
      bgAvatar2,
      bgAvatar3,
      bgAvatar4,
    }
  },
})
