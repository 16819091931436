/*
 Designed and developed by Richard Nesnass and Hoang Bao Ngo

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
// useCMSStore are the same across projects,
// however they provide the junction to each project's unique data structures in neighboring directories
import { ref, Ref, computed, ComputedRef } from 'vue'
import { PROJECT_NAME, LanguageCodes } from '@/constants'
import { cmsRequest } from '@/api/cmsService'
import { useAppStore, AppStoreType } from '@/store/useAppStore'
import { useProjectStore } from '@/store/useProjectStore'
import { Sett, Question, CmsGQLQuery, CmsGQLData, CmsQuestionData } from '@/types/main'
import { CmsPicturebook, Picturebook } from '../structure/picturebookModels'

// Query strings for each project
import navigationQuery from '../graphql/navigationQuery.gql'
import questionsQuery from '../graphql/questionsQuery.gql'
import singleQuestionQuery from '../graphql/singleQuestionQuery.gql'
import fragmentsQuery from '../graphql/questionFragments.gql'
import picturebookQuestionQuery from '../graphql/picturebookQuestionQuery.gql'

// Project-specific Types
import { TopLevel, TopLevelData, Day } from '../structure/generalModels'
import * as QuestionModels from '../structure/questionModels'
import { QUESTION_CLASS_NAMES, QuestionDataIntersection } from '../structure/helpers'

const appStore: AppStoreType = useAppStore()
const projectStore = useProjectStore()

// ------------  State (internal) --------------

interface State {
  project: {
    name: PROJECT_NAME
  }
  root: Sett[] // This is the root containing first level of Sett where we begin Layout navigation
  dict: { [id: string]: Sett } // A dictionary version of the same root data, indexed by 'id'
  selectedSet: {
    set: TopLevel | Day | Picturebook | Sett | undefined // Currently selected Sett.  In DSLPlus, this will be a Day or a Week
    index: number // Index of the selected Sett in its parent's sett[]
    level: number // level of this sett below root (root = level 0)
  }
  selectedQuestion: {
    question: Question | undefined // In DSLPlus this is a Question (Task1, Task2, etc..)
    index: number // Index of this Question in the parent Day's questions[]
  }
  questions: Question[] // Questions for the currently selected Sett
}

const state: Ref<State> = ref({
  project: {
    name: PROJECT_NAME.dslplus,
  },
  root: [],
  dict: {},
  selectedSet: {
    set: undefined,
    index: -1,
    level: 0,
  },
  questions: [],
  selectedQuestion: {
    question: undefined,
    index: -1,
  },
})
// ------------  Internal functions ------------

async function fetchSets(language: LanguageCodes): Promise<CmsGQLQuery> {
  const project = projectStore.getters.selectedProject.value
  const query = navigationQuery as string
  const variables = { __language: language }
  if (project) return cmsRequest(project, query, variables, language)
  else return Promise.resolve({})
}

async function fetchQuestionsForSet(sett: Sett, language: LanguageCodes): Promise<CmsGQLQuery> {
  const project = projectStore.getters.selectedProject.value
  if (sett && project) {
    const fragments = fragmentsQuery as string
    const questions = questionsQuery as string
    const query = fragments + questions
    const variables = { __setID: sett._id, __language: language }
    return cmsRequest(project, query, variables, language)
  } else return Promise.resolve({})
}

async function fetchQuestionByID(schema: string, id: string, language: LanguageCodes): Promise<CmsGQLQuery> {
  const project = projectStore.getters.selectedProject.value
  const s = String(schema).toPascalCase()
  const fragments = fragmentsQuery as string
  const question = singleQuestionQuery as string
  const query = fragments + question
  const variables = {
    __findSchemaContent: `find${s}Content`,
    __questionID: id,
    __language: language,
  }
  if (project) return cmsRequest(project, query, variables, language)
  else return Promise.resolve({})
}

async function fetchPicturebookByID(schema: string, id: string, language: LanguageCodes): Promise<CmsGQLQuery> {
  const project = projectStore.getters.selectedProject.value
  const query = picturebookQuestionQuery as string
  const variables = {
    __findSchemaContent: `findPicturebookContent`,
    __questionID: id,
    __language: language,
  }
  if (project) return cmsRequest(project, query, variables, language)
  else return Promise.resolve({})
}

function addQuestion(data: CmsGQLData | CmsPicturebook, language: LanguageCodes, parent?: Sett): Question | void {
  const className = <keyof typeof QUESTION_CLASS_NAMES>data.__typename
  if (data.__typename === 'Picturebook') {
    const newModel = new Picturebook(data as CmsPicturebook, [], language, parent)
    if (parent) parent.addQuestion(newModel)
    return newModel
  } else if (className) {
    const QModel = QuestionModels[className]
    const newModel = new QModel(data as QuestionDataIntersection, language, parent)
    if (parent) parent.addQuestion(newModel)
    return newModel
  } else {
    console.log(`Incorrect className undefined for data: ${data.__typename}`)
    return
  }
}

// ------------  Getters --------------
interface Getters {
  root: ComputedRef<Sett[]>
  questions: ComputedRef<Question[]>
  selectedSet: ComputedRef<State['selectedSet']>
  selectedQuestion: ComputedRef<State['selectedQuestion']>
}

const getters = {
  get root(): ComputedRef<Sett[]> {
    return computed(() => state.value.root)
  },
  get questions(): ComputedRef<Question[]> {
    if (state.value.selectedQuestion.question instanceof Picturebook)
      return computed(() => (state.value.selectedQuestion.question ? [state.value.selectedQuestion.question] : []))
    else
      return computed(() => {
        const set = state.value.selectedSet.set
        if (set && !(set instanceof Picturebook)) return set.questions
        else return []
      })
  },
  get selectedSet(): ComputedRef<State['selectedSet']> {
    return computed(() => state.value.selectedSet)
  },
  get selectedQuestion(): ComputedRef<State['selectedQuestion']> {
    return computed(() => state.value.selectedQuestion)
  },
}

// ------------  Actions --------------
interface Actions {
  getSets: (language: LanguageCodes) => Promise<void>
  setById: (id: string) => Sett
  getQuestions: (set: Sett, language: LanguageCodes) => Promise<void>
  getQuestionByID: (schema: string, id: string, language: LanguageCodes) => Promise<Question | void>
  selectSet: (set: TopLevel | Day | Picturebook | Sett | undefined, index: number, level: number) => void
  selectQuestion: (question: Question, index: number) => void
}
const actions = {
  // Retrieve from CMS the Set data for top levels of navigation
  getSets: async function (language: LanguageCodes): Promise<void> {
    const project = projectStore.getters.selectedProject.value
    console.log(`Calling store: ${project && project.projectName}...`)
    if (state.value.root.length === 0 || projectStore.getters.overrideCMS) {
      console.log('SL: Get CMS data...')
      if (!project || project.projectName === PROJECT_NAME.none) {
        console.log('Content: No project selected!')
        return Promise.resolve()
      }
      appStore.actions.setLoading(true)
      const response: CmsGQLQuery = await fetchSets(language)
      const newRoot: TopLevel[] = []
      if (response.data) {
        const topLevelDataList = response.data.results as TopLevelData[]
        topLevelDataList.forEach((data: TopLevelData, index: number) => {
          const newLevel = new TopLevel(data, index, language)
          newRoot.push(newLevel)
          state.value.dict[newLevel._id] = newLevel
          newLevel.sets.forEach((d) => (state.value.dict[d._id] = d))
        })
        // Sort root Sets by index key
        if (newRoot.length > 0) {
          newRoot.sort((a, b) => a.index - b.index)
        }
        state.value.root = newRoot
      } else {
        const error = 'Sett query contains no records'
        appStore.actions.setError(error)
        console.log(error)
      }
      appStore.actions.setLoading(false)
      return Promise.resolve()
    } else {
      return Promise.resolve()
    }
  },
  setById(id: string): Sett {
    return state.value.dict[id]
  },

  // Retrieve Question data for a given Sett
  // Relies on a Sett containing Quesitons being currently selected
  getQuestions: async function (sett: Sett, language: LanguageCodes): Promise<void> {
    appStore.actions.setLoading(true)
    // Don't continue if we already have the questions for given Sett
    // if (sett.questions && sett.questions.length > 0) return Promise.resolve()

    // Deliberate fallback is needed temporarily as fallback does not seem to work for Squidex components
    const l = language === LanguageCodes.nn ? LanguageCodes.no : language

    const response: CmsGQLQuery = await fetchQuestionsForSet(sett, l)
    if (!response.data) {
      const error = 'Question query contains no records'
      console.error(response.errors ? response.errors : error)
      appStore.actions.setError(error)
      return Promise.reject()
    }
    state.value.questions = []
    const questions: CmsQuestionData = response.data.results as CmsQuestionData
    const data: CmsGQLData[] = questions.data.questions.iv
    if (sett && data) {
      sett.clearQuestions()
      data.forEach((d: CmsGQLData) => {
        addQuestion(d, l, sett)
      })
    }
    appStore.actions.setLoading(false)
    return Promise.resolve()
  },

  // This is used to construct a 'sample' Question
  getQuestionByID: async function (schema: string, id: string, language: LanguageCodes): Promise<Question | void> {
    appStore.actions.setLoading(true)
    const response: CmsGQLQuery =
      schema === 'picturebook' ? await fetchPicturebookByID(schema, id, language) : await fetchQuestionByID(schema, id, language)
    state.value.questions = []
    if (response.data) {
      const data: CmsGQLData = response.data.results as CmsGQLData
      if (data) {
        const newQuestion = addQuestion(data, language)
        return Promise.resolve(newQuestion)
      }
    }
    appStore.actions.setLoading(false)
    return Promise.resolve()
  },
  // Used by Layout vue, which changing the displayed Sett grid
  selectSet: function (set: TopLevel | Day | Picturebook | Sett | undefined, index: number, level: number): void {
    state.value.selectedSet.set = set
    state.value.selectedSet.index = index
    state.value.selectedSet.level = level
  },
  // Called from the Question vue, when changing the displayed question
  selectQuestion: function (question: Question, index: number): void {
    state.value.selectedQuestion.question = question
    state.value.selectedQuestion.index = index
  },
}

// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useCMSStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type CMSStoreType = ReturnType<typeof useCMSStore>
// export const UserKey: InjectionKey<UseUser> = Symbol('UseUser')
