
import { defineComponent, ref, watch } from 'vue'

enum MODES {
  stop = 'stop',
  record = 'record',
  play = 'play',
  delete = 'delete',
}

export default defineComponent({
  props: {
    recordingExists: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['vclick'],
  setup(props, context) {
    const buttonMode = ref(MODES.stop)
    const buttonStyle = ref({
      'border-radius': '50%',
      'background-color': '#ff0000',
      width: '5rem',
      height: '5rem',
    })

    watch(
      () => props.recordingExists,
      () => {
        buttonMode.value = MODES.stop
        switchMode(false)
      },
    )

    watch(
      () => props.isPlaying,
      () => {
        buttonMode.value = props.isPlaying ? MODES.play : MODES.stop
        switchMode(false)
      },
    )

    function switchMode(clicked: boolean) {
      switch (buttonMode.value) {
        case MODES.record:
          buttonMode.value = MODES.stop
          buttonStyle.value = {
            'border-radius': '10%',
            'background-color': '#ff0000',
            width: '3rem',
            height: '3rem',
          }
          if (clicked) context.emit('vclick', MODES.record)
          break
        case MODES.stop:
          if (props.recordingExists) {
            buttonMode.value = MODES.play
            buttonStyle.value = {
              'border-radius': '50%',
              'background-color': props.transparent ? 'rgba(255, 255, 255, .5)' : '#0099ff',
              width: '6rem',
              height: '6rem',
            }
          } else {
            buttonMode.value = MODES.record
            buttonStyle.value = {
              'border-radius': '50%',
              'background-color': '#ff0000',
              width: '5rem',
              height: '5rem',
            }
          }
          if (clicked) context.emit('vclick', MODES.stop)
          break
        case MODES.play:
          buttonMode.value = MODES.stop
          buttonStyle.value = {
            'border-radius': '10%',
            'background-color': '#ff0000',
            width: '3rem',
            height: '3rem',
          }
          if (clicked) context.emit('vclick', MODES.play)
          break
        default:
          buttonMode.value = MODES.record
      }
    }
    switchMode(false)

    return {
      MODES,
      switchMode,
      buttonMode,
      buttonStyle,
    }
  },
})
