/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import giantRobot from './effects/giantRobot'
import speed from './effects/speed'
import pitch from './effects/pitch'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// type EffectFunction = (audioBuffer: AudioBuffer, opts?: any) => Promise<AudioBuffer>

export enum Effect {
  giantRobot = 'giantRobot',
  speed = 'speed',
  pitch = 'pitch',
}

let globalAudioBuffer: AudioBuffer
// eslint-disable-next-line @typescript-eslint/ban-types

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export async function transform(effect: Effect, value?: number): Promise<void> {
  if (!globalAudioBuffer) {
    alert('No input buffer')
    return Promise.reject()
  }

  let outputAudioBuffer = globalAudioBuffer
  switch (effect) {
    case Effect.giantRobot:
      outputAudioBuffer = await giantRobot(outputAudioBuffer)
      break
    case Effect.speed:
      outputAudioBuffer = await speed(outputAudioBuffer, { speed: value || 1 })
      break
    case Effect.pitch:
      outputAudioBuffer = await pitch(outputAudioBuffer, { pitchMod: value || 0 })
      break
  }
  globalAudioBuffer = outputAudioBuffer
}

export async function load(arrayBuffer: ArrayBuffer): Promise<void> {
  const audio = new AudioContext()
  try {
    globalAudioBuffer = await audio.decodeAudioData(arrayBuffer)
  } catch (error) {
    console.log(error)
  }
}

export function play(callback: () => void): void {
  const audioCtx = new window.AudioContext()
  const source = audioCtx.createBufferSource()
  source.buffer = globalAudioBuffer
  source.connect(audioCtx.destination)
  source.onended = () => {
    if (callback) callback()
  }
  source.start()
}
