/*
 Designed and developed by Richard Nesnass and Hoang Bao Ngo

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { LanguageCodes } from '@/constants'
import { Sett, Question, QuestionData, QUESTION_TYPES } from '@/types/main'

// -------------- Picturebook and Overlays ---------

export enum PICTUREBOOK_CLASS_NAMES {
  'Picturebook' = 'Picturebook',
}

type LC<T> = {
  [key in LanguageCodes]: T
}

// This enumerator aligns names with the Overlay's Squidex '__typename'
export enum OVERLAY_TYPE {
  // Old JSON-based overlay types
  display_delay = 'display_delay',
  appearing_images = 'appearing_images',
  focus_images = 'focus_images',
  zoomable_images = 'zoomable_images',
  playable_audio = 'playable_audio',

  // New CMS overlay structure:
  OverlayAppear = 'OverlayAppear', // This value must match the '__typename' supplied by GraphQL query
}
interface CmsOverlay {
  id: string
  __typename: string
  data: {
    x: LC<number>
    y: LC<number>
    w: LC<number>
    h: LC<number>
    type: LC<string>
    autoStart: LC<boolean>
    autoVisible: LC<boolean>
    autoHide: LC<boolean>
    triggerDelay: LC<number>
    loop: LC<boolean>
    file: LC<{ url: string; slug: string }[]> | null
    sound: LC<{ url: string; slug: string }[]> | null
    triggerX: LC<number>
    triggerY: LC<number>
    triggerWidth: LC<number>
    triggerHeight: LC<number>
    transitToX: LC<number>
    transitToY: LC<number>
    transitToScale: LC<number>
    transitDuration: LC<number>
  }
}
export class Overlay {
  id: string
  __typename: string
  coords: {
    // Percentage coordniates
    x: number
    y: number
    w: number
    h: number
  }
  trigger: {
    // Percentage coordniates
    x: number
    y: number
    w: number
    h: number
  }
  transition: {
    // Transition TO these values
    // Percentages
    x: number
    y: number
    // Decimal (1 = natural size)
    scale: number
    // Seconds
    duration: number
  }
  file: string
  sound: string
  type: string
  autoVisible: boolean // Show the overlay automatically (image, animation)
  autoStart: boolean // Start the overlay automatically (zoom, animation or sound) after triggerDelay expires
  autoHide: boolean // Hide the overlay after it has finished (animations)
  triggerDelay: number // Seconds before overlay becomes interactive
  loop: boolean

  constructor(spec: CmsOverlay, language: LanguageCodes) {
    this.id = spec.id
    this.__typename = (spec.__typename as OVERLAY_TYPE).toLowerCase()
    this.coords = {
      x: spec.data.x?.iv || 0,
      y: spec.data.y?.iv || 0,
      w: spec.data.w?.iv || 0,
      h: spec.data.h?.iv || 0,
    }
    this.trigger = {
      x: spec.data.triggerX?.iv ? spec.data.triggerX.iv : this.coords.x,
      y: spec.data.triggerY.iv ? spec.data.triggerY.iv : this.coords.y,
      w: spec.data.triggerWidth?.iv ? spec.data.triggerWidth.iv : this.coords.w,
      h: spec.data.triggerHeight?.iv ? spec.data.triggerHeight.iv : this.coords.h,
    }
    this.transition = {
      x: spec.data.transitToX?.iv ? spec.data.transitToX.iv : this.coords.x,
      y: spec.data.transitToY?.iv ? spec.data.transitToY.iv : this.coords.y,
      scale: spec.data.transitToScale?.iv ? spec.data.transitToScale.iv : 1,
      duration: spec.data.transitDuration?.iv ? spec.data.transitDuration.iv : 0,
    }
    this.triggerDelay = spec.data.triggerDelay?.iv || 0
    this.autoVisible = !!spec.data.autoVisible?.iv
    this.autoStart = !!spec.data.autoStart?.iv
    this.autoHide = !!spec.data.autoHide?.iv
    this.loop = !!spec.data.loop?.iv
    this.file = (spec.data.file?.iv?.[0]?.url || '') + (spec.data.file?.iv?.[0]?.slug || '')
    this.sound = (spec.data.sound?.[language]?.[0]?.url || '') + (spec.data.sound?.[language]?.[0]?.slug || '')
    if (this.sound === 'undefined') this.sound = ''
    this.type = spec.data.type?.iv || ''
  }
}

export interface Overlays {
  // New overlay structure:
  [OVERLAY_TYPE.OverlayAppear]: Overlay
}

interface CmsPage {
  background: { url: string; slug: string }[] | null
  foreground?: { url: string; slug: string }[] | null
  overlays: CmsOverlay[]
}

export class Page {
  backgrounds: Array<{ url: string; slug: string }>
  foregrounds: Array<{ url: string; slug: string }>
  mainBackground: string
  mainForeground: string
  overlays: Overlay[]
  text: string

  constructor(spec: CmsPage, pageText: string, language: LanguageCodes) {
    this.backgrounds = []
    this.foregrounds = []
    this.mainBackground = ''
    this.mainForeground = ''
    this.text = pageText
    if (spec.background) {
      spec.background.forEach((b) => this.backgrounds.push(b))
    }
    if (this.backgrounds.length > 0) this.mainBackground = this.backgrounds[0].url + this.backgrounds[0].slug
    if (spec.foreground) {
      spec.foreground.forEach((b) => this.foregrounds.push(b))
      if (this.foregrounds.length > 0) this.mainForeground = this.foregrounds[0].url + this.backgrounds[0].slug
    }
    this.overlays = []

    if (spec.overlays) {
      spec.overlays.forEach((item: CmsOverlay) => {
        this.overlays.push(new Overlay(item, language))
      })
    }
  }
}

export interface CmsPicturebook {
  id: string
  __typename: string
  data: {
    recordAudio: LC<boolean>
    title: LC<string>
    name: LC<string>
    thumbnail: LC<{ url: string; slug: string }[]> | null
    pages: LC<CmsPage[]>
  }
}

export interface PictureBookText {
  pageNumber: number
  text: string
}

export class Picturebook extends Question {
  pages: Array<Page>
  shown: boolean

  constructor(spec: CmsPicturebook, picturebookText: PictureBookText[], language: LanguageCodes, parent?: Sett) {
    const data: QuestionData = {
      id: spec.id,
      __typename: PICTUREBOOK_CLASS_NAMES.Picturebook,
      name: spec.data.name?.[language] || spec.data.title.iv || '',
      thumbnail: spec.data.thumbnail && spec.data.thumbnail.iv?.length ? spec.data.thumbnail.iv[0].url + spec.data.thumbnail.iv[0].slug : '',
      recordAudio: spec.data.recordAudio.iv,
      type: QUESTION_TYPES.picturebook,
    }
    super(data, parent)
    this.pages = []
    const pageText: Record<number, string> = {}
    picturebookText.forEach((page) => (pageText[page.pageNumber] = page.text))
    if (spec.data.pages.iv) {
      spec.data.pages.iv.forEach((p, index) => this.pages.push(new Page(p, pageText[index + 1], language)))
    }

    // Local
    this.shown = false
  }
}
