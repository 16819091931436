
import { defineComponent, PropType } from 'vue'
import { AvatarLayout } from '../../types/main'

export default defineComponent({
  name: 'PlayerAvatar',
  props: {
    layout: {
      type: Object as PropType<AvatarLayout>,
      required: true,
    },
  },
})
