import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/svg/trash.svg'


const _withScopeId = n => (_pushScopeId("data-v-5b9a028d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full relative overflow-hidden" }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col h-full"
}
const _hoisted_3 = { class: "text-2xl -mt-8 text-white" }
const _hoisted_4 = { class: "text-white" }
const _hoisted_5 = { class: "flex flex-row justify-between py-10" }
const _hoisted_6 = { class: "flex flex-row items-center pl-4" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex flex-row items-center" }
const _hoisted_10 = { class: "flex flex-row items-center m-4" }
const _hoisted_11 = {
  key: 2,
  class: "flex flex-col justify-around items-center h-full sl-intro-background"
}
const _hoisted_12 = { class: "text-2xl text-sl-green-button" }
const _hoisted_13 = { class: "flex flex-row w-full justify-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      mode: "out-in",
      name: "fade"
    }, {
      default: _withCtx(() => [
        ($setup.mode === $setup.MODES.intro)
          ? (_openBlock(), _createBlock($setup["Intro"], {
              key: 0,
              title: $setup.t('recordingIntroTitle'),
              description: $setup.t('recordingIntro'),
              image: $setup.yourTurnImage
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Button"], {
                  class: "my-8",
                  backgroundcolour: 'bg-sl-green-button',
                  textcolour: 'text-white',
                  childclass: 'text-2xl border-none h-16 w-56',
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.changeMode($setup.MODES.playerRecord)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.t('next')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["title", "description", "image"]))
          : ($setup.mode === $setup.MODES.playerRecord && $setup.currentPlayer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "flex flex-grow flex-col place-items-center place-content-center justify-center bg-right-bottom bg-cover",
                  style: _normalizeStyle($setup.computedPlayerBackground)
                }, [
                  _createVNode($setup["Avatar"], {
                    layout: $setup.currentPlayer.profile.avatar,
                    class: "h-128 w-128"
                  }, null, 8 /* PROPS */, ["layout"]),
                  _createElementVNode("p", _hoisted_3, _toDisplayString($setup.currentPlayer.profile.name + ', ' + $setup.t('itsYourTurn1')), 1 /* TEXT */),
                  _createElementVNode("p", _hoisted_4, _toDisplayString($setup.t('itsYourTurn2')), 1 /* TEXT */)
                ], 4 /* STYLE */),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", {
                      class: "rounded-full border-2 border-solid border-gray-500 h-20 w-20 flex flex-row justify-center items-center",
                      style: {"background-color":"#83dbb7"},
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.playbackMode = $setup.MODES.parrot))
                    }, [
                      _createElementVNode("img", {
                        src: $setup.parrotIcon,
                        class: "h-14 w-14 block"
                      }, null, 8 /* PROPS */, _hoisted_7)
                    ]),
                    _createElementVNode("div", {
                      class: "ml-4 rounded-full border-2 border-solid border-gray-500 h-20 w-20 flex flex-row justify-center items-center",
                      style: {"background-color":"#1953b8"},
                      onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.playbackMode = $setup.MODES.robot))
                    }, [
                      _createElementVNode("img", {
                        src: $setup.robotIcon,
                        class: "h-14 w-14 block"
                      }, null, 8 /* PROPS */, _hoisted_8)
                    ]),
                    _createElementVNode("div", {
                      class: "ml-4 rounded-full border-2 border-solid border-gray-500 h-20 w-20 flex flex-row justify-center items-center",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.playbackMode = $setup.MODES.normal))
                    }, [
                      _createVNode($setup["Avatar"], {
                        layout: $setup.currentPlayer.profile.avatar,
                        class: "h-28 w-28 -m-7"
                      }, null, 8 /* PROPS */, ["layout"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    ($setup.recordingExists)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "w-8 h-8",
                          src: _imports_0,
                          alt: "delete",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.deleteRecordedAudio()))
                        }))
                      : _createCommentVNode("v-if", true),
                    _createVNode($setup["RecordButton"], {
                      class: "mt-1 mx-6",
                      "recording-exists": $setup.recordingExists,
                      "is-playing": $setup.isPlaying,
                      onVclick: $setup.recordButtonClicked
                    }, null, 8 /* PROPS */, ["recording-exists", "is-playing"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    ($setup.recordingExists || !$setup.isMobile)
                      ? (_openBlock(), _createBlock($setup["Button"], {
                          key: 0,
                          backgroundcolour: 'bg-sl-green-button',
                          textcolour: 'text-white',
                          childclass: 'text-2xl border-none h-16 w-56',
                          onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.changeMode($setup.MODES.endRecording)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('next')), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true)
                  ])
                ])
              ]))
            : ($setup.mode === $setup.MODES.allDone)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("p", _hoisted_12, _toDisplayString($setup.t('goodJob')), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.finishedPlayers, (p) => {
                      return _createElementVNode("div", {
                        key: p.player._id,
                        class: "rounded-full border-2 border-solid border-gray-500 h-40 w-40 flex flex-row justify-center items-center bg-right-bottom bg-cover",
                        style: _normalizeStyle($setup.playerBackground(p.mode, p.player))
                      }, [
                        _createVNode($setup["Avatar"], {
                          layout: p.player.profile.avatar,
                          class: "h-36 w-36 -m-7"
                        }, null, 8 /* PROPS */, ["layout"])
                      ], 4 /* STYLE */)
                    }), 64 /* STABLE_FRAGMENT */))
                  ]),
                  _createVNode($setup["Button"], {
                    backgroundcolour: 'bg-sl-green-button',
                    textcolour: 'text-white',
                    childclass: 'text-2xl border-none h-16 w-56',
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('done')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('next')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}