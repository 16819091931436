
import { defineComponent, PropType } from 'vue'
import { Player } from '@/types/main'
import Avatar from '@/components/base/Avatar.vue'

export default defineComponent({
  name: 'PlayersReadySubtask',
  components: {
    Avatar,
  },
  props: {
    players: {
      type: Object as PropType<Player[]>,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
})
