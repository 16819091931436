/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { Sett, Question, SetData, DISPLAY_MODE, CmsGQLData } from '@/types/main'
import { CmsPicturebook, Picturebook, PictureBookText } from './picturebookModels'
import { assetRoot, LanguageCodes } from '@/constants'

type LC<T> = {
  [key in LanguageCodes]: T
}

export interface DayData extends CmsGQLData {
  data: {
    name: LC<string>
    subtitle: LC<string>
    recordAudio: LC<boolean>
    displayMode: LC<DISPLAY_MODE>
    picturebook?: LC<CmsPicturebook[]>
    picturebookNarrative?: LC<PictureBookText[]>
    questions: LC<[{ id: string; __typename: string }]>
  }
}
// This should match the second Navigation level in Squidex
export class Day extends Sett {
  word: string
  subtitle: string
  recordAudio: boolean // Whether or not to record audio on this day
  questions: Array<Question>
  parent: TopLevel | undefined
  picturebook?: Picturebook
  picturebookText?: PictureBookText[]

  constructor(spec: DayData, parent: TopLevel, i: number, language: LanguageCodes) {
    const d = spec.data
    const index: Partial<LC<number>> = { iv: i }
    const word: Partial<LC<string>> = { iv: parent.word }
    const description: Partial<LC<string>> = { iv: (d.subtitle && d.subtitle.iv) || '' }
    const subtitle: Partial<LC<string>> = { iv: (d.subtitle && d.subtitle.iv) || '' }
    const data = {
      id: spec.id,
      __typename: spec.__typename,
      data: {
        index,
        name: d.name,
        word,
        description,
        subtitle,
        wordImage: `${assetRoot}/day${i + 1}.png`, // FIXME: This path requires the project name to work
        consolidation: { iv: false },
        displayMode: d.displayMode,
      },
    }
    super(data as SetData, parent)
    this.word = parent.word
    this.subtitle = (spec.data.subtitle && spec.data.subtitle.iv) || ''
    this.recordAudio = spec.data.recordAudio && !!spec.data.recordAudio.iv
    this.parent = parent
    if (spec.data.picturebook && spec.data.picturebook.iv && spec.data.picturebook.iv.length > 0) {
      const pbt: PictureBookText[] = spec.data.picturebookNarrative?.[language] || []
      this.picturebook = new Picturebook(spec.data.picturebook.iv[0], pbt, language, this)
    }
    // At this point we will request questions for a Sett in a separate GraphQL call
    // cmsStore checks this list's length to see if it should request data
    this.questions =
      (spec.data.questions?.iv && spec.data.questions.iv.map((q) => new Question({ id: q.id, __typename: q.__typename, name: '' }))) || []

    /* this.questions = spec.flatData.questions.map(
      (q, i) =>
        new Question(
          {
            id: q.id,
            __typename: q.__typename,
            name: i + 1 + '.',
            thumbnail: `${assetRoot}/slplus/tasksymbol-${i}.png`,
            recordAudio: false,
          },
          this
        )
    ) */
  }
}
// This should match the first Navigation level in Squidex
export interface TopLevelData extends SetData {
  data: {
    index: LC<number>
    name: LC<string>
    title: LC<string>
    word: LC<string>
    description: LC<string>
    subtitle: LC<string>
    displayMode: LC<DISPLAY_MODE>
    consolidation: LC<boolean>
    wordImage: LC<[{ url: string }] | null>
    sets: LC<DayData[]>
  }
}
export class TopLevel extends Sett {
  word: string
  sets: Array<Day>
  wordImage: string // URL

  constructor(spec: TopLevelData, index: number, language: LanguageCodes) {
    const wordImage = spec.data.wordImage && spec.data.wordImage.iv ? spec.data.wordImage.iv[0].url : ''
    if (isNaN(spec.data.index.iv)) spec.data.index.iv = index
    const data = {
      id: spec.id,
      __typename: spec.__typename,
      data: { ...spec.data },
    }
    super(data as SetData, undefined, language)
    this.wordImage = wordImage
    this.word = spec.data.word.iv || ''
    this.sets = []
    if (spec && spec.data.sets && spec.data.sets.iv) {
      spec.data.sets.iv.forEach((s, i) => this.sets.push(new Day(s, this, i, language)))
    }
  }
}
