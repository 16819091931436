import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, vModelText as _vModelText, vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeStyle as _normalizeStyle, renderList as _renderList, vModelCheckbox as _vModelCheckbox, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-154cb544"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "text-xs max-w-sm"
}
const _hoisted_4 = { class: "flex flex-row items-center" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "flex flex-row items-center" }
const _hoisted_8 = ["id"]
const _hoisted_9 = ["for"]
const _hoisted_10 = ["id"]
const _hoisted_11 = ["id", "placeholder", "type", "onKeyup"]
const _hoisted_12 = ["id"]
const _hoisted_13 = ["id", "value"]
const _hoisted_14 = ["for"]
const _hoisted_15 = ["id"]
const _hoisted_16 = ["id", "value"]
const _hoisted_17 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2", { 'ml-5': $props.border }]),
          for: $setup.elementId
        }, _toDisplayString($props.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    ($props.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.description), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.mode == 'binaryChoice')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              id: `${$setup.elementId}-yes`,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedValue) = $event)),
              class: "mr-1 mb-1",
              type: "radio",
              value: true,
              onChange: $setup.valueInput
            }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_5), [
              [_vModelRadio, $setup.selectedValue]
            ]),
            _createElementVNode("label", {
              class: "mr-2",
              for: `${$setup.elementId}-yes`
            }, "ja", 8 /* PROPS */, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              id: `${$setup.elementId}-no`,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedValue) = $event)),
              class: "mr-1 mb-1",
              type: "radio",
              value: false,
              onChange: $setup.valueInput
            }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_8), [
              [_vModelRadio, $setup.selectedValue]
            ]),
            _createElementVNode("label", {
              class: "mr-1",
              for: `${$setup.elementId}-no`
            }, "nei", 8 /* PROPS */, _hoisted_9)
          ])
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    ($setup.mode == 'url')
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 3,
          id: $setup.elementId,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.selectedValue) = $event)),
          type: "url",
          placeholder: "https://eksempel.com",
          pattern: "https://.*",
          size: "30",
          required: "",
          onInput: $setup.valueInput
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_10)), [
          [_vModelText, $setup.selectedValue]
        ])
      : _createCommentVNode("v-if", true),
    ($setup.mode == 'text' || $setup.mode === 'password' || $setup.mode === 'tel' || $setup.mode === 'email' || $setup.mode === 'number')
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["w-full bg-white flex items-center", {
          'rounded-full shadow-inner p-4': $props.border,
          'border-gray-300 border': !$props.border,
          'invalid-entry': !$setup.isValid && $setup.selectedValue,
        }])
        }, [
          _withDirectives(_createElementVNode("input", {
            id: $setup.elementId,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.selectedValue) = $event)),
            class: _normalizeClass(["w-full px-1 border-gray-300 focus:ring-2 focus:ring-blue-600 bg-transparent", { 'border-b': $props.underline }]),
            style: _normalizeStyle({
            width: `${$props.customSize ? $props.customSize + 'rem' : '100%'}`,
            height: `${parseInt($props.customSize) / 6}rem`,
            'font-size': `${parseInt($props.customSize) / 10}rem`,
          }),
            placeholder: $props.placeholder,
            type: $setup.mode,
            onInput: $setup.valueInput,
            onKeyup: _withKeys($setup.enterKey, ["enter"])
          }, null, 46 /* CLASS, STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_11), [
            [_vModelDynamic, $setup.selectedValue]
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($setup.mode == 'multiChoice')
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          id: $setup.elementId,
          class: "flex flex-col"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (o) => {
            return (_openBlock(), _createElementBlock("div", {
              key: o.id,
              class: "py-1"
            }, [
              _withDirectives(_createElementVNode("input", {
                id: `${$setup.elementId}-option-${o.title.replace(/\s/g, '')}`,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.selectedMultiChoice) = $event)),
                class: "mr-1 mb-1",
                type: "checkbox",
                value: o.id,
                onChange: $setup.valueInput
              }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_13), [
                [_vModelCheckbox, $setup.selectedMultiChoice]
              ]),
              _createElementVNode("label", {
                class: "mr-2",
                for: `${$setup.elementId}-option-${o.title.replace(/\s/g, '')}`
              }, _toDisplayString(o.title), 9 /* TEXT, PROPS */, _hoisted_14)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 8 /* PROPS */, _hoisted_12))
      : _createCommentVNode("v-if", true),
    ($setup.mode == 'singleChoice')
      ? (_openBlock(), _createElementBlock("div", {
          key: 6,
          id: $setup.elementId,
          class: "flex flex-col"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (o) => {
            return (_openBlock(), _createElementBlock("div", {
              key: o.id
            }, [
              _withDirectives(_createElementVNode("input", {
                id: `${$setup.elementId}-option-${o.title.replace(/\s/g, '')}`,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.selectedValue) = $event)),
                class: "mr-1 mb-1",
                type: "radio",
                value: o.title,
                onChange: $setup.valueInput
              }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_16), [
                [_vModelRadio, $setup.selectedValue]
              ]),
              _createElementVNode("label", {
                class: "mr-2",
                for: `${$setup.elementId}-option-${o.title.replace(/\s/g, '')}`
              }, _toDisplayString(o.title), 9 /* TEXT, PROPS */, _hoisted_17)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 8 /* PROPS */, _hoisted_15))
      : _createCommentVNode("v-if", true)
  ]))
}