
import { defineComponent, PropType, ref, Ref, computed, onMounted, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { shuffleItems, playSounds, stopSounds, soundIsPlaying } from '@/utilities'
import { useCMSStore, CMSStoreType } from '@/templates/slplus/store/useCMSStore'
import { useGameStore } from '@/store/useGameStore'
import { Day } from '@/templates/slplus/structure/generalModels'
import { Wordchaos, WordChaosWordlist, PlayerWordlist } from '@/templates/slplus/structure/questionModels'
import { assetRoot } from '@/constants'
import Button from '@/components/base/Button.vue'
import Avatar from '@/components/base/Avatar.vue'

import Intro from '@/components/task/Intro.vue'
import PlayersReady from '@/components/task/PlayersReady.vue'
import PlayerReady from '@/components/task/wordchaos/PlayerReady.vue'
import ChaosQuizz from '@/components/task/wordchaos/ChaosQuizz.vue'

import chaosImage from '@/assets/tasks/wordchaos/wordchaos.svg'
import omarHappyImage from '@/assets/omar/worm-happy.svg'

import trudeluttSound from '@/assets/tasks/stovnerfaret_26_10.mp3'
import chaosIntroSound from '@/assets/tasks/wordchaos/ordkaos_intro.mp3'
import chaosDittOrdSound from '@/assets/tasks/wordchaos/Ordkaos_dittorder_alex.mp3'
import chaosInstruksjonSound from '@/assets/tasks/wordchaos/Ordkaos_instruksjon.mp3'
import chaosAvslutningSound from '@/assets/tasks/wordchaos/Ordkaos_avslutning.mp3'

const messages = {
  no: {
    title: 'Ordkaos',
    description: 'Hør ordet - bytt plass',
    getStarted: 'Neste',
    exercise: 'Aktivitet ',
    yourTurn: 'oppdage ordet ditt!',
    weReady: 'Vi er klar',
    letsDiscuss: 'La oss alle snakke om det!',
    quizzTitle: 'I dette spillet skal dere bytte plasser!',
    finishedTitle: 'Jippi!',
  },
  en: {
    title: 'Word Chaos',
    description: 'Listen for the word - change places',
    getStarted: 'start',
    exercise: 'Exercise ',
    yourTurn: 'discover your word!',
    weReady: 'We are ready',
    letsDiscuss: "Let's all talk about it!",
    quizzTitle: 'In this game you will change places!',
    finishedTitle: 'Jippi!',
  },
}

enum MODES {
  intro = 'intro',
  playersReady = 'playersReady',
  playerReady = 'playerReady',
  quizzIntro = 'quizzIntro',
  quizz = 'quizz',
  finished = 'finished',
  changePlayer = 'changePlayer',
}

export default defineComponent({
  name: 'WordChaosTask', // Type name should be capitalised! Squidex does not allow uppercase for "schemaName" but capitalises first letter in GraphQL response..
  components: {
    Button,
    Avatar,
    Intro,
    PlayersReady,
    PlayerReady,
    ChaosQuizz,
  },
  props: {
    question: { required: true, type: Object as PropType<Wordchaos> },
    myIndex: { required: false, type: Number, default: 0 },
  },
  emits: ['completed', 'start-introduction', 'not-completed'],
  setup(props, context) {
    const { t } = useI18n({ messages })
    const { question } = toRefs(props)
    const mode: Ref<MODES> = ref(MODES.intro)
    const cmsStore: CMSStoreType = useCMSStore()
    const gameStore = useGameStore()
    const selectedSet: Day = cmsStore.getters.selectedSet.value.set as Day
    const word = selectedSet ? selectedSet.word : ''
    const players = gameStore.getters.playersInSelectedGame
    const currentPlayer: Ref<PlayerWordlist | undefined> = ref()
    let currentPlayerCounter = 0
    const playersAndWords: Ref<PlayerWordlist[]> = ref([])

    const exerciseIndex = computed(() => `${t('exercise')} ${props.myIndex + 1}`)

    // Play the introduction audio automatically
    context.emit('start-introduction', true)

    // Configure the output object at any time. It will be included when calling 'completed'

    // Call this when the task is complete
    const finish = () => {
      stopSounds()
      // Call this when the task is complete
      if (mode.value === MODES.finished) {
        context.emit('completed')
      }
      // Call when finishing early
      else context.emit('not-completed')
    }

    // ------ Functions to be called from parent -------

    // Moves task forward, if this task has multiple pages
    const forwardInternal = () => {
      console.log('Move forward internally')
    }

    // Called when introduction begins
    const onIntroductionStart = () => {
      console.log('Introduction started')
    }
    // Called when introduction ends
    const onIntroductionEnd = () => {
      console.log('Introduction ended')
    }

    // -------------    Word Chaos -----------------

    // Shuffle word list and players
    // Move important words to the front of the list for selection
    // Assign a words to Player
    function arrangeWords() {
      currentPlayerCounter = -1
      playersAndWords.value = []
      const arrangedWords: WordChaosWordlist[] = []
      const shuffledPlayers = shuffleItems(players.value)
      const shuffledWordlist = shuffleItems(question.value.wordlist)
      shuffledWordlist.forEach((word) => {
        if (word.important) arrangedWords.unshift(word)
        else arrangedWords.push(word)
      })
      shuffledPlayers.forEach((player) => {
        const wordlist = arrangedWords.shift()
        if (wordlist) {
          playersAndWords.value.push({
            player,
            wordlist,
            playCount: 0,
            playedWith: [],
          })
        } else console.log(`Word chaos ${question.value.name} - not enough words for players!`)
      })
    }

    onMounted(() => arrangeWords())

    const changeMode = (newMode: MODES) => {
      stopSounds()
      if (newMode === MODES.changePlayer) {
        setTimeout(() => changeMode(MODES.playerReady), 1000)
      } else if (newMode === MODES.playerReady) {
        currentPlayerCounter++
        if (playersAndWords.value.length - 1 >= currentPlayerCounter) {
          const playerWords = playersAndWords.value[currentPlayerCounter]
          currentPlayer.value = playerWords
          playSounds([chaosDittOrdSound, playerWords.wordlist.sound1])
        } else {
          newMode = MODES.quizzIntro
          playInstruction()
        }
      } else if (newMode === MODES.finished) {
        playSounds([chaosAvslutningSound])
      }
      mode.value = newMode
    }

    const playWordAgain = () => {
      const playerWords = playersAndWords.value[currentPlayerCounter]
      currentPlayer.value = playerWords
      playSounds([playerWords.wordlist.sound1])
    }

    const playAgain = () => {
      changeMode(MODES.changePlayer)
      setTimeout(() => {
        arrangeWords()
      }, 500)
    }

    const playInstruction = () => {
      playSounds([chaosInstruksjonSound])
    }

    const playIntro = () => {
      playSounds([trudeluttSound, chaosIntroSound])
    }
    playIntro()

    return {
      t,
      MODES,
      mode,
      word,
      assetRoot,
      exerciseIndex,
      players: gameStore.getters.playersInSelectedGame,

      // Methods called from parent
      forwardInternal,
      onIntroductionStart,
      onIntroductionEnd,

      // Task methods & data
      finish,
      currentPlayer,
      changeMode,
      playersAndWords,
      playAgain,
      playWordAgain,
      playInstruction,
      playIntro,
      stopSounds,
      soundIsPlaying,

      // Assets
      chaosImage,
      omarHappyImage,
    }
  },
})
