
import { defineComponent } from 'vue'
import { useAppStore } from '../../store/useAppStore'
import { useProjectStore } from '../../store/useProjectStore'
import MonitorProjectItem from '@/components/admin/MonitorProjectItem.vue'
import SlButton from '@/components/base/SlButton.vue'

export default defineComponent({
  name: 'MonitorProjects',
  components: {
    MonitorProjectItem,
    SlButton,
  },
  setup() {
    const { getters: appGetters } = useAppStore()
    const { getters: projectGetters, actions: projectActions } = useProjectStore()

    const addProject = () => {
      projectActions.createProject()
    }

    projectActions.getProjects()

    return {
      // Computed
      status: appGetters.status,
      projects: projectGetters.projects,

      // Methods
      addProject,
    }
  },
})
