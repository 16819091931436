import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-evenly w-full h-full sl-intro-background text-center text-white" }
const _hoisted_2 = { class: "flex flex-row justify-center" }
const _hoisted_3 = { class: "text-5xl font-bold text-black" }
const _hoisted_4 = { class: "flex flex-row justify-center mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Avatar, {
          layout: _ctx.player.profile.avatar,
          class: "w-96 h-96"
        }, null, 8 /* PROPS */, ["layout"])
      ]),
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.player.profile.name) + ", ", 1 /* TEXT */),
        _createTextVNode(_toDisplayString(_ctx.description), 1 /* TEXT */)
      ])
    ]),
    _createCommentVNode(" Get started button goes here"),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}