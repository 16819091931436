import Jungle from '../lib/jungle'

export default async function giantRobotTransform(audioBuffer: AudioBuffer): Promise<AudioBuffer> {
  const ctx = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate)

  const source = ctx.createBufferSource()
  source.buffer = audioBuffer

  const dee = new Jungle(ctx)
  dee.setPitchOffset(-0.1)

  const deep = new Jungle(ctx)
  deep.setPitchOffset(-0.2)

  const deeper = new Jungle(ctx)
  deeper.setPitchOffset(-0.4)

  const deeperer = new Jungle(ctx)
  deeperer.setPitchOffset(-0.8)

  const compressor = ctx.createDynamicsCompressor()

  source.connect(dee.input)
  source.connect(deep.input)
  source.connect(deeper.input)
  source.connect(deeperer.input)

  dee.output.connect(compressor)
  deep.output.connect(compressor)
  deeper.output.connect(compressor)
  deeperer.output.connect(compressor)

  compressor.connect(ctx.destination)

  source.start(0)
  return await ctx.startRendering()
}
