
import { defineComponent, PropType, ref, Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from '@/lib/vue3-carousel/src'
import { useCMSStore } from '../../store/useCMSStore'
import { useAppStore } from '@/store/useAppStore'
import { Day } from '../../structure/generalModels'
import { Picturebook, Page, Overlay } from '../../structure/picturebookModels'
import { assetRoot } from '@/constants'
import SlButton from '@/components/base/SlButton.vue'
import CarouselTrigger from '@/components/task/CarouselTrigger.vue'
import { overlayappear } from '../overlays'

export default defineComponent({
  name: 'PictureBook',
  components: {
    Carousel,
    Slide,
    SlButton,
    CarouselTrigger,
    overlayappear, // name is case sensitive when used in template <component> tag
  },
  props: {
    question: { required: true, type: Object as PropType<Picturebook> },
  },
  emits: ['start-introduction', 'completed', 'not-completed'],
  setup(props, context) {
    const { t } = useI18n()
    const cmsStore = useCMSStore()
    const appStore = useAppStore()
    const disableDelays = appStore.getters.disableDelays.value
    const carouselTriggerRef = ref()
    const showPulse = ref(false)
    let checkForMouse = false
    const selectedSet: Day = cmsStore.getters.selectedSet.value.set as Day
    const word = selectedSet ? selectedSet.word : undefined

    const completedOverlays: Ref<Record<string, boolean>> = ref({}) // Keyed by overlay ID
    const currentPageIndex = ref(0)
    const currentPage: Ref<Page> = ref(props.question.pages[currentPageIndex.value])
    const currentOverlay: Ref<Overlay | undefined> = ref()

    const pageOverlays = computed(() => (currentPage.value ? currentPage.value.overlays : []))

    const completeOverlay = (overlay: Overlay): void => {
      if (overlay) completedOverlays.value[overlay.id] = true
      nextOverlay()
    }

    // Play the introduction audio automatically
    context.emit('start-introduction', true)

    // Configure the output object at any time. It will be included when calling 'completed'

    const finish = () => {
      // Call this when the task is complete
      if (allPagesDone.value) context.emit('completed')
      // Call when finishing early
      else context.emit('not-completed')
    }

    // ------ Functions to be called from parent -------

    // Moves task forward, if this task has multiple pages
    const forwardInternal = () => {
      console.log('Move forward internally')
    }

    const activateMouseCheck = () => {
      checkForMouse = true
    }
    const checkMouse = (event: MouseEvent | TouchEvent) => {
      if (checkForMouse && currentOverlay.value && !disableDelays) {
        event.stopImmediatePropagation()
        triggerPulse()
      }
    }
    const deactivateMouseCheck = () => {
      checkForMouse = false
    }

    const triggerPulse = () => {
      if (!showPulse.value) {
        showPulse.value = true
        setTimeout(() => {
          showPulse.value = false
        }, 5000)
      }
    }

    const allPagesDone = computed(() => {
      const done =
        // !currentOverlay.value && // TODO: Is it compulsory to complete overlays before finishing the book?
        currentPageIndex.value === props.question.pages.length - 1
      return done
    })

    // Called when introduction begins
    const onIntroductionStart = () => {
      console.log('Introduction started')
    }
    // Called when introduction ends
    const onIntroductionEnd = () => {
      console.log('Introduction ended')
    }

    let next = () => {
      if (disableDelays || !currentOverlay.value) carouselTriggerRef.value.next()
      else triggerPulse()
    }
    let prev = () => {
      if (carouselTriggerRef.value) carouselTriggerRef.value.prev()
    }

    const nextOverlay = () => {
      let i = 0
      let overlay: Overlay = currentPage.value.overlays[i]
      while (overlay && completedOverlays.value[overlay.id]) {
        overlay = currentPage.value.overlays[++i]
      }
      currentOverlay.value = overlay
    }

    const newPage = (index: number) => {
      currentPageIndex.value = index
      currentPage.value = props.question.pages[currentPageIndex.value]
      completedOverlays.value = {}
      nextOverlay()
    }
    // Check for overlay during setup (first page)
    nextOverlay()

    return {
      t,
      Carousel,
      Slide,
      CarouselTrigger,
      carouselTriggerRef,
      disableDelays,

      word,
      assetRoot,
      next,
      prev,
      checkMouse,
      activateMouseCheck,
      deactivateMouseCheck,
      newPage,
      pageOverlays,
      completeOverlay,
      currentOverlay,
      currentPageIndex,
      allPagesDone,
      showPulse,

      // Methods called from parent
      finish,
      forwardInternal,
      onIntroductionStart,
      onIntroductionEnd,
    }
  },
})
