/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { ref, Ref, computed, ComputedRef } from 'vue'
import cordovaService from '../api/cordovaService'
import { emitError, uuid } from '../utilities'
import { cordovaConstants } from '../constants'
type Timer = ReturnType<typeof setTimeout>

export enum CordovaPathName {
  root = '.',
  users = 'users',
  players = 'players',
  games = 'games',
}
export enum MediaType {
  audio = 'audio',
  video = 'video',
}
export enum CordovaDataType {
  fileEntry = 'fileEntry',
  text = 'text',
  arrayBuffer = 'arrayBuffer',
}
/* export interface CordovaPathType {
  path: string[]
}
export class CordovaPath implements CordovaPathType {
  userID: string
  projectID: string
  participantID: string

  constructor(userID = '', projectID = '', participantID = '') {
    this.userID = userID
    this.projectID = projectID
    this.participantID = participantID
  }

  get path(): string[] {
    const p = []
    if (this.userID) p.push(this.userID)
    if (this.projectID) p.push(this.projectID)
    if (this.participantID) p.push(this.participantID)
    return p
  }
} */

export interface ICordovaOptions {
  dataType?: CordovaDataType
  json?: boolean // attempt to parse JSON or stringify. Supply a return type T. Defaults to True if reading text. False otherwise
  overwrite?: boolean // false   Set to true to overwrite an existing file (open file)
  append?: boolean // false   Set to true to append data to the end of the file (write)
  path?: string[] // Path to the file below the root as an array of directory names (read/write)
  fileName?: string // name of the file on disk (read/write)
  data?: unknown | Blob | string // the content to be written  (write)
  file?: FileEntry // the FileEntry object in memory
  fileToMove?: FileEntry | MediaFile // the file entry object in momory for the file to be moved
}
export class CordovaOptions implements ICordovaOptions {
  dataType: CordovaDataType = CordovaDataType.fileEntry
  json = true
  overwrite = false
  append = false
  path: string[]
  fileName = ''
  data?: unknown | string | Blob
  file?: FileEntry
  fileToMove?: /* data */ FileEntry | MediaFile /* video */

  constructor(data: ICordovaOptions) {
    this.path = []
    if (data) {
      this.dataType = data.dataType || CordovaDataType.fileEntry
      this.json = data.json ? data.json : true
      this.overwrite = data.overwrite ? data.overwrite : false
      this.append = data.append ? data.append : false
      this.path = data.path ? data.path : []
      this.fileName = data.fileName ? data.fileName : ''
      this.data = data.data
      this.file = data.file
      this.fileToMove = data.fileToMove
    }
  }
}

// ------------  State (internal) --------------
export interface CordovaState {
  deviceReady: boolean
  deviceOnline: boolean
  recordingNow: boolean
  cordovaPath: string[]
  currentVideo: FileEntry | undefined
  currentAudio: FileEntry | undefined
  currentAudioBuffer: ArrayBuffer | undefined
  currentAudioFilename: string
  currentVideoFilename: string
}

let mediaStartTime = 0 // Used to know how long the recording has been running (media.getDuration is sometimes innacurate)
let mediaTimeout: Timer // Use this to stop the media if it has run too long

const _cordovaState: Ref<CordovaState> = ref({
  deviceReady: false,
  deviceOnline: window.navigator.onLine,
  recordingNow: false,
  cordovaPath: [],
  currentVideo: undefined,
  currentAudio: undefined,
  currentAudioBuffer: undefined,
  currentAudioFilename: '',
  currentVideoFilename: '',
})

// ------------ Internal fucntions --------------
// Handle the device losing internet connection
function onOffline() {
  _cordovaState.value.deviceOnline = false
}
function onOnline() {
  _cordovaState.value.deviceOnline = true
}

// ------------  Getters (Read only) --------------
interface Getters {
  directoryPath: ComputedRef<string[]>
  deviceOnline: ComputedRef<boolean>
  deviceReady: ComputedRef<boolean>
  currentVideoFile: ComputedRef<FileEntry | undefined>
  currentAudioFile: ComputedRef<FileEntry | undefined>
  currentAudioBuffer: ComputedRef<ArrayBuffer | undefined>
  audioFilename: ComputedRef<string>
  videoFilename: ComputedRef<string>
  recordingNow: ComputedRef<boolean>
}
// Node: these are 'getters' which should be called as a variable, not a function
const getters = {
  get recordingNow(): ComputedRef<boolean> {
    return computed(() => _cordovaState.value.recordingNow)
  },
  get directoryPath(): ComputedRef<string[]> {
    return computed(() => _cordovaState.value.cordovaPath)
  },
  get deviceOnline(): ComputedRef<boolean> {
    return computed(() => _cordovaState.value.deviceOnline)
  },
  get deviceReady(): ComputedRef<boolean> {
    return computed(() => _cordovaState.value.deviceReady)
  },
  get currentVideoFile(): ComputedRef<FileEntry | undefined> {
    return computed(() => _cordovaState.value.currentVideo)
  },
  get currentAudioFile(): ComputedRef<FileEntry | undefined> {
    return computed(() => _cordovaState.value.currentAudio)
  },
  get currentAudioBuffer(): ComputedRef<ArrayBuffer | undefined> {
    return computed(() => _cordovaState.value.currentAudioBuffer)
  },
  get audioFilename(): ComputedRef<string> {
    return computed(() => _cordovaState.value.currentAudioFilename)
  },
  get videoFilename(): ComputedRef<string> {
    return computed(() => _cordovaState.value.currentVideoFilename)
  },
}
// ------------  Actions --------------
interface Actions {
  setup: () => void
  recordVideo: (filename?: string, recordingCompleted?: () => void) => void
  loadMedia: (filename: string, type: MediaType) => Promise<void | FileEntry>
  loadAudioBuffer: (fileName: string) => Promise<ArrayBuffer | void>
  removeMedia: (filename: string, type: MediaType) => Promise<void>
  createAudio: (audioFilename?: string) => Promise<void>
  startRecordingAudio: () => Promise<void>
  stopRecordingAudio: () => Promise<void>
  pauseRecordingAudio: () => Promise<void>
  resumeRecordingAudio: () => Promise<void>

  // In this store, the path is set to locate resources e.g. video / audio recordings
  setCordovaPath: (path: string[]) => void

  // Another Store should call these to have its data saved or loaded
  loadFromStorage: <T>(cordovaOptions: CordovaOptions) => Promise<T | void>
  saveToStorage: (cordovaOptions: CordovaOptions) => Promise<void>
  removeFromStorage: (cordovaOptions: CordovaOptions) => Promise<void>
  copyFileToTemp: (cordovaOptions: CordovaOptions) => Promise<FileEntry | void>
  getCachedMedia: (fileURL: string) => Promise<ArrayBuffer | string>
  loadMediaCache: () => Promise<void>
  saveMediaCache: () => Promise<void>

  // Called from an event listener to log errors to drive
  logErrorMessage: (errorText: string) => void
}
const actions: Actions = {
  setup: function (): void {
    _cordovaState.value.deviceReady = true
    document.addEventListener('offline', onOffline, false)
    document.addEventListener('online', onOnline, false)
    cordovaService.checkPermissionList()
  },
  logErrorMessage: function (errorText: string): void {
    if (_cordovaState.value.deviceReady) cordovaService.saveLog(errorText)
  },
  // Set the location for device data (e.g. recordings)
  setCordovaPath: function (path: string[]): void {
    _cordovaState.value.cordovaPath = path
  },
  // This will look in the Participant's folder for a file and load it to the state.currentVideo.
  loadMedia: function (fileName: string, type: MediaType): Promise<FileEntry | void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName,
      dataType: CordovaDataType.fileEntry,
      path: _cordovaState.value.cordovaPath,
    })
    return this.loadFromStorage<FileEntry>(cd).then((fileEntry: FileEntry | void) => {
      if (fileEntry && type === MediaType.video) {
        _cordovaState.value.currentVideoFilename = cd.fileName
        _cordovaState.value.currentVideo = fileEntry
        _cordovaState.value.recordingNow = false
      } else if (fileEntry && type === MediaType.audio) {
        _cordovaState.value.currentAudioFilename = cd.fileName
        _cordovaState.value.currentAudio = fileEntry
        _cordovaState.value.recordingNow = false
      }
    })
  },
  loadAudioBuffer: function (fileName: string): Promise<ArrayBuffer | void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName,
      dataType: CordovaDataType.arrayBuffer,
      path: _cordovaState.value.cordovaPath,
    })
    return this.loadFromStorage<ArrayBuffer>(cd).then((buffer) => {
      if (buffer) {
        _cordovaState.value.currentAudioFilename = cd.fileName
        _cordovaState.value.currentAudioBuffer = buffer
      }
    })
  },
  removeMedia: function (fileName: string, type: MediaType): Promise<void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName,
      dataType: CordovaDataType.fileEntry,
      path: _cordovaState.value.cordovaPath,
    })
    return this.removeFromStorage(cd).then(() => {
      if (type === MediaType.video) {
        _cordovaState.value.currentVideoFilename = ''
        _cordovaState.value.currentVideo = undefined
        _cordovaState.value.recordingNow = false
      } else if (type === MediaType.audio) {
        _cordovaState.value.currentAudioFilename = ''
        _cordovaState.value.currentAudio = undefined
        _cordovaState.value.recordingNow = false
      }
    })
  },
  // Begin a video recording session - this will call the OS Camera module and resolve when that module returns with a finished video
  // Then the video is moved from the temp folder to a more suitable location
  recordVideo: function (filename?: string, recordingCompleted?: () => void): Promise<void> {
    if (!_cordovaState.value.deviceReady) {
      const e = new Error('Cordova not ready calling recordVideo')
      e.name = 'Warning'
      emitError(e)
      return Promise.resolve()
    }
    if (_cordovaState.value.recordingNow) {
      const e = new Error('Called recordVideo when already recording')
      e.name = 'Warning'
      emitError(e)
      return Promise.resolve()
    }
    _cordovaState.value.recordingNow = true
    return cordovaService
      .captureVideo()
      .then((mediaFile: MediaFile | void) => {
        // The returned file is in the temp directory
        if (mediaFile) {
          const fileId = filename || uuid()
          const cordovaOptions: CordovaOptions = new CordovaOptions({
            fileName: fileId + '.mp4',
            fileToMove: mediaFile,
            path: _cordovaState.value.cordovaPath,
          })
          // This will move a file from a temp directory to our app storage
          cordovaService.moveMediaFromTemp(cordovaOptions).then((fileEntry: FileEntry | void) => {
            if (fileEntry) {
              // What was a MediaFile will now be a FileEntry
              _cordovaState.value.currentVideoFilename = cordovaOptions.fileName || 'videoFilenameNotFound'
              _cordovaState.value.currentVideo = fileEntry
              _cordovaState.value.recordingNow = false
              if (recordingCompleted) recordingCompleted()
            }
          })
        } else {
          // 'cancel' was pressed in the iOS video recorder..
          _cordovaState.value.recordingNow = false
          if (recordingCompleted) recordingCompleted()
        }
      })
      .catch((error: Error) => console.log(error))
  },

  // Create a new audio Media and set it as the current audio
  // Reference: https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-media/
  createAudio: function (audioFilename?: string): Promise<void> {
    if (!_cordovaState.value.deviceReady) {
      const e = new Error('Cordova not ready calling recordAudio')
      e.name = 'Warning'
      emitError(e)
      return Promise.resolve()
    }
    if (_cordovaState.value.recordingNow) {
      const e = new Error('Called recordAudio when already recording')
      e.name = 'Warning'
      emitError(e)
      return Promise.resolve()
    }
    _cordovaState.value.recordingNow = true
    const cordovaOptions: CordovaOptions = new CordovaOptions({
      fileName: (audioFilename || uuid()) + '.m4a', // iOS only records to files of type .wav and .m4a
      // path: [], // the recording will be placed in the application's documents/tmp directory
    })
    return cordovaService
      .createAudio(cordovaOptions)
      .then(() => {
        _cordovaState.value.currentAudioFilename = cordovaOptions.fileName
      })
      .catch((error: Error) => {
        _cordovaState.value.recordingNow = false
        console.log(error)
      })
  },
  // Start recording with the current audio object
  startRecordingAudio: function (): Promise<void> {
    return cordovaService.startRecordingAudio().then(() => {
      _cordovaState.value.recordingNow = true
      console.log('Started audio recorder')
      mediaStartTime = Date.now()
      // Create a timer to stop the recording if it exceeds the maximum duration
      clearTimeout(mediaTimeout)
      mediaTimeout = setTimeout(() => {
        if (_cordovaState.value.recordingNow) {
          this.stopRecordingAudio()
        }
      }, cordovaConstants.audioRecordingMaxDuration)
    })
  },
  pauseRecordingAudio: function (): Promise<void> {
    return cordovaService.pauseRecordingAudio()
  },
  resumeRecordingAudio: function (): Promise<void> {
    return cordovaService.resumeRecordingAudio()
  },
  stopRecordingAudio: function (): Promise<void> {
    return cordovaService
      .stopRecordingAudio()
      .then(() => {
        clearTimeout(mediaTimeout)
        if (!_cordovaState.value.recordingNow) return
        if (mediaStartTime === 0) return
        const mediaLength = Date.now() - mediaStartTime
        // The returned file is in the temp directory
        // Don't use it if it was too short..
        // Audio recording should be 2 seconds minimum
        if (mediaLength < 2000) {
          _cordovaState.value.currentAudioFilename = ''
          _cordovaState.value.currentAudio = undefined
          _cordovaState.value.recordingNow = false
          console.log('Audio recording discarded - too short')
        } else {
          const cordovaOptions: CordovaOptions = new CordovaOptions({
            dataType: CordovaDataType.fileEntry,
            fileName: _cordovaState.value.currentAudioFilename,
            path: _cordovaState.value.cordovaPath, // This path should have been set to the current Participant's directory
          })
          // This will move the audio file from temp directory to our desired storage
          return cordovaService
            .moveMediaFromTemp(cordovaOptions)
            .then((movedFile: FileEntry | void) => {
              if (movedFile) {
                console.log(`Audio file moved to: ${movedFile.toInternalURL()}`)
                // What was a MediaFile will now be a FileEntry
                _cordovaState.value.currentAudioFilename = cordovaOptions.fileName || ''
                _cordovaState.value.currentAudio = movedFile
                console.log('Stopped audio recorder')
              }
              _cordovaState.value.recordingNow = false
            })
            .catch((error: Error) => console.log(error))
        }
      })
      .catch((error: Error) => console.log(error))
  },
  /**
   * Load a file given a CordovaOptions config object
   *
   * e.g.
   * CordovaOptions {
   *    filename: string
   *    path: string[]
   *    readFile: true  <== Returns the content if true, returns a FileEntry if false
   *    type: 'text' if read as text, otherwise read as binary
   *    asJSON: true <== Use false to read a text file as raw text
   * }
   * Returns a promise
   */
  loadFromStorage: function <T>(cordovaOptions: CordovaOptions): Promise<T | void> {
    if (!_cordovaState.value.deviceReady) {
      const e = new Error('Cordova not ready calling loadFromStorage')
      e.name = 'Warning'
      emitError(e)
      return Promise.resolve()
    }
    return cordovaService.loadFromStorage<T>(cordovaOptions)
  },
  /* saveToStorage
   * Save data to device. Include a 'data' object inside CordovaOptions, this will be serialised
   *
   * e.g.
   * CordovaOptions {
   *    data: Participant dict. || TrackingDetails dict.
   *    filename: string
   *    path: string[]
   * }
   * Returns a promise
   */
  saveToStorage: function (cordovaOptions: CordovaOptions): Promise<void> {
    if (!_cordovaState.value.deviceReady) {
      const e = new Error('Cordova not ready calling saveToStorage')
      e.name = 'Warning'
      emitError(e)
      return Promise.resolve()
    }
    return cordovaService.saveToStorage(cordovaOptions)
  },
  /* removeFromStorage
   * Remove a file from device
   *
   * e.g.
   * CordovaOptions {
   *    filename: string
   *    path: string[]
   * }
   * Returns a promise
   */
  removeFromStorage: function (cordovaOptions: CordovaOptions): Promise<void> {
    if (!_cordovaState.value.deviceReady) {
      const e = new Error('Cordova not ready calling saveToStorage')
      e.name = 'Warning'
      emitError(e)
      return Promise.resolve()
    }
    return cordovaService.removeFromStorage(cordovaOptions)
  },
  /* copyFileToTemp
   * Make a copy of the file in the application /tmp directory
   *
   * Resolves:
   *  copied File object
   *  or <void> + emit an error if there was an unexpected result
   */
  copyFileToTemp: function (cordovaOptions: CordovaOptions): Promise<FileEntry | void> {
    if (!_cordovaState.value.deviceReady) {
      emitError(new Error('Cordova not ready calling copyFileToTemp'))
      return Promise.resolve()
    }
    return cordovaService.copyFileToTemp(cordovaOptions)
  },
  /* getCachedMedia
   * Cache a local copy of the file in the application cache directory
   *
   * Resolves:
   *  blob containing local file OR URl to remote image if not found
   */
  getCachedMedia: async function (fileURL): Promise<ArrayBuffer | string> {
    if (!_cordovaState.value.deviceReady) return Promise.resolve(fileURL)
    const media = await cordovaService.getFileFromCache(fileURL)
    // If we didn't find a cahced file, attempt to add this URL to the cache
    if (typeof media === 'string') {
      cordovaService.downloadFileToCache(fileURL)
    }
    return media
  },
  loadMediaCache: function (): Promise<void> {
    if (!_cordovaState.value.deviceReady) return Promise.resolve()
    return cordovaService.loadMediaCache()
  },
  saveMediaCache: function (): Promise<void> {
    if (!_cordovaState.value.deviceReady) return Promise.resolve()
    return cordovaService.saveMediaCache()
  },
}

// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useDeviceService(): ServiceInterface {
  return {
    actions,
    getters,
  }
}

export type DeviceServiceType = ReturnType<typeof useDeviceService>
//export const AppKey: InjectionKey<UseApp> = Symbol('UseApp')
