
import { defineComponent, PropType, ref, Ref, computed, onMounted, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { shuffleItems, playSounds, stopSounds } from '@/utilities'
import { useCMSStore, CMSStoreType } from '@/templates/slplus/store/useCMSStore'
import { useGameStore } from '@/store/useGameStore'
import { Day } from '@/templates/slplus/structure/generalModels'
import { Retell, RetellPage } from '@/templates/slplus/structure/questionModels'
import { Player } from '@/types/main'
import { assetRoot } from '@/constants'
import Button from '@/components/base/Button.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import Avatar from '@/components/base/Avatar.vue'

import Intro from '@/components/task/Intro.vue'
import Instruction from '@/components/task/Instruction.vue'
import PlayersReady from '@/components/task/PlayersReady.vue'
import Sequence from '@/components/task/retell/Sequence.vue'
import RetellDetail from '@/components/task/retell/RetellDetail.vue'

import retellImage from '@/assets/tasks/retell/retell.svg'
import yourTurnImage from '@/assets/tasks/retell/yourturn.svg'
import radioImage from '@/assets/tasks/retell/radio.svg'

import trudeluttSound from '@/assets/tasks/stovnerfaret_26_10.mp3'
import inst1Sound from '@/assets/tasks/retell/Gjenfort_instruks1.mp3'
import inst2Sound from '@/assets/tasks/retell/Gjenfort_instruks2.mp3'
//import inst3Sound from '@/assets/tasks/retell/Gjenfort_instruks3.mp3'
import inst4Sound from '@/assets/tasks/retell/Gjenfort_instruks4.mp3'
import intro1Sound from '@/assets/tasks/retell/Gjenfort_intro1.mp3'
import intro2Sound from '@/assets/tasks/retell/Gjenfort_intro2.mp3'
import intro3Sound from '@/assets/tasks/retell/Gjenfort_intro3.mp3'

const messages = {
  no: {
    title: 'Gjenfortelling',
    description: 'Nå skal vi gjøre en gøyal oppgave, følg med!',
    getStarted: 'Start',
    exercise: 'Aktivitet ',
    playTogether: 'Er dere klare?',
    weReady: 'Neste',
    playTheStory: 'Hør fortellingen',
    ytTitle: 'Nå er det deres tur!',
    ytDescription: 'Nå skal dere fortelle historien. Ta den opp og hør på dere selv, hvis dere vil.',
    congratsDescription: 'Bra jobba!',
    findAName: 'Gi historien deres et navn!',
  },
  en: {
    title: 'Retell with Omar',
    description: 'Now we shall go on a task ,follow ',
    getStarted: 'start',
    exercise: 'Exercise ',
    playTogether: "Let's all play together!",
    weReady: 'We are ready',
    playTheStory: 'Play the story',
    ytTitle: 'Now its your turn!',
    ytDescription: 'Do you remember what this story was about? Now record your own story!',
    congratsDescription: 'Great job!',
    findAName: 'Find a name for your story!',
  },
}

enum MODES {
  intro = 'intro',
  playerReady = 'playerReady',
  instruction = 'instruction',
  sequence = 'sequence',
  tell = 'tell',
  yourTurn = 'yourTurn',
  retell = 'retell',
  videoname = 'videoname',
  finished = 'finished',
}

export default defineComponent({
  name: 'RetellTask', // Type name should be capitalised! Squidex does not allow uppercase for "schemaName" but capitalises first letter in GraphQL response..
  components: {
    Button,
    AnswerInput,
    Avatar,
    Intro,
    Instruction,
    PlayersReady,
    Sequence,
    RetellDetail,
  },
  props: {
    question: { required: true, type: Object as PropType<Retell> },
    myIndex: { required: false, type: Number, default: 0 },
  },
  emits: ['start-introduction', 'completed', 'not-completed'],
  setup(props, context) {
    const { t } = useI18n({ messages })
    const { question } = toRefs(props)
    const mode: Ref<MODES> = ref(MODES.intro)
    const cmsStore: CMSStoreType = useCMSStore()
    const gameStore = useGameStore()
    const { getters: gameGetters } = useGameStore()
    const selectedSet: Day = cmsStore.getters.selectedSet.value.set as Day
    const word = selectedSet ? selectedSet.word : ''
    const currentPage: Ref<RetellPage | undefined> = ref()
    const videoName = ref('')
    const completed: Ref<boolean> = ref(false)
    const currentPlayer: Ref<Player | undefined> = ref()
    const players = gameStore.getters.playersInSelectedGame
    const shuffledPlayers = shuffleItems(players.value)

    // Each time the task is run, shuffle the players
    onMounted(() => {
      currentPlayer.value = undefined
      completed.value = false
      videoName.value = ''
    })

    // Initial setup
    if (question.value) {
      // Configure the parent with this Question's introduction audio
      // context.emit('setup-audio-introduction', '/assets/sounds/tasks/sevenseas/intro.mp3')
      playSounds([trudeluttSound, intro1Sound])
    }

    const exerciseIndex = computed(() => `${t('exercise')} ${props.myIndex + 1}`)

    // Play the introduction audio automatically
    context.emit('start-introduction', true)

    // Configure the output object at any time. It will be included when calling 'completed'

    // Call this when the task is complete
    const finish = () => {
      stopSounds()
      // Call this when the task is complete
      if (completed.value) {
        context.emit('completed')
      }
      // Call when finishing early
      else context.emit('not-completed')
    }

    // ------ Functions to be called from parent -------

    // Moves task forward, if this task has multiple pages
    const forwardInternal = () => {
      console.log('Move forward internally')
    }

    // Called when introduction begins
    const onIntroductionStart = () => {
      console.log('Introduction started')
    }
    // Called when introduction ends
    const onIntroductionEnd = () => {
      console.log('Introduction ended')
    }

    // -------------    Seven Seas -----------------

    const confirmVideoName = () => {
      console.log(videoName.value)
      completed.value = true
      playSounds([inst4Sound])
    }

    const changeMode = (newMode: MODES) => {
      stopSounds()
      if (newMode === MODES.playerReady) {
        playSounds([intro2Sound])
      } else if (newMode === MODES.instruction) {
        playSounds([intro3Sound])
      } else if (newMode === MODES.sequence) {
        playSounds([inst1Sound])
      } else if (newMode === MODES.yourTurn) {
        playSounds([inst2Sound])
        currentPlayer.value = shuffledPlayers[0]
      } else if (newMode === MODES.videoname) {
        completed.value = true // Added to skip naming of book
        // playSounds([inst3Sound]) // removed as there is no Bibliotek
      } else if (newMode === MODES.finished) {
        completed.value = true
      }
      mode.value = newMode
    }

    const playInstruction = () => {
      stopSounds()
      playSounds([intro3Sound])
    }

    const tellPageChanged = (): void => {
      stopSounds()
    }

    const retellPageChanged = (index: number): void => {
      if (shuffledPlayers.length > index) {
        currentPlayer.value = shuffledPlayers[index]
      }
    }

    return {
      t,
      MODES,
      mode,
      word,
      assetRoot,
      exerciseIndex,
      players: gameGetters.playersInSelectedGame,
      selectedSet,

      // Methods called from parent
      forwardInternal,
      onIntroductionStart,
      onIntroductionEnd,

      // Task methods & data
      finish,
      completed,
      currentPage,
      currentPlayer,
      changeMode,
      videoName,
      confirmVideoName,
      playInstruction,
      retellPageChanged,
      tellPageChanged,

      // Assets
      retellImage,
      yourTurnImage,
      radioImage,
    }
  },
})
