/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import './tailwind.css'
import { DraggablePlugin } from '@braks/revue-draggable'
import { createApp } from 'vue'
import { i18n } from './i18n'
import Particles from 'particles.vue3'
import router from './router'
import App from './App.vue'

import { useAppStore } from './store/useAppStore'
import { useDeviceService } from './composition/useDevice'

const { actions: deviceActions } = useDeviceService()
const { actions: appActions } = useAppStore()

const app = createApp(App)

app.use(Particles)

// Register Draggables
// https://github.com/bcakmakoglu/revue-draggable
app.use(DraggablePlugin)

// Redirected OAuth login for mobile devices
// Using cordova-plugin-oauth
// Called from a callback URL like
// com.example.foo://oauth_callback?code=b10a8db164e0754105b7a99be72e3fe5
// it would be received in JavaScript like this:
window.addEventListener('message', function (event) {
  if (event.origin) console.log(`Login callback event origin: ${event.origin}`)
  const url = event.data.toString() as string
  if (url.match(/^oauth::/)) {
    const data = JSON.parse(url.substring(7))
    if (data.mode == 'login' && data.code && data.code !== 'undefined') {
      // The JWT will be sent with future requests to authenticate Mobile users in case the session has expired
      localStorage.setItem('jwt', data.code)
      appActions.tokenLogin()
    } else {
      router.push('/')
    }
  }
})

// 'window.handleOpenURL' is called by Cordova when a Plugin returns
// At this point the OAuth callback URl is available, however OAuth Plugin instead deals with the callback further to parse the included token
// and then calls window.dispatchEvent('message'), which is listened for above
// Refer to OAuthPlugin.swift line 114
/* window.handleOpenURL = function (url: string) {
  setTimeout(() => {
    ... do something with the callback string
  })
} */

/* interface SLPlusCustomEventDetail {
  type: string
  error: Error
} */
// Catch slPLus errors sent via utilities.js error function
window.addEventListener('slpluserror', ((event: CustomEvent) => {
  //do something
  const error = event.detail
  if (error) {
    const errorText = error.toString()
    console.log(errorText)
    deviceActions.logErrorMessage(errorText)
  }
}) as EventListener)

// Catch unhandled errors
window.addEventListener('unhandledrejection', function (event) {
  console.warn(`Uncaught promise: ${event.promise.toString()} Reason: ${event.reason.toString()}`)
})

// Media cache Directive
// Use this to cache(save and load) CMS-based images & videos to the mobile device
// e.g:  <img src="..." v-cache>
// NO NEED to use this with local assets
app.directive('cache', (el: HTMLImageElement) => {
  deviceActions.getCachedMedia(el.src).then((source) => {
    el.src = source as string
  })
})

// Bootstrap the Vue app when called
const initialiseApp = () => {
  app.use(router).use(i18n).mount('#app')
}

if (window.cordova) {
  // If running Cordova, start once it becomes ready & is set up, else start the app immediately
  const onDeviceReady = () => {
    deviceActions.setup()
    appActions.loadSettings().then(() => initialiseApp())
  }
  // If the app is backgrounded, pause audio recording
  const onPause = async () => {
    //await deviceActions.stopRecordingAudio()
    await deviceActions.pauseRecordingAudio()
  }
  // Resume audio recording when app is foregrounded.
  // Note: The recording will likely be lost if the app is removed from memory or killed whilst in background
  const onResume = async () => {
    await deviceActions.resumeRecordingAudio()
    return
  }
  document.addEventListener('deviceready', onDeviceReady, false)
  document.addEventListener('pause', onPause, false)
  document.addEventListener('resume', onResume, false)
} else {
  initialiseApp()
}
