// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../../../node_modules/vue-loader-v16/dist/stylePostLoader.js!../../../../../node_modules/@vue/cli-service/node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!../main.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../../../node_modules/vue-loader-v16/dist/stylePostLoader.js!../../../../../node_modules/@vue/cli-service/node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!../question.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".sl-intro-background[data-v-7af53884] {\n  background-color: #1953b9;\n}\n", ""]);
// Exports
module.exports = exports;
