/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

const theHost = process.env.VUE_APP_SERVER_HOST
const thePort = process.env.VUE_APP_SERVER_PORT
let baseUrl = `${theHost}`
// Local development hosting requires the port for "hot reload" and for mobile app to server access over LAN
if (
  process.env.NODE_ENV === 'development' &&
  theHost &&
  (theHost.includes('localhost') || theHost.includes('10.0.0.') || theHost.includes('192.168.'))
) {
  baseUrl = `${theHost}:${thePort}`
}
const cmsUrl: string = process.env.VUE_APP_CMS_HOST || ''
const assetRoot: string = process.env.VUE_APP_CMS_HOST + '/api/assets' || ''
const cmsTokenUrl = process.env.VUE_APP_SQUIDEX_TOKEN_URL || ''
const cmsClientId = process.env.VUE_APP_SQUIDEX_CLIENT_ID || ''
const cmsClientSecret = process.env.VUE_APP_SQUIDEX_CLIENT_SECRET || ''
const userRolesString = process.env.VUE_APP_USER_ROLES || ''
const userRoles = userRolesString.split(',')
const consentStatesString = process.env.VUE_APP_CONSENT_STATES || ''
const consentStates = consentStatesString.split(',')
const projectNamesString = process.env.VUE_APP_PROJECT_NAMES || ''
const projectNames = projectNamesString.split(',') || ''
const projectTypesString = process.env.VUE_APP_PROJECT_TYPES || ''
const projectTypes = projectTypesString.split(',') || ''
const masteryBaselineID = 'mastery-baseline'
let appVersion: string = document.documentElement.getAttribute('data-appversion') || ''

// The web-based app will always be the latest version, set the version directly from .env
// If not built with Cordova, 'data-appversion' will === '%%VERSION%%'
if (appVersion === '%%VERSION%%') appVersion = process.env.VUE_APP_VERSION || ''

projectTypes.push('none')
projectNames.push('none')

const deviceType: string = window.location.protocol == 'file:' ? 'mobile' : 'web'

const cordovaConstants = {
  videoRecordingMaxDuration: 1200000, // 20 minutes
  audioRecordingMaxDuration: 1200000, // 20 minutes
}

const taskColours = ['#A861A6', '#F84016', '#009F4B', '#A9BD50', '#FFDE01']

// User roles determine what is displayed on screen UI
// FIXME: Edit this enum to match the content of the env file
enum USER_ROLE {
  user = 'user',
  monitor = 'monitor',
  admin = 'admin',
  logs = 'logs',
}
// Ensure enums match those defined in env file
const t = Object.values(USER_ROLE)
if (!userRoles.every((e: string) => t.indexOf(e as USER_ROLE) > -1) || userRoles.length !== t.length) {
  console.error('USER_ROLE enum mismatch', { t, userRoles })
}

// Actual names of projects created in Squidex
// FIXME: Edit this enum to match the content of the env file
enum PROJECT_NAME {
  dslplus = 'dslplus',
  testand = 'testand',
  'slplus-picturebooks' = 'slplus-picturebooks',
  slpilot = 'slpilot',
  none = 'none',
}
// Ensure enums match those defined in env file
const pn = Object.keys(PROJECT_NAME)
if (!projectNames.every((e: string) => pn.indexOf(e as PROJECT_NAME) > -1) || projectNames.length !== pn.length) {
  console.error('PROJECT_NAME enum mismatch', { enums: pn, env: projectNames })
}

// Determines the templates used to display this project
// FIXME: Edit this enum to match the content of the env file
enum PROJECT_TYPE {
  slplus = 'slplus',
  kapteinmorf = 'kapteinmorf',
  none = 'none',
}
// Ensure enums match those defined in env file
const p = Object.keys(PROJECT_TYPE)
if (!projectTypes.every((e: string) => p.indexOf(e as PROJECT_TYPE) > -1) || projectTypes.length !== p.length) {
  console.error('PROJECT_TYPE enum mismatch', { enums: p, env: projectTypes })
}

enum LanguageCodes {
  iv = 'iv', // Invariant code

  no = 'no',
  nn = 'nn',
  en = 'en',
}
// The keys used for i18n selection (except for 'system') should correlate to LanguageCodes (used for Squidex GraphQL calls)
enum LanguageNames {
  system = 'system', // System default
  //ar = 'العربية', // Arabic
  //bs = 'bosanski', // Bosnian
  //nb_NO = 'Norsk Bokmål',
  nn = 'Nynorsk',
  no = 'Norsk',
  sv = 'Svensk',
  /*  nn_NO = 'Norsk Nynorsk', */
  en = 'English',
  //ku = 'Soranî', // Kurdish
  //pl = 'Polski', // Polish
  //so = 'af Soomaali', // Somali
  /*   sq = 'shqip', // Albanian */
  //ta = 'தமிழ்', // Tamil
  //tr = 'Türkçe',
  //ur = 'اردو', // Urdu
  /*  vi = 'tiếng việt', // Vietnamese */
}

interface LF {
  [key: string]: Array<LanguageCodes>
}
const LanguageFallbacks: LF = {
  en: [LanguageCodes.no],
  nn: [LanguageCodes.no],
  no: [LanguageCodes.en],
}

export {
  deviceType,
  baseUrl,
  cmsUrl,
  assetRoot,
  userRoles,
  USER_ROLE,
  PROJECT_NAME,
  PROJECT_TYPE,
  consentStates,
  cmsTokenUrl,
  cmsClientId,
  cmsClientSecret,
  cordovaConstants,
  appVersion,
  masteryBaselineID,
  taskColours,
  LanguageCodes,
  LanguageNames,
  LanguageFallbacks,
}
