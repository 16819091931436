import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded-sm p-1" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.completions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.completions, (c, keyname, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `result-${keyname + index}`,
              class: "border-gray-600 flex flex-col h-20 mx-1 rounded-md overflow-hidden"
            }, [
              (c.total - c.correct > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "bg-red-600 w-10 flex flex-col align-middle justify-center items-center",
                    style: _normalizeStyle({
            height: ((c.total - c.correct) / c.total) * 100 + '%',
            backgroundColor: '#ff3f14',
          })
                  }, [
                    _createElementVNode("p", null, _toDisplayString(c.total - c.correct), 1 /* TEXT */)
                  ], 4 /* STYLE */))
                : _createCommentVNode("v-if", true),
              (c.correct > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "w-10 flex flex-col justify-center items-center",
                    style: _normalizeStyle({ height: (c.correct / c.total) * 100 + '%', backgroundColor: '#42e05d' })
                  }, [
                    _createElementVNode("div", null, _toDisplayString(c.correct), 1 /* TEXT */)
                  ], 4 /* STYLE */))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, "No records found"))
  ]))
}