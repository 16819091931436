
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'SetResultItem',
  props: {
    completions: {
      type: Object as PropType<Record<number, { total: number; correct: number }>>,
      default: () => ({}),
    },
  },
  setup() {
    return {}
  },
})
