import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@/assets/icons/svg/speaker-off.svg'
import _imports_1 from '@/assets/icons/svg/speaker.svg'


const _hoisted_1 = {
  class: "relative flex flex-row justify-center items-center w-full h-full text-center text-white overflow-hidden",
  style: {"background-color":"#1e56b6"}
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 0,
  class: "w-6 h-6",
  src: _imports_0,
  alt: "close"
}
const _hoisted_4 = {
  key: 1,
  class: "w-6 h-6",
  src: _imports_1,
  alt: "close"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "absolute top-4 left-8 transform rotate-180"
}
const _hoisted_7 = {
  key: 1,
  class: "absolute bottom-4 left-2"
}
const _hoisted_8 = {
  key: 2,
  class: "absolute top-4 right-5 transform rotate-180 z-30"
}
const _hoisted_9 = {
  key: 3,
  class: "absolute bottom-4 right-5"
}
const _hoisted_10 = { class: "absolute bottom-5 z-20 rounded-full flex flex-row justify-center w-full p-5 cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PlaceItem = _resolveComponent("PlaceItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Main bubble and item image "),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "absolute pointer-events-auto bg-white p-2 rounded-full top-4 right-4",
        childclass: 'w-12 h-12',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.soundIsPlaying ? _ctx.stopSounds() : _ctx.$emit('playsound')))
      }, {
        default: _withCtx(() => [
          (_ctx.soundIsPlaying)
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : (_openBlock(), _createElementBlock("img", _hoisted_4))
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("img", {
        class: "w-72",
        src: _ctx.omarSpeaksImage,
        alt: "intoduction main image"
      }, null, 8 /* PROPS */, _hoisted_5),
      _renderSlot(_ctx.$slots, "pagination")
    ]),
    _createCommentVNode(" Avatars "),
    (_ctx.players.length >= 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_PlaceItem, {
            player: _ctx.players[0]
          }, null, 8 /* PROPS */, ["player"])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.players.length >= 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_PlaceItem, {
            player: _ctx.players[2]
          }, null, 8 /* PROPS */, ["player"])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.players.length >= 4)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_PlaceItem, {
            player: _ctx.players[3]
          }, null, 8 /* PROPS */, ["player"])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.players.length >= 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_PlaceItem, {
            player: _ctx.players[1]
          }, null, 8 /* PROPS */, ["player"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_10, [
      _renderSlot(_ctx.$slots, "default", { class: "pointer-events-none text-lg text-white capitalize" })
    ])
  ]))
}