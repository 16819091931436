
import { defineComponent, ref, computed, Ref } from 'vue'
import { USER_ROLE } from '@/constants'
import { Group, Game, SPECIAL_REQUEST_TYPE, TRACKING_TYPE, SpecialRequestData } from '@/types/main'
import { useAppStore } from '@/store/useAppStore'
import { useUserStore } from '@/store/useUserStore'
import { useCMSStore } from '../../store/useCMSStore'
import { useGameStore } from '@/store/useGameStore'
import { useProjectStore } from '@/store/useProjectStore'
import SelectionBox from '@/components/base/SelectionBox.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import MonitorParticipantMastery from '@/components/admin/MonitorParticipantMastery.vue'

interface GroupListItem {
  itemName: string
  item: Group
}

export default defineComponent({
  name: 'MonitorMastery',
  components: {
    MonitorParticipantMastery,
    SelectionBox,
    AnswerInput,
  },
  setup() {
    const { getters: appGetters } = useAppStore()
    const { getters: userGetters, actions: userActions } = useUserStore()
    const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
    const { actions: projectActions } = useProjectStore()
    const { getters: gameGetters, actions: gameActions } = useGameStore()
    const showSave = ref(false)
    const groups = userGetters.selectedUserGroups
    const selectedGroup: Ref<GroupListItem> = ref({
      item: new Group(),
      itemName: '',
    })
    const selectedGame = ref(new Game())
    const results: Ref<SpecialRequestData['data']> = ref({})
    const masteryOnly = ref(false)
    const hasAdminRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.admin))

    // Participants filtered by the user's selected project
    const games = computed(() => {
      const p = gameGetters.games.value.filter((pp) => pp.project.id === userGetters.selectedUserProject.value._id)
      return masteryOnly.value ? p.filter((pa) => pa.status.controlActive) : p
    })

    // ----------------  CMS --------------------------------

    // Taken from Layout.vue  Refer to that component for comments
    // We require the correct CMS setup to display the Game's progress
    const getCMSData = () => {
      const project = projectActions.projectById(selectedGame.value.project.id)
      if (project) projectActions.selectProject(project)
      return cmsActions.getSets(appGetters.languageCode.value)
    }

    // --------------- Game functions -----------------

    const selectGame = (p: Game) => {
      gameActions.selectGame(p)
      getCMSData().then(() => {
        gameActions
          .getSpecialRequest(p._id, SPECIAL_REQUEST_TYPE.successresults, masteryOnly.value ? TRACKING_TYPE.mastery : TRACKING_TYPE.all)
          .then((pd) => {
            selectedGame.value = new Game(pd.game)
            results.value = pd.data
          })
      })
    }

    const filterGamesByGroup = (value: GroupListItem) => {
      selectedGroup.value = value
      userActions.selectGroup(selectedGroup.value.item)
      gameActions.getGames('', selectedGroup.value.item._id).then(() => {
        const projectParticipants = games.value.filter((p) => p.project.id === userGetters.selectedUserProject.value._id)
        if (projectParticipants.length > 0) selectGame(projectParticipants[0])
      })
    }

    const groupList = computed((): GroupListItem[] => {
      return groups.value.map((i) => ({
        item: i,
        itemName: i.name,
      }))
    })

    const shuffledRootSet = computed(() => {
      let newRoot = cmsGetters.root.value
      if (selectedGame.value.project.shuffleTopLevel) {
        newRoot = selectedGame.value.project.topLevelOrder.map((s) => cmsActions.setById(s))
      }
      return newRoot
    })

    if (groups.value[0]) {
      selectedGroup.value.itemName = groups.value[0].name
      selectedGroup.value.item = groups.value[0]

      // Fetch games inital value for the default location
      gameActions.getGames('', selectedGroup.value.item._id)
    }

    return {
      // Computed
      games,
      status: appGetters.status,
      hasAdminRole,
      showSave,
      selectedGame,
      results,
      cmsRoot: shuffledRootSet,
      userProject: userGetters.selectedUserProject,

      // Methods
      masteryOnly,
      selectedGroup,
      groupList,
      filterGamesByGroup,
      selectGame,
      // schoolWasSelected,
    }
  },
})
