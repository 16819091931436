
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useGameStore } from '@/store/useGameStore'
import PlaceItem from '@/components/task/horerduordet/PlaceItem.vue'
import Button from '@/components/base/Button.vue'
import { Thumbs } from '@/templates/slplus/structure/questionModels'
import { soundIsPlaying, stopSounds } from '@/utilities'

import bgTextImage from '@/assets/tasks/sevenseas/Flat_3.svg'
import omarSpeaksImage from '@/assets/omar/OMAR-GLAD.svg'

const messages = {
  no: {
    letsDiscuss: 'Diskusjonstid!',
  },
  en: {
    letsDiscuss: 'Discussion time!',
  },
}

export default defineComponent({
  name: 'FindPlaces',
  components: {
    PlaceItem,
    Button,
  },
  emits: ['done', 'playsound', 'stopsound'],
  setup(props, context) {
    const { t } = useI18n({ messages })
    const gameStore = useGameStore()
    const players = gameStore.getters.playersInSelectedGame
    const results: Record<string, Thumbs> = {}

    function clickAnswer(playerIndex: string, answer: Thumbs) {
      console.log(`Player: ${playerIndex} answer: ${answer}`)
      results[playerIndex] = answer
      if (Object.keys(results).length === players.value.length) context.emit('done', results)
    }

    return {
      t,
      players,
      Thumbs,
      clickAnswer,

      // Assets
      omarSpeaksImage,
      bgTextImage,
      soundIsPlaying,
      stopSounds,
    }
  },
})
