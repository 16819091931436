
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SlButton',
  props: {
    logo: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    extraClasses: {
      type: String,
      default: '',
    },
    backgroundClass: {
      type: String,
      default: 'bg-blue-button',
    },
    textClass: {
      type: String,
      default: 'text-white',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {}
  },
})
