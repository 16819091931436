
import { defineComponent, onMounted, ref, toRefs, PropType } from 'vue'
import lottie from 'lottie-web'
import { LottieOptions } from '../../types/main'

export default defineComponent({
  name: 'LottieAnimation',
  props: {
    options: {
      type: Object as PropType<LottieOptions>,
      required: true,
    },
    height: { type: String, default: '100%' },
    width: { type: String, default: '100%' },
  },
  emits: ['animation-created'],
  setup(props, context) {
    const { options, height, width } = toRefs(props)
    const lottieContainer = ref()
    let animation

    const style = {
      width: width ? `${width.value}%` : '100%',
      height: height ? `${height.value}%` : '100%',
      overflow: 'hidden',
    }

    onMounted(() => {
      animation = lottie.loadAnimation({
        container: lottieContainer.value,
        renderer: 'svg',
        loop: options.value.loop !== false,
        autoplay: options.value.autoplay !== false,
        animationData: options.value.animationData,
        path: options.value.path,
        rendererSettings: options.value.rendererSettings,
      })
      context.emit('animation-created', animation)
    })
    return {
      style,
      lottieContainer,
    }
  },
})
