
import { defineComponent, PropType, ref, Ref, computed, watch } from 'vue'
import { Player, Group } from '../../types/main'
import { consentStates } from '../../constants'
import { dateToFormattedString } from '@/utilities'
import AnswerInput from '../base/AnswerInput.vue'
import SlButton from '../base/SlButton.vue'
import Avatar from '@/components/base/Avatar.vue'
import { useGameStore } from '../../store/useGameStore'
import { useUserStore } from '../../store/useUserStore'

interface GroupListItem {
  itemName: string
  item: Group
}

export default defineComponent({
  name: 'MonitorPlayerItem',
  components: {
    SlButton,
    AnswerInput,
    Avatar,
  },
  props: {
    player: {
      type: Object as PropType<Player>,
      required: true,
    },
    edit: {
      type: Boolean,
    },
  },
  setup(props) {
    const { actions: gameActions } = useGameStore()
    const { getters: userGetters } = useUserStore()

    const showSave = ref(false)
    const p = new Player(props.player)
    const thePlayer = ref(p)
    const currentGroupName: Ref<GroupListItem> = ref({
      item: new Group(),
      itemName: '',
    })

    const initialise = (pa: Player) => {
      thePlayer.value = new Player(pa)
    }
    initialise(p)

    watch(
      () => props.player,
      (newValue: Player) => {
        initialise(newValue)
      },
    )

    // ------------------------------------------------------

    const gameGroups = computed(() => {
      const gGroups: Group[] = []
      thePlayer.value.groups.map((g) => {
        const group = userGetters.allGroups.value.find((ag) => ag._id === g)
        if (group) gGroups.push(group)
      })
      return gGroups
    })

    const ownerName = computed(() => {
      const myOwnerID = thePlayer.value.owner
      const u = userGetters.allUsers.value.find((u) => u._id === myOwnerID)
      return u ? u.profile.username : 'owner unknown'
    })

    const groupList = computed((): GroupListItem[] => {
      return userGetters.allGroups.value.map((i) => ({
        item: i,
        itemName: i.name,
      }))
    })

    const consentStateList = Object.values(consentStates).map((v, i) => ({
      id: '' + i,
      title: v,
    }))

    const savePlayer = async () => {
      // Update a selected Player
      await gameActions.updatePlayer(thePlayer.value)
      showSave.value = false
    }

    const saveActive = () => {
      showSave.value = true
    }

    const selectPlayer = () => {
      gameActions.selectPlayer(thePlayer.value)
    }

    return {
      // Computed
      showSave: computed(() => showSave.value),
      thePlayer,
      consentStateList,
      currentGroupName,
      ownerName,
      gameGroups,

      // Methods
      savePlayer,
      saveActive,
      selectPlayer,
      dateToFormattedString,

      groupList,
    }
  },
})
