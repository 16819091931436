
import { defineComponent } from 'vue'
import { useAppStore } from '@/store/useAppStore'
import Button from './Button.vue'
const { getters: appGetters } = useAppStore()

export default defineComponent({
  name: 'DialogBox',
  components: {
    Button,
  },
  setup() {
    return {
      dialogConfig: appGetters.dialogConfig,
    }
  },
})
