import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex flex-col w-full h-full flex-grow sl-intro-background text-center text-white" }
const _hoisted_2 = { class: "flex flex-row flex-grow place-items-center justify-center" }
const _hoisted_3 = {
  ref: "finalBall0",
  class: "rounded-full w-32 h-32",
  style: { backgroundColor: '#1b9cfb' }
}
const _hoisted_4 = {
  ref: "finalBall2",
  class: "rounded-full w-32 h-32",
  style: { backgroundColor: '#1b9cfb' }
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "relative text-left" }
const _hoisted_7 = { class: "text-black text-2xl m-4" }
const _hoisted_8 = { class: "text text-center break-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Particles = _resolveComponent("Particles")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, null, 512 /* NEED_PATCH */),
      _createElementVNode("div", {
        ref: "finalBall1",
        class: _normalizeClass(["rounded-full w-32 h-32", [_ctx.mode === 'zigzag' ? 'mb-20' : '']]),
        style: { backgroundColor: '#1b9cfb' }
      }, null, 2 /* CLASS */),
      _createElementVNode("div", _hoisted_4, null, 512 /* NEED_PATCH */),
      _createElementVNode("div", {
        ref: "finalBall3",
        class: _normalizeClass(["rounded-full w-32 h-32", [_ctx.mode === 'zigzag' ? 'mt-12' : '']]),
        style: { backgroundColor: '#1b9cfb' }
      }, null, 2 /* CLASS */),
      _createElementVNode("img", {
        class: "w-36 h-72 pb-20",
        src: _ctx.youpiImage,
        alt: "intoduction main image"
      }, null, 8 /* PROPS */, _hoisted_5)
    ]),
    _createElementVNode("div", {
      class: "absolute z-10 pointer-events-none bg-left bg-no-repeat bg-fill p-6 bottom-24 left-1/2 transform -translate-x-1/2",
      style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.bgTextImage + ')' })
    }, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.mode === 'straight' ? 'Hmm, ordet var visst ikke der!' : 'Ordet var der!'), 1 /* TEXT */)
        ])
      ])
    ], 4 /* STYLE */),
    _renderSlot(_ctx.$slots, "finish"),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_Particles, {
      id: "tsparticles",
      options: _ctx.options,
      class: "absolute w-full h-full pointer-events-none"
    }, null, 8 /* PROPS */, ["options"])
  ]))
}