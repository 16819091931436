import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "absolute top-0 right-0 text-xs flex flex-row" }
const _hoisted_2 = {
  key: 0,
  class: "mr-2 text-red-600 text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DialogBox = _resolveComponent("DialogBox")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: "fadeInOut h-screen relative",
    style: _normalizeStyle(_ctx.fadeState)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.disableDelays)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Delays & locks disabled"))
        : _createCommentVNode("v-if", true),
      (_ctx.canSeeMonitor)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            id: "button-monitor",
            class: "mr-1 border rounded-md w-16 bg-blue-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.monitor()))
          }, "Monitor"))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode(_component_router_view),
    (_ctx.dialogConfig.visible)
      ? (_openBlock(), _createBlock(_component_DialogBox, { key: 0 }))
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}