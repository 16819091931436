import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6399eec5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute flex flex-col justify-evenly w-full h-full sl-intro-background text-center text-white" }
const _hoisted_2 = { class: "mt-12 text-5xl font-bold max-w-lg text-center m-auto" }
const _hoisted_3 = { class: "flex flex-row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString($setup.t('title')), 1 /* TEXT */),
      _renderSlot(_ctx.$slots, "pagination", {}, undefined, true)
    ]),
    _createCommentVNode(" Get started button goes here"),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}