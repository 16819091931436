
import { defineComponent, PropType, ref, toRefs, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useGameStore } from '@/store/useGameStore'
import Avatar from '@/components/base/Avatar.vue'
import { Enig, Thumbs } from '@/templates/slplus/structure/questionModels'
import RecordButton from '@/components/base/RecordButton.vue'

import omarChat from '@/assets/omar/omar_chat1.svg'
import bgFishedImage from '@/assets/tasks/sevenseas/Bg-Element_fished.svg'
import bgTextImageBlue from '@/assets/tasks/sevenseas/Flat_discussiontime_blue.svg'
import bgTextImage from '@/assets/tasks/sevenseas/Flat_3.svg'
import bgAvatar1 from '@/assets/tasks/sevenseas/Chat_2.svg'
import bgAvatar2 from '@/assets/tasks/sevenseas/Chat_2.svg'
import bgAvatar3 from '@/assets/tasks/sevenseas/Chat_3.svg'
import bgAvatar4 from '@/assets/tasks/sevenseas/Chat_4.svg'
import yes from '@/assets/tasks/enig/yes_enig.svg'
import no from '@/assets/tasks/enig/no_enig.svg'

type ImageKey = 0 | 1 | 2 | 3
type Results = {
  [key in ImageKey]: { answer: Thumbs; correct: boolean } | undefined
}

const messages = {
  no: {
    letsDiscuss: 'Diskusjonstid!',
    discussion: 'Snakk sammen',
    remember: 'Husker alle hva ordet var?',
  },
  en: {
    letsDiscuss: 'Discussion time!',
    discussion: 'Discussion',
    remember: 'Does everyone remember what the word was?',
  },
}

enum MODES {
  intro = 'intro',
  firstInstruction = 'firstInstruction',
  omarSpeaks = 'omarSpeaks',
  movie = 'movie',
  discussion = 'discussion',
  recording = 'recording',
  secondInstruction = 'secondInstruction',
  quizzInstruction = 'quizzInstruction',
  places = 'places',
  playQuizz = 'playQuizz',
  quizzResults = 'quizzResults',
  endRound = 'endRound',
  finalDiscussion = 'finalDiscussion',
  finished = 'finished',
}

export default defineComponent({
  name: 'DiscussionTimeSubtask',
  components: {
    Avatar,
    RecordButton,
  },
  props: {
    question: { required: true, type: Object as PropType<Enig> },
    mode: { type: String as PropType<MODES>, default: MODES.discussion },
    round: { type: Number, default: 0 },
    results: { type: Object as PropType<Results>, default: () => ({}) },
  },
  setup(props) {
    const { results, question, round, mode } = toRefs(props)
    const { t } = useI18n({ messages })
    const gameStore = useGameStore()
    const players = gameStore.getters.playersInSelectedGame
    const videoIsPlaying = ref(false)
    const videoPlayer = ref()

    const totalResults = computed(() => Object.keys(results.value).length)

    const totalThumbsUp = computed(() => {
      const values = Object.values(results.value)
      return values.filter((e) => e?.answer === Thumbs.up).length
    })

    const playVideo = () => {
      videoPlayer.value.addEventListener('ended', () => {
        setTimeout(() => (videoIsPlaying.value = false), 500)
      })
      videoPlayer.value.play()
      videoIsPlaying.value = true
    }

    // Handles video pause event
    const pauseVideo = () => {
      videoPlayer.value.pause()
      videoIsPlaying.value = false
    }

    const togglePlayPause = () => {
      videoIsPlaying.value ? pauseVideo() : playVideo()
    }

    const playMening = () => {
      const soundFile = question.value.mening[round.value].omarStatementAudio || ''
      new Audio(soundFile).play()
    }

    const boxText = computed(() => (mode.value === MODES.discussion ? question.value.movieQuestion : t('remember')))

    return {
      t,
      players,
      videoIsPlaying,
      videoPlayer,
      togglePlayPause,
      playMening,
      MODES,
      totalResults,
      totalThumbsUp,
      boxText,

      // Assets
      omarChat,
      bgTextImageBlue,
      bgTextImage,
      bgFishedImage,
      bgAvatar1,
      bgAvatar2,
      bgAvatar3,
      bgAvatar4,
      yes,
      no,
    }
  },
})
