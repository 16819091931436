
import { defineComponent, PropType, ref, Ref, reactive, computed } from 'vue'
import { PROJECT_NAME, PROJECT_TYPE } from '../../constants'
import { Project } from '../../types/main'
import { useProjectStore } from '../../store/useProjectStore'
import SelectionBox from '../base/SelectionBox.vue'
import AnswerInput from '../base/AnswerInput.vue'
import SlButton from '../base/SlButton.vue'

interface ProjectNameListItem {
  itemName: string
  item: PROJECT_NAME
}
interface ProjectTypeListItem {
  itemName: string
  item: PROJECT_TYPE
}

export default defineComponent({
  name: 'MonitorProjectItem',
  components: {
    SelectionBox,
    AnswerInput,
    SlButton,
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    edit: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
  },
  emits: ['selected'],
  setup(props, context) {
    const { actions: projectActions } = useProjectStore()
    const projectNameList = computed((): ProjectNameListItem[] => {
      return Object.values(PROJECT_NAME).map((i) => ({
        item: i,
        itemName: i,
      }))
    })
    const projectTypeList = computed((): ProjectTypeListItem[] => {
      return Object.values(PROJECT_TYPE).map((i) => ({
        item: i,
        itemName: i,
      }))
    })
    const showSave = ref(false)

    const p = new Project(props.project as Project)
    const editableProject = reactive<Project>(p)

    const currentProjectName: Ref<ProjectNameListItem> = ref({
      item: p.projectName,
      itemName: p.projectName,
    })
    const currentProjectType: Ref<ProjectTypeListItem> = ref({
      item: p.projectType,
      itemName: p.projectType,
    })

    const saveProject = async () => {
      await projectActions.updateProject(editableProject)
      showSave.value = false
    }

    const setProjectName = (value: ProjectNameListItem) => {
      editableProject.projectName = PROJECT_NAME[value.item as PROJECT_NAME]
      showSave.value = true
    }

    const setProjectType = (value: ProjectTypeListItem) => {
      editableProject.projectType = PROJECT_TYPE[value.item as PROJECT_TYPE]
      showSave.value = true
    }

    const deleteProject = async () => {
      await projectActions.deleteProject(editableProject)
      showSave.value = false
    }

    const saveActive = () => {
      showSave.value = true
    }

    const selectMe = () => {
      context.emit('selected')
    }

    return {
      // Computed
      projectNameList,
      projectTypeList,
      showSave: computed(() => showSave.value),
      editableProject,
      currentProjectName,
      currentProjectType,

      // Methods
      setProjectType,
      setProjectName,
      saveProject,
      saveActive,
      selectMe,
      deleteProject,
    }
  },
})
