/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { ref, computed, ComputedRef, Ref } from 'vue'
import router from '../router'
import { apiRequest } from '@/api/apiRequest'
import { useDeviceService, CordovaOptions, CordovaDataType } from '../composition/useDevice'
import { PersistedAppState, LocalUser, APIRequestPayload, XHR_REQUEST_TYPE, DialogConfig } from '@/types/main'

import { appVersion, LanguageCodes } from '../constants'
const { getters: deviceGetters, actions: deviceActions } = useDeviceService()

// ------------  Types --------------
interface AppStatus {
  validToken: boolean
  loading: boolean
  isMobileApp: boolean
  lastLogin: Date
}
export interface AppState {
  validToken: boolean
  errorMessage: string
  loading: boolean
  fade: boolean
  lastLogin: Date
  currentLocalUser: LocalUser | undefined
  appIsOld: boolean
  languageCode: LanguageCodes
  disableDelays: boolean
  dialogConfig: DialogConfig
}
// ------------  State (internal) --------------
const _appState: Ref<AppState> = ref({
  validToken: false,
  errorMessage: '',
  loading: false,
  fade: false,
  lastLogin: new Date(),
  currentLocalUser: undefined,
  appIsOld: false,
  languageCode: LanguageCodes.en,
  disableDelays: process.env.VUE_APP_DISABLE_DELAYS === 'true',
  dialogConfig: {
    title: '',
    text: '',
    visible: false,
    cancel: () => ({}),
    cancelText: 'Cancel',
    confirm: () => ({}),
    confirmText: 'Confirm',
  },
})

// This will be saved to device storage
const _persistedAppState: Ref<PersistedAppState> = ref({
  localUsers: {},
})

// ------------  Getters (Read only) --------------
interface Getters {
  status: ComputedRef<AppStatus>
  fade: ComputedRef<boolean>
  languageCode: ComputedRef<LanguageCodes>
  disableDelays: ComputedRef<boolean>
  currentLocalUser: ComputedRef<LocalUser | undefined>
  persistedLocalUsers: ComputedRef<Record<string, LocalUser>>
  dialogConfig: ComputedRef<DialogConfig>
}
const getters = {
  get status(): ComputedRef<AppStatus> {
    return computed(() => ({
      loading: _appState.value.loading,
      validToken: _appState.value.validToken,
      isMobileApp: deviceGetters.deviceReady.value || navigator.userAgent.indexOf('Mobile') > -1,
      lastLogin: _appState.value.lastLogin,
    }))
  },
  get languageCode(): ComputedRef<LanguageCodes> {
    return computed(() => _appState.value.languageCode)
  },
  // Current state of the display 'fade'
  get fade(): ComputedRef<boolean> {
    return computed(() => _appState.value.fade)
  },
  // TESTING ONLY. Remove app delays
  get disableDelays(): ComputedRef<boolean> {
    return computed(() => _appState.value.disableDelays)
  },
  get currentLocalUser(): ComputedRef<LocalUser | undefined> {
    return computed(() => {
      return _appState.value.currentLocalUser ? _appState.value.currentLocalUser : undefined
    })
  },
  get persistedLocalUsers(): ComputedRef<Record<string, LocalUser>> {
    return computed(() => _persistedAppState.value.localUsers)
  },
  get dialogConfig(): ComputedRef<DialogConfig> {
    return computed(() => _appState.value.dialogConfig)
  },
}
// ------------  Actions --------------
interface Actions {
  setDisableDelays: (s: boolean) => void
  setLanguageCode: (lang: LanguageCodes) => void
  setError: (message: string) => void
  setLoading: (loading: boolean) => void
  setFade: (fade: boolean) => void
  setCurrentLocalUser: (user: LocalUser) => void
  logout: (rememberMe: boolean) => Promise<void>
  tokenLogin: () => Promise<boolean>
  smsLogin: (mobil: string) => Promise<void>
  smsCode: (query: Record<string, string>) => Promise<void>
  loadSettings: () => Promise<void>
  saveSettings: () => Promise<void>
  detectOldApp: () => Promise<void>
  setDialog: (visible: boolean, config?: DialogConfig) => void
}
const actions = {
  setDialog(visible: boolean, config?: DialogConfig): void {
    if (config) _appState.value.dialogConfig = config
    _appState.value.dialogConfig.visible = visible
  },

  // FOR TESTING ONLY. Remove app delays
  setDisableDelays(s: boolean) {
    _appState.value.disableDelays = s
  },
  setLanguageCode: function (languageCode: LanguageCodes): void {
    _appState.value.languageCode = languageCode
  },
  setError: function (message: string): void {
    _appState.value.errorMessage = message
  },
  setLoading: function (loading: boolean): void {
    _appState.value.loading = loading
  },
  // Fade the display down or up
  setFade: function (fade: boolean): void {
    _appState.value.fade = fade
  },
  setCurrentLocalUser: function (user: LocalUser): void {
    _appState.value.currentLocalUser = user
    localStorage.setItem('jwt', user.jwt)
  },
  // The intention of logout is to enforce a new login with the server
  logout: async function (rememberMe: boolean): Promise<void> {
    await apiRequest({
      route: '/auth/logout',
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
    })
    if (_appState.value.currentLocalUser) {
      if (rememberMe) {
        _persistedAppState.value.localUsers[_appState.value.currentLocalUser._id] = _appState.value.currentLocalUser
      } else {
        delete _persistedAppState.value.localUsers[_appState.value.currentLocalUser._id]
      }
      _appState.value.currentLocalUser = undefined
    }
    localStorage.removeItem('jwt')
    await this.saveSettings()
    router.push(`/`)
  },
  // Call server for the current version of the app
  detectOldApp: async function (): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: false,
      route: '/api/appversion',
      contentType: 'text/html',
    }
    let version = ''
    try {
      version = await apiRequest<string>(payload)
    } catch (error: unknown) {
      console.log(`Error getting server version: ${error}`)
    }
    if (appVersion !== version) {
      _appState.value.appIsOld = true
    }
  },
  loadSettings: function (): Promise<void> {
    return new Promise((resolve) => {
      const cd: CordovaOptions = new CordovaOptions({
        fileName: 'settings.json',
        dataType: CordovaDataType.text,
        json: true,
      })
      // If the file does not exist, our cordovaService will create it
      return deviceActions.loadFromStorage<PersistedAppState>(cd).then((data) => {
        if (data) {
          _persistedAppState.value.localUsers = {}
          const d = data
          Object.keys(d.localUsers).forEach((key) => (_persistedAppState.value.localUsers[key] = d.localUsers[key]))
        }
        resolve()
      })
    })
  },
  saveSettings: function (): Promise<void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName: 'settings.json', // Saved to app's root folder
      data: _persistedAppState.value,
      dataType: CordovaDataType.text,
      json: true,
    })
    return deviceActions.saveToStorage(cd)
  },
  // Try to exchange token for a session if the token already exists
  tokenLogin: function (): Promise<boolean> {
    return apiRequest({
      route: '/auth/token',
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
    })
      .then(() => {
        // We now have an active session so proceed as normal..
        router.push('/postlogin')
        return Promise.resolve(true)
      })
      .catch(() => {
        // Exchange was not accepted, clear the token and redirect to login page
        console.log('No valid token. Redirecting to login page..')
        _appState.value.currentLocalUser = undefined
        return Promise.resolve(false)
      })
  },
  // Request One Time Code by mobile number and password
  smsLogin: function (mobil: string): Promise<void> {
    return apiRequest({
      route: '/auth/sms/login',
      method: XHR_REQUEST_TYPE.POST,
      credentials: true,
      body: { mobil },
    })
  },
  // Log in with One Time Code
  smsCode: function (query: Record<string, string>): Promise<void> {
    return apiRequest({
      route: '/auth/sms/code',
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      query,
    })
  },
}
// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useAppStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type AppStoreType = ReturnType<typeof useAppStore>
//export const AppKey: InjectionKey<UseApp> = Symbol('UseApp')
