import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/svg/speaker-off.svg'
import _imports_1 from '@/assets/icons/svg/speaker.svg'
import _imports_2 from '@/assets/omar/omar-party.svg'
import _imports_3 from '@/assets/omar/worm-gla.svg'


const _withScopeId = n => (_pushScopeId("data-v-9d46289c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col justify-evenly w-full h-full sl-intro-background text-center text-white" }
const _hoisted_2 = { class: "flex flex-row w-full h-1/2 bg-gray-200 p-4 pt-32 justify-center items-center" }
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 0,
  style: {"color":"#0199fd"}
}
const _hoisted_5 = { class: "flex flex-row w-full h-1/2 bg-white p-4 justify-center items-center pb-32" }
const _hoisted_6 = {
  key: 0,
  class: "relative h-12 w-full"
}
const _hoisted_7 = {
  key: 0,
  class: "w-6 h-6",
  src: _imports_0,
  alt: "close"
}
const _hoisted_8 = {
  key: 1,
  class: "w-6 h-6",
  src: _imports_1,
  alt: "close"
}
const _hoisted_9 = {
  key: 1,
  class: "w-24",
  src: _imports_2,
  alt: "close"
}
const _hoisted_10 = {
  key: 2,
  class: "w-24",
  src: _imports_3,
  alt: "close"
}
const _hoisted_11 = {
  key: 0,
  class: "absolute bottom-10 left-0 w-full flex flex-row justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_draggable = _resolveDirective("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" drop destinations "),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.pages, (page) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          id: `dropbox-${page.index}`,
          key: page.id,
          axis: 'x',
          class: "drop-target w-48 h-36 mx-4 rounded-xl border border-dashed shadow-lg bg-cover bg-no-repeat bg-center flex flex-row place-items-center justify-center font-bold text-2xl",
          style: _normalizeStyle([{"border-color":"#0199fd"}, [page.active ? { backgroundImage: 'url(' + page.image + ')' } : {}]]),
          onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDropAreaMouseEnter && _ctx.onDropAreaMouseEnter(...args))),
          onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDropAreaMouseLeave && _ctx.onDropAreaMouseLeave(...args)))
        }, [
          (!page.active)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(page.index + 1), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 44 /* STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_3)), [
          [_directive_draggable, { axis: 'none' }]
        ])
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" pages to drag "),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shuffledPages, (dPage) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: dPage.page.id
        }, [
          (!dPage.page.active)
            ? (_openBlock(), _createBlock(_component_Draggable, {
                key: 0,
                class: _normalizeClass([`${_ctx.activeDrags ? 'nopointer' : ''}`, "drag-item"]),
                position: dPage.position,
                onStart: (e) => _ctx.onStart(e, dPage),
                onStop: (e) => _ctx.onDrop(e, dPage)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "w-48 h-36 mx-4 rounded-xl shadow-lg bg-cover bg-no-repeat bg-center",
                    style: _normalizeStyle({ backgroundImage: 'url(' + dPage.page.image + ')' })
                  }, null, 4 /* STYLE */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "position", "onStart", "onStop"]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-2 peek", _ctx.omarActive ? 'bottom-0' : '-bottom-26']),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activateOmar()))
    }, [
      (!_ctx.omarActive && !_ctx.allPagesDone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Button, {
              class: "absolute pointer-events-auto bg-white rounded-full -bottom-3 -right-3 border border-gray-400 p-1",
              childclass: 'w-12 h-12',
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.soundIsPlaying ? _ctx.stopSounds() : _ctx.playInstruction()))
            }, {
              default: _withCtx(() => [
                (_ctx.soundIsPlaying)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                  : (_openBlock(), _createElementBlock("img", _hoisted_8))
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.allPagesDone)
        ? (_openBlock(), _createElementBlock("img", _hoisted_9))
        : (_openBlock(), _createElementBlock("img", _hoisted_10))
    ], 2 /* CLASS */),
    (_ctx.allPagesDone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}