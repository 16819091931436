import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-479730f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "relative h-full",
  style: {"width":"480vh"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "fixed left-0 top-1/2 transform -translate-y-1/2 z-30" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  ref: "boatElement",
  class: "absolute z-30 boat",
  style: {"width":"30vh","top":"23%","left":"100px"}
}
const _hoisted_9 = ["data"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "scrollElement",
    class: "relative flex flex-col overflow-x-scroll w-full h-full sl-intro-background text-center text-white",
    onScroll: $setup.scroll
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: $setup.seaImage,
        class: "absolute h-full",
        alt: "sea background image"
      }, null, 8 /* PROPS */, _hoisted_2),
      _createElementVNode("img", {
        src: $props.item.image,
        class: "absolute wobble",
        style: _normalizeStyle([{"width":"5vh"}, { top: $setup.itemTop + 'px', left: $setup.itemLeft + 'px' }]),
        alt: "sea background image",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.fish()))
      }, null, 12 /* STYLE, PROPS */, _hoisted_3),
      _createElementVNode("img", {
        src: $setup.coralImage,
        class: "absolute z-10 bottom-0 pointer-events-none",
        alt: "coral background image"
      }, null, 8 /* PROPS */, _hoisted_4),
      _createElementVNode("img", {
        src: $setup.fishImage,
        class: "absolute z-20 pointer-events-none",
        style: {"top":"40%"},
        alt: "fish background image"
      }, null, 8 /* PROPS */, _hoisted_5),
      _createCommentVNode(" Review buttons "),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "rounded-r-4xl p-3 pr-5 mt-2 h-16 w-16 flex flex-row justify-end cursor-pointer",
          style: {"background-color":"#d9eefb"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('playinstruction')))
        }, [
          _createElementVNode("img", {
            src: $setup.instructions,
            class: "w-6"
          }, null, 8 /* PROPS */, _hoisted_7)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("object", {
        id: "boat-image",
        type: "image/svg+xml",
        alt: "omars boat",
        data: $setup.boatImage,
        onLoad: $setup.svgLoaded
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_9),
      _createCommentVNode("img :src=\"boatImage\" alt=\"sea background image\" /")
    ], 512 /* NEED_PATCH */)
  ], 544 /* HYDRATE_EVENTS, NEED_PATCH */))
}