
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ItemSelection',
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    colour: {
      type: String,
      required: false,
      default: '#ffffff',
    },
    corners: {
      type: Boolean,
    },
  },
  setup() {
    const mouseIsDown = ref(false)
    return {
      mouseIsDown,
    }
  },
})
