/// <reference lib="webworker" />
import doWorkerTask from '../utils/doWorkerTask'

export default async function speedTransform(audioBuffer: AudioBuffer, opts: { speed: number }): Promise<AudioBuffer> {
  const channels = []
  for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
    channels[i] = new Float32Array(audioBuffer.getChannelData(i))
  }

  // Run worker
  const outputChannels = await doWorkerTask(
    function () {
      ;(self as DedicatedWorkerGlobalScope).onmessage = function (e) {
        const inputChannels = e.data.channels as Float32Array[]
        const speed = e.data.speed
        const outputChannels: Float32Array[] = []
        for (let i = 0; i < inputChannels.length; i++) {
          outputChannels[i] = new Float32Array(Math.floor(inputChannels[i].length / speed))
          for (let j = 0; j < outputChannels[i].length; j++) {
            outputChannels[i][j] = inputChannels[i][Math.floor(j * speed)]
          }
        }
        const t1 = outputChannels.map((c) => c.buffer)
        const t2 = inputChannels.map((c) => c.buffer)
        const transfer = t1.concat(t2)

        self.postMessage(outputChannels, transfer)
        self.close()
      }
    },
    { channels, speed: opts.speed },
    channels.map((c) => c.buffer),
  )

  const ctx = new OfflineAudioContext(audioBuffer.numberOfChannels, outputChannels[0].length, audioBuffer.sampleRate)

  const outputAudioBuffer = ctx.createBuffer(outputChannels.length, outputChannels[0].length, audioBuffer.sampleRate)
  for (let i = 0; i < outputChannels.length; i++) {
    outputAudioBuffer.copyToChannel(outputChannels[i], i)
  }

  return outputAudioBuffer
}
