
import { defineComponent, ref, computed, Ref } from 'vue'
import { LanguageNames, USER_ROLE } from '@/constants'
import { dateToFormattedString } from '@/utilities'
import { User, Project, Group } from '@/types/main'
import { useAppStore } from '@/store/useAppStore'
import { useUserStore } from '@/store/useUserStore'
import { useGameStore } from '@/store/useGameStore'
import { useProjectStore } from '@/store/useProjectStore'
import MonitorProjectItem from '@/components/admin/MonitorProjectItem.vue'
import MonitorPlayerItem from '@/components/admin/MonitorPlayerItem.vue'
import MonitorGameItem from '@/components/admin/MonitorGameItem.vue'
import MonitorGroupItem from '@/components/admin/MonitorGroupItem.vue'
import SelectionBox from '@/components/base/SelectionBox.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import Button from '@/components/base/Button.vue'

interface RoleOptionListItem {
  itemName: string
  item: USER_ROLE
}
interface ProjectOptionListItem {
  itemName: string
  item: Project
}
interface GroupOptionListItem {
  itemName: string
  item: Group
}

export default defineComponent({
  name: 'MonitorProfile',
  components: {
    MonitorProjectItem,
    MonitorPlayerItem,
    MonitorGameItem,
    MonitorGroupItem,
    SelectionBox,
    Button,
    AnswerInput,
  },
  setup() {
    const { getters: appGetters, actions: appActions } = useAppStore()
    const { getters: userGetters, actions: userActions } = useUserStore()
    const { getters: gameGetters, actions: gameActions } = useGameStore()
    const { getters: projectGetters, actions: projectActions } = useProjectStore()
    const u = new User(userGetters.selectedUser.value)
    const theUser: Ref<User> = ref(u)
    const validators: Ref<Record<string, boolean>> = ref({})
    const projectSelected = ref({ item: new Project(), itemName: 'none selected' })
    const groupSelected = ref({ item: new Group(), itemName: 'none selected' })
    const editingParticipant: Ref<string> = ref('')
    const editingGame: Ref<string> = ref('')

    const currentUserRole = ref({
      item: u.profile.role,
      itemName: u.profile.role,
    })
    const showSave = ref(false)
    const delaysDisabled = ref(false)
    delaysDisabled.value = appGetters.disableDelays.value

    const saveUser = () => {
      return userActions.updateUser(theUser.value).then(() => {
        showSave.value = false
      })
    }

    const allValid = computed(() => {
      return Object.values(validators.value).every((v) => v)
    })

    const hasAdminRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.admin))
    const hasLogsRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.logs))

    const selectAUser = (us: User) => {
      userActions.selectUser(us)
      theUser.value = new User(userGetters.selectedUser.value)
      currentUserRole.value.item = theUser.value.profile.role
      currentUserRole.value.itemName = theUser.value.profile.role
      setProjectToCurrentUserProject()
      if (us.groups.length > 0) {
        const groupId = us.groups[0]._id || ''
        gameActions.getPlayers(groupId, '')
        gameActions.getGames(groupId, '')
      }
      editingParticipant.value = ''
      editingGame.value = ''
    }
    const selectMyUser = () => {
      selectAUser(userGetters.myUser.value)
    }
    const lastLogin = computed(() => {
      if (userGetters.selectedUser.value.status.lastLogin) return dateToFormattedString(userGetters.selectedUser.value.status.lastLogin)
      else return 'Unknown'
    })

    const refreshUserList = () => {
      userActions.getAllUsers()
    }

    const addUser = () => {
      userActions.createUser().then((u) => selectAUser(u))
    }

    const languageNames = Object.keys(LanguageNames)
    const languageList = languageNames.map((v, i) => ({
      id: '' + i,
      title: v,
    }))

    // -------------- Role -----------------
    const roleOptionList = computed((): RoleOptionListItem[] => {
      const roles = Object.values(USER_ROLE)
        .filter((r) => hasLogsRole.value || (hasAdminRole.value && r !== USER_ROLE.logs) || (r !== USER_ROLE.logs && r !== USER_ROLE.admin))
        .map((r) => ({ item: r, itemName: r }))
      return roles
    })

    const updateRole = (role: RoleOptionListItem) => {
      theUser.value.profile.role = role.item
      saveUser()
    }
    // -------------- Projects --------------
    const setProjectToCurrentUserProject = () => {
      const currentProject = projectActions.projectById(theUser.value.status.currentProjectId)
      if (currentProject) projectActions.selectProject(currentProject)
    }

    projectActions.getProjects().then(() => {
      setProjectToCurrentUserProject()
    })

    const projectOptionList = computed((): ProjectOptionListItem[] => {
      return projectGetters.projects.value
        .filter((p: Project) => {
          return !theUser.value.projects.some((up) => p._id === up._id)
        })
        .map((p) => ({ item: p, itemName: p.projectName }))
    })

    const addProject = (value: ProjectOptionListItem) => {
      if (value.item) {
        theUser.value.projects.push(value.item)
        saveUser()
      }
    }

    const selectProject = (p: Project) => {
      theUser.value.status.currentProjectId = p._id
      projectActions.selectProject(p)
      saveUser().then(() => projectActions.overrideCMS())
    }

    const removeProject = async (index: number) => {
      theUser.value.projects.splice(index, 1)
      saveUser()
    }

    const userProjects = computed(() => {
      const ps = projectGetters.projects.value.filter((p) => theUser.value.projects.some((tp) => tp._id === p._id))
      ps.forEach((p) => {
        if (p._id === theUser.value.status.currentProjectId) p.selected = true
        else p.selected = false
      })
      return ps
    })

    // -------------- Groups --------------

    const groupOptionList = computed((): GroupOptionListItem[] => {
      return userGetters.allGroups.value
        .filter((g) => !theUser.value.groups.some((up: Group) => up._id === g._id))
        .map((g) => {
          return { item: g, itemName: g.name }
        })
    })

    const addGroupToUser = (value: GroupOptionListItem) => {
      theUser.value.groups.push(value.item)
      saveUser().then(() => userActions.syncUserGroupsToGamesPlayers(theUser.value))
    }

    const removeGroup = async (index: number) => {
      theUser.value.groups.splice(index, 1)
      saveUser().then(() => userActions.syncUserGroupsToGamesPlayers(theUser.value))
    }

    const selectedProject = projectGetters.selectedProject

    const selectedGroups = computed(() => theUser.value.groups)

    const disableDelays = (value: boolean) => {
      appActions.setDisableDelays(value)
    }

    // ---------------- Players ----------------

    const myPlayers = computed(() =>
      gameGetters.players.value.filter((p) => {
        const userGroupIDs = theUser.value.groups.map((g) => g._id)
        const matchingGroup = p.groups.some((g) => userGroupIDs.includes(g))
        return matchingGroup || p.owner === theUser.value._id
      }),
    )
    const newPlayer = () => {
      gameActions.addPlayer(theUser.value)
    }

    // ---------------- Games -------------------

    const myGames = computed(() =>
      gameGetters.games.value.filter((g) => {
        const userGroupIDs = theUser.value.groups.map((g) => g._id)
        const matchingGroup = g.sharing.groups.some((g) => userGroupIDs.includes(g))
        return matchingGroup || g.owner === theUser.value._id
      }),
    )
    const newGame = () => {
      gameActions.addGame(theUser.value)
    }

    // ------------  Initial data --------------

    // Request data to populate the Monitor views
    userActions
      .getAllUsers()
      .then(() => selectAUser(userGetters.myUser.value))
      .then(() => userActions.getAllGroups())

    return {
      // Data
      projectSelected,
      groupSelected,
      editingParticipant,
      editingGame,
      validators,
      languageList,

      // Computed
      status: appGetters.status,
      allUsers: userGetters.allUsers,
      theUser,
      currentUserRole,
      hasAdminRole,
      showSave,
      projectOptionList,
      groupOptionList,
      roleOptionList,
      userProjects,
      selectedGroups,
      updateRole,
      lastLogin,
      selectedProject,
      myPlayers,
      myGames,
      allValid,

      // Methods
      addUser,
      addProject,
      saveUser,
      newPlayer,
      newGame,
      selectProject,
      selectAUser,
      selectMyUser,
      removeProject,
      addGroupToUser,
      refreshUserList,
      removeGroup,
      disableDelays,
      delaysDisabled,
    }
  },
})
