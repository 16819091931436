import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed top-0 left-0 flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-75 rounded-xl no-scrollbar overflow-y-auto" }
const _hoisted_2 = { class: "my-6 flex flex-row flex-wrap bg-viva-grey-400 text-viva-grey-500 rounded-3xl p-6 w-auto" }
const _hoisted_3 = { class: "flex flex-col items-center text-white max-w-md text-center" }
const _hoisted_4 = { class: "text-2xl font-medium" }
const _hoisted_5 = { class: "m-2" }
const _hoisted_6 = { class: "flex flex-row w-full justify-around mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.dialogConfig.title), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.dialogConfig.text), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            backgroundcolour: 'bg-white',
            textcolour: 'text-black',
            childclass: 'h-12 w-32',
            onClick: _ctx.dialogConfig.confirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dialogConfig.confirmText), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_Button, {
            backgroundcolour: 'bg-viva-blue-800',
            textcolour: 'text-white',
            childclass: 'h-12 w-32',
            onClick: _ctx.dialogConfig.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dialogConfig.cancelText), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ])
    ])
  ]))
}