
import { defineComponent, PropType, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { playSounds } from '@/utilities'
import { Callback } from '@/types/main'
// import { useProjectStore } from '@/store/useProjectStore'
import { PlayerWordlist } from '@/templates/slplus/structure/questionModels'
import { getRandomInt } from '@/utilities'
import Button from '@/components/base/Button.vue'

import chaosAudio from '@/assets/tasks/wordchaos/ordkaos_lydfil_kaos.mp3'

const messages = {
  no: {
    title: 'Bytt plass hvis jeg sier ordet ditt!',
    replay: 'Spill igjen',
  },
  en: {
    title: 'Changes places if I say your word!',
    replay: 'Play again',
  },
}

export default defineComponent({
  name: 'ChaosQuizz',
  components: {
    Button,
  },
  props: {
    playerwordlists: {
      type: Object as PropType<PlayerWordlist[]>,
      required: true,
    },
    mindelay: {
      type: Number,
      default: 5,
    },
  },
  emits: ['replay'],
  setup(props, context) {
    const { playerwordlists } = toRefs(props)
    const { t } = useI18n({ messages })
    const allDone = ref(false)
    const chaosSound = new Audio(chaosAudio)
    chaosSound.load()
    const chaos = ref(false)
    const playingNow = ref(true)
    const wordCount = playerwordlists.value.length
    let swapped = false
    let chaosCount = 0
    let comboIndex = 0
    // let chaosWasJustPlayed = false

    let list1: PlayerWordlist[]
    let list2: PlayerWordlist[]

    const squareStyle = ref({
      top: '30%',
      right: '15%',
    })

    const circleStyle = ref({
      top: '30%',
      left: '15%',
    })

    function shiftArray(array: unknown[], shifts: number) {
      let count = 0
      while (count < shifts) {
        array.push(array.shift())
        count++
      }
    }

    // The Ordkaos game concept doesn't work with fewer than three players
    if (wordCount < 3) {
      console.log('Not enough players to play WordChaos')
    }
    // Create two lists to 'pseudo-randomise' the words (already shuffled by parent)
    // This will show all the words twice aleays with a different partner
    list1 = playerwordlists.value.map((w) => ({ ...w }))
    list2 = [...list1]
    shiftArray(list2, list2.length - 1)

    function resetOmar() {
      squareStyle.value.right = '15%'
      squareStyle.value.top = '30%'
      circleStyle.value.left = '15%'
      circleStyle.value.top = '30%'
      swapped = false
    }

    function runChaos() {
      chaos.value = true
      // chaosWasJustPlayed = true
      chaosCount++
      chaosSound.play()
      setTimeout(() => {
        playingNow.value = false
        chaos.value = false
        resetOmar()
        // setupAgainAfterDelay()
      }, 5000) // Chaos lasts for this long
      function run() {
        const randomX1 = (getRandomInt(0, document.body.clientWidth) / document.body.clientWidth) * 100
        const randomY1 = (getRandomInt(0, document.body.clientHeight) / document.body.clientHeight) * 100
        const randomX2 = (getRandomInt(0, document.body.clientWidth) / document.body.clientWidth) * 100
        const randomY2 = (getRandomInt(0, document.body.clientHeight) / document.body.clientHeight) * 100
        squareStyle.value.right = randomX1 + '%'
        squareStyle.value.top = randomY1 + '%'
        circleStyle.value.left = randomX2 + '%'
        circleStyle.value.top = randomY2 + '%'
        setTimeout(() => {
          if (chaos.value) run()
        }, 500)
      }
      run()
    }

    function swapOmar() {
      if (swapped) {
        squareStyle.value.right = '15%'
        circleStyle.value.left = '15%'
        swapped = false
      } else {
        squareStyle.value.right = '60%'
        circleStyle.value.left = '60%'
        swapped = true
      }
      playingNow.value = false
    }

    /*     function playWords(swapOmar: Callback) {
      // Don't use words that have been used twice already
      const theList = [...playerwordlists.value].filter((w) => w.playCount < 2)

      // If less than two words remain to play, we're finished
      if (theList.length < 2) {
        allDone.value = true
        return
      }

      let i1 = getRandomInt(0, theList.length - 1)
      const w1 = theList[i1]

      // Ensure the two words are not the same, and weren't previously paired
      let i2 = getRandomInt(0, theList.length - 1)
      while (i1 === i2 || w1.playedWith.includes(theList[i2].wordlist.id)) {
        i2 = getRandomInt(0, theList.length - 1)
      }

      const w2 = theList[i2]
      w1.playCount++
      w1.playedWith.push(w2.wordlist.id)
      w2.playCount++
      w2.playedWith.push(w1.wordlist.id)

      const randomSoundTruthy1 = getRandomInt(0, 1)
      const randomSoundTruthy2 = getRandomInt(0, 1)
      const audio1 = new Audio(randomSoundTruthy1 ? w1.wordlist.sound1 : w1.wordlist.sound2)
      const audio2 = new Audio(randomSoundTruthy2 ? w2.wordlist.sound1 : w2.wordlist.sound2)

      audio1.addEventListener('ended', () => {
        audio2.addEventListener('ended', () => {
          swapOmar()
        })
        audio2.play()
      })
      audio1.play()
    } */
    function playWords(swapOmar: Callback) {
      // chaosWasJustPlayed = false
      const w1 = list1[comboIndex]
      const w2 = list2[comboIndex]
      comboIndex++
      if (comboIndex === wordCount) allDone.value = true
      w1.playCount++
      w1.playedWith.push(w2.wordlist.id)
      w2.playCount++
      w2.playedWith.push(w1.wordlist.id)

      const randomSoundTruthy1 = getRandomInt(0, 1)
      const randomSoundTruthy2 = getRandomInt(0, 1)
      const audio1 = randomSoundTruthy1 ? w1.wordlist.sound1 : w1.wordlist.sound2
      const audio2 = randomSoundTruthy2 ? w2.wordlist.sound1 : w2.wordlist.sound2

      playSounds([audio1, audio2], swapOmar)
    }

    function setup() {
      playingNow.value = true
      // Arbitrary - if chaos is true we will play 'chaos!' instead
      const chance = wordCount > 3 ? 12 : 9
      chaos.value = getRandomInt(0, chance) < 5 // (0, 9) => 50% chance of kaos. (0, 12) => 33% chance of kaos.

      // Play chaos! if it was randomly selected, as long as it's not been played twice already, or if we haven't seen chaos once before the end
      if (
        // !chaosWasJustPlayed &&
        (chaos.value && chaosCount < 2) ||
        (comboIndex === wordCount - 1 && chaosCount === 0)
      ) {
        runChaos()
      } else {
        playWords(() => swapOmar())
      }
    }

    function playAgain() {
      context.emit('replay')
    }

    setTimeout(() => setup(), 1500)

    return {
      t,
      allDone,
      playAgain,
      setup,
      chaos,
      squareStyle,
      circleStyle,
      playingNow,
    }
  },
})
