
// @ is an alias to /src
import { defineComponent, computed, ref } from 'vue'
import { apiRequest } from '@/api/apiRequest'
import { useAppStore } from '@/store/useAppStore'
import { useUserStore } from '@/store/useUserStore'
import { useDeviceService } from '@/composition/useDevice'
import { useGameStore } from '@/store/useGameStore'
import router from '@/router'
import Avatar from '@/components/base/Avatar.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import { USER_ROLE } from '@/constants'
import { XHR_REQUEST_TYPE } from '@/types/main'

import enigImage from '@/assets/omar/OMAR-GLAD.svg'

export default defineComponent({
  name: 'DashboardComponent',
  components: {
    AnswerInput,
    Avatar,
  },
  setup() {
    const { actions: appActions, getters: appGetters } = useAppStore()
    const { getters: userGetters, actions: userActions } = useUserStore()
    const { getters: gameGetters } = useGameStore()

    const { getters: deviceGetters, actions: deviceActions } = useDeviceService()
    const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))
    const rememberMe = ref(false)
    const rightMenuTransitionName = ref('slideprev')
    const rightMenuActive = ref(false)
    const rightMenuLevel = ref('main')
    appActions.setFade(false)

    // --------------------------------------------------------------

    // Save media cache here, so it runs after returning from a Question series
    // Yet to determine if this is the best position to do this
    deviceActions.saveMediaCache()

    // Auto-determine the currently selected Game
    const game = gameGetters.selectedGame

    const playerListForGame = computed(() => {
      const players = gameGetters.players.value
      if (game.value) {
        const g = game.value
        return players.filter((p) => g.activePlayers.includes(p._id))
      } else return []
    })

    const logout = () => {
      const completeLogout = () => {
        appActions.logout(rememberMe.value)
        router.push(`/`)
      }
      apiRequest({
        route: '/auth/logout',
        method: XHR_REQUEST_TYPE.GET,
        credentials: true,
      })
        .then(() => {
          completeLogout()
        })
        .catch((error) => {
          // Exchange was not accepted, clear the token and redirect to login page
          console.log(error)
          completeLogout()
        })
    }

    // Only show 'remember me' if we are on a device, not a desktop browser
    let showRememberMe = computed(() => deviceGetters.deviceReady || process.env.NODE_ENV == 'development')

    const avatarCreator = () => {
      router.push('/avatar')
    }

    const goToGames = () => {
      router.push('/dashboard/games')
    }

    const toggleRightMenu = () => {
      rightMenuActive.value = !rightMenuActive.value
      rightMenuLevel.value = 'main'
    }

    const toggleSettings = () => {
      rightMenuTransitionName.value = rightMenuTransitionName.value === 'slidenext' ? 'slideprev' : 'slidenext'
      rightMenuLevel.value = rightMenuLevel.value === 'main' ? 'settings' : 'main'
    }

    return {
      monitor: () => router.push('/monitor'),
      logout,
      game,

      rememberMe,
      showRememberMe,
      localUser: appGetters.currentLocalUser,
      hasMonitorRole,
      playerListForGame,

      avatarCreator,
      enigImage,

      rightMenuActive,
      rightMenuLevel,
      rightMenuTransitionName,
      toggleRightMenu,
      toggleSettings,
      goToGames,
    }
  },
})
