
import { ref, defineComponent, computed, onMounted, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { PROJECT_NAME } from '@/constants'
import { shuffleItems } from '@/utilities'
import { useAppStore } from '@/store/useAppStore'
import { useCMSStore } from '../store/useCMSStore'
import { useUserStore } from '@/store/useUserStore'
import { useGameStore } from '@/store/useGameStore'
import GridItem from './GridItem.vue'
import SlButton from '@/components/base/SlButton.vue'
import BookImage from '@/assets/icons/svg/book1.svg'

/* Interfaces */
import { Sett, Question, Game } from '@/types/main'
import { Picturebook } from '../structure/picturebookModels'
import { Day, TopLevel } from '../structure/generalModels'

type AllListItems = Sett | Question | Picturebook

const messages = {
  no: {
    week: 'Uke',
  },
  en: {
    week: 'Week',
  },
}

export default defineComponent({
  name: 'LayoutComponent',
  components: {
    GridItem,
    SlButton,
  },
  setup() {
    const { t } = useI18n({ messages })
    const { getters: userGetters } = useUserStore()
    const { getters: gameGetters, actions: gameActions } = useGameStore()
    const game = gameGetters.selectedGame
    const { getters: appGetters, actions: appActions } = useAppStore()
    const { getters: cmsGetters, actions: cmsActions } = useCMSStore()

    // Transitions
    const transitionName = ref('slidenext')
    const layoutVisible = ref(true)
    const libraryVisible = ref(false)
    const isMobileApp = appGetters.status.value.isMobileApp

    // Sett lists
    const atLevel = computed(() => cmsGetters.selectedSet.value.level)
    const selectedSet: Ref<TopLevel | Day> = computed(() => cmsGetters.selectedSet.value.set as TopLevel | Day)
    const selectedIndex = computed(() => cmsGetters.selectedSet.value.index)
    const selectedList: Ref<AllListItems[]> = ref<AllListItems[]>([])
    const parentIndexes: number[] = []

    const selectedUserProject = userGetters.selectedUserProject
    if (!selectedUserProject.value.projectName) selectedUserProject.value.projectName = PROJECT_NAME.dslplus

    // Initial values, and re-select the most recent Sett on return to this component
    const setLevel = () => {
      if (selectedSet.value) downALevel(selectedSet.value, selectedIndex.value)
      else {
        upALevel()
        checkCompletions(false)
      }
    }

    const shuffledRootSet = computed(() => {
      let newRoot = cmsGetters.root.value
      if (game.value && game.value.project.shuffleTopLevel) {
        newRoot = game.value.project.topLevelOrder.map((s) => cmsActions.setById(s))
      }
      return newRoot
    })

    onMounted(() => {
      if (router.currentRoute.value.params.reloaded === 'yes') {
        cmsActions.selectSet(undefined, -1, 0)
      }
    })

    // Initial setup
    if (selectedUserProject) {
      // Get Sets if not already done
      cmsActions.getSets(appGetters.languageCode.value).then(() => {
        // Decide if we shuffle the top level on first run
        if (game.value && game.value.project.shuffleTopLevel && game.value.project.topLevelOrder.length === 0) {
          const rootIDs: string[] = cmsGetters.root.value.map((r: Sett) => r._id)
          const shuffledIDs = shuffleItems(rootIDs)
          const g = new Game(game.value)
          g.project.topLevelOrder = shuffledIDs
          gameActions.updateGame(g)
        }
        setLevel()
      })
    }

    // Return the index of the set in the given list not permitted, qualified also by a not permitted sub-set
    // This is designed to target SETTs, not QUESTIONS
    // If the Sett is not present, we will block progression beyond it
    /*    const restrictProgressSetIndex = (list: AllListItems[]): number => {
      const index = list.findIndex((set) => {
        const match =
          set && !game.value.mastery.allowedSets.includes(set._id)
        let subMatch = false
        if (set instanceof Sett) {
          subMatch = restrictProgressSetIndex(set.sets) > -1
        }
        return match || subMatch
      })
      return index
    } */

    // TESTING COMPLETION CHECK
    const checkCompletions = (disable: boolean) => {
      if (disable) return
      const theGame = game.value
      if (theGame) {
        const weeks = selectedList.value as Sett[]
        weeks.forEach((week, weekIndex) => {
          const days = week.sets as Day[]
          let special = false
          days.forEach((day, dayIndex) => {
            const questions = day.questions
            const picturebook = day.picturebook
            const dayComplete = theGame.itemIsComplete(day._id, week._id)
            const picturebookComplete = !picturebook || (picturebook && theGame.itemIsComplete(picturebook._id, day._id))
            const allQuestionsComplete = questions.every((q) => {
              return theGame.itemIsComplete(q._id, day._id)
            })
            if (!dayComplete && picturebookComplete && allQuestionsComplete) {
              gameActions.completeProgressForItem(day._id, week._id || '')
              console.log(`Repaired day ${day._id}`)
              if (dayIndex === 3 && weekIndex < 3) special = true // Complete early (past) weeks if last day is done
            }
          })
          const weekComplete = theGame.itemIsComplete(week._id, week._id)
          const allDaysComplete = days.every((d) => {
            return theGame.itemIsComplete(d._id, week._id)
          })
          if (!weekComplete && (allDaysComplete || special)) {
            gameActions.completeProgressForItem(week._id, '')
            console.log(`Repaired week ${week._id}`)
            special = false
          }
        })
      }
    }

    // Given a list item, determine its COMPLETED status
    const itemWasCompleted = (item: Sett | Question | Picturebook | undefined): boolean => {
      if (!item) return false
      const parentId = selectedSet.value?._id || item.parent?._id || ''
      const wasCompleted = !!game.value && game.value.itemIsComplete(item._id, parentId)
      return wasCompleted
    }
    // Given a list item, determine its SEEN status
    const itemWasSeen = (item: Sett | Question | Picturebook | undefined): boolean => {
      if (!item) return false
      const parentId = selectedSet.value?._id || item.parent?._id || ''
      const wasCompleted = !!game.value && game.value.itemAttempts(item._id, parentId) > 0
      return wasCompleted
    }

    // Given a list item, determine its DISABLED status
    const itemDisabled = (item: Sett | Question | Picturebook, index: number) => {
      // Control for multiple-probe style testing: the Participant is bounded until the researcher moves them forward
      const itemCompleted = itemWasCompleted(item)
      let itemProgressBlocked = false
      if (item instanceof Sett && !!game.value && game.value.restrictProgress) {
        // An accessible set is determined from participants' mastery sub-section: mastery.allowedSets
        // A valid Set ID found there will allow access
        itemProgressBlocked = !game.value.status.allowedSets.includes(item._id)
      }
      // Possibly prevent access to items that were already completed
      if (itemCompleted && !!game.value && !game.value.status.redoTasks) itemProgressBlocked = true
      // Navigation level - below top level
      if (selectedSet.value) {
        // Disable picturebook if it has been completed *TODAY*
        if (item instanceof Picturebook) {
          /* const lastPicturebookCompletion = participantStore.actions.latestAttemptDate(
            item._id,
            selectedSet.value._id
          )
          if (lastPicturebookCompletion) {
            // If the picturebook has been read today, enable the next task
            const wasRead = moment().isSame(
              lastPicturebookCompletion as Date,
              'day'
            )
            return wasRead
          }
          return true */
          return itemProgressBlocked // For now, only disable Picturebook if by Mastery
        }
      }
      // Finally, item is locked if previous item is not complete (unless we're allowed to 'skip tasks'), or Mastery says it should be
      const skipIsNotActive = !!game.value && !game.value.status.skipTasks
      //return (index > 0 && !itemWasCompleted(selectedList.value[index - 1]) && skipIsNotActive) || itemProgressBlocked
      return (index > 0 && !itemWasSeen(selectedList.value[index - 1]) && skipIsNotActive) || itemProgressBlocked
    }

    // Navigate DOWN into the layout
    function downALevel(item: TopLevel | Day | Picturebook | Question | Sett, index: number) {
      const pictureBookList: Picturebook[] = []
      let level = atLevel.value
      transitionName.value = 'slidenext'
      layoutVisible.value = false
      parentIndexes.push(selectedIndex.value)

      if (item instanceof Sett) {
        // A Sett was selected below root level
        if (item instanceof Day) {
          level = 2
          // Specific for SLPlus - second level is a 'Day'
          if (item.picturebook) pictureBookList.push(item.picturebook)
          // Ensure Question data is allocated to the Sett
          cmsActions.getQuestions(item, appGetters.languageCode.value).then(() => {
            // Insert Picturebook, Task Questions and Mastery Set into the layout
            selectedList.value = [...pictureBookList, ...item.questions]
          })
        } else {
          // Selecting different item as was already selected
          // Item was a Week or some other intermediate level
          selectedList.value = item.sets
          level = atLevel.value + 1
        }
        cmsActions.selectSet(item, index, level)
      } else if (item instanceof Question) {
        // A Question was selected
        cmsActions.selectQuestion(item, index)
        appActions.setFade(true)
        setTimeout(() => {
          router.push('/question')
        }, 1000)
      }
      setTimeout(() => (layoutVisible.value = true), 500)
    }

    // Navigate UP through the layout
    const upALevel = () => {
      transitionName.value = 'slideprev'
      layoutVisible.value = false
      const parent = selectedSet.value?.parent
      const parentIndex = parentIndexes.pop()
      if (!selectedSet.value || !parent) {
        // Go to root level (select no item)
        selectedList.value = shuffledRootSet.value
        cmsActions.selectSet(undefined, -1, 0)
      } else {
        selectedList.value = parent.sets
        cmsActions.selectSet(parent, parentIndex || 0, 1)
      }
      setTimeout(() => (layoutVisible.value = true), 500)
    }

    const showLibrary = () => {
      transitionName.value = 'slidenext'
      layoutVisible.value = false
      libraryVisible.value = true
    }
    const hideLibrary = () => {
      transitionName.value = 'slideprev'
      layoutVisible.value = true
      libraryVisible.value = false
    }

    const gridLevelClassName = computed(() => {
      switch (atLevel.value) {
        case 0:
          return isMobileApp ? 'top-level-grid' : 'top-level-grid-vertical'
        case 1:
          return 'second-level-grid'
        case 2:
          return 'question-level-grid'
        case 3:
          return 'question-level-grid'
        default:
          return 'top-level-grid'
      }
    })

    const backgroundClassName = computed(() => {
      const completed = itemWasCompleted(selectedSet.value)
      switch (atLevel.value) {
        case 0:
          return 'bg-gray-background'
        case 1:
          return completed ? 'bg-teal-lightbackground' : 'bg-gray-background'
        case 2:
          return completed ? 'bg-teal-lightbackground' : 'bg-gray-background'
        case 3:
          return completed ? 'bg-teal-lightbackground' : 'bg-gray-background'
        default:
          return 'top-level-grid'
      }
    })

    return {
      t,
      // Data
      selectedList,
      itemDisabled,
      itemWasCompleted,
      selectedSet,
      selectedIndex,
      atLevel,
      gridLevelClassName,
      backgroundClassName,
      BookImage,

      transitionName,
      layoutVisible,
      libraryVisible,
      isMobileApp,

      // Methods
      downALevel,
      upALevel,
      showLibrary,
      hideLibrary,
    }
  },
  methods: {},
})
