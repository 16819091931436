
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TaskIntro',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    description: {
      type: String,
      default: 'Description',
    },
    exerciseIndex: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: String,
      default: '12rem',
    },
  },
})
