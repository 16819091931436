import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative flex flex-col justify-center items-center",
  style: {"background-color":"#b3e9ff"}
}
const _hoisted_2 = { class: "relative z-20" }
const _hoisted_3 = {
  class: "bg-white rounded-4xl mt-12 p-3 font-playful text-2xl font-bold",
  style: {"color":"#715d4d"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "absolute rounded-full w-20 h-20 -top-10 -left-10",
        style: _normalizeStyle({ backgroundColor: _ctx.player.profile.avatar.backgroundColour || '#aaaaaa' })
      }, null, 4 /* STYLE */),
      _createVNode(_component_Avatar, {
        class: "absolute w-32 h-32 -m-6 -top-10 -left-10",
        layout: _ctx.player.profile.avatar
      }, null, 8 /* PROPS */, ["layout"])
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.player.profile.name), 1 /* TEXT */)
  ]))
}