/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { computed, ref, Ref, ComputedRef } from 'vue'
import { USER_ROLE } from '../constants'
import { apiRequest } from '../api/apiRequest'
import { hasMinimumRole } from '../utilities'
import { User, UserData, Group, GroupData, LocalUser, Project, APIRequestPayload, XHR_REQUEST_TYPE } from '../types/main'
import { useAppStore } from './useAppStore'
import { useDeviceService, CordovaOptions, CordovaPathName, CordovaDataType } from '../composition/useDevice'

const { actions: deviceActions } = useDeviceService()
const { actions: appActions } = useAppStore()

// ------------  State (internal) --------------

interface State {
  myUser: User
  selectedUser: User
  selectedGroup: Group | undefined
  allUsers: User[]
  allGroups: Group[]
  cordovaPath: string[]
}

const state: Ref<State> = ref({
  myUser: new User(), // The actual logged in User. Initialised after successful login
  selectedUser: new User(), // This user is the model for making changes to a User
  selectedGroup: undefined,
  allUsers: [], // All users in the system, for admins
  allGroups: [], // All groups in the system, for admins
  cordovaPath: [],
})

// ------------  Getters --------------

// Once a reactive getter has been gotten by a component
// we cannot overwrite its instance here in the store - but we can write to its children reactively
// Complex objects provided by a getter here should be represented by a Class and also have an update() function
interface Getters {
  myUser: ComputedRef<User>
  selectedUser: ComputedRef<User>
  allUsers: ComputedRef<User[]>
  allGroups: ComputedRef<Group[]>
  selectedUserGroups: ComputedRef<Group[]>
  selectedGroup: ComputedRef<Group | undefined>
  selectedUserProject: ComputedRef<Project>
}
const getters = {
  get myUser(): ComputedRef<User> {
    return computed(() => state.value.myUser) // This is the current logged in user and should not change during app usage
  },
  get selectedUser(): ComputedRef<User> {
    return computed(() => state.value.selectedUser) // This is the 'currently selected' user and can change, must change by calling User.update()
  },
  get selectedGroup(): ComputedRef<Group | undefined> {
    return computed(() => state.value.selectedGroup) // This is the current logged in user and should not change during app usage
  },
  get allUsers(): ComputedRef<User[]> {
    return computed(() => state.value.allUsers) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get allGroups(): ComputedRef<Group[]> {
    return computed(() => state.value.allGroups)
  },
  get selectedUserGroups(): ComputedRef<Group[]> {
    return computed(() => state.value.selectedUser.groups)
  },
  get selectedUserProject(): ComputedRef<Project> {
    return computed(() => {
      const currentPID = state.value.selectedUser.status.currentProjectId
      const p = state.value.selectedUser.projects.find((pr) => pr._id === currentPID)
      return p ? p : new Project()
    })
  },
}

// ------------  Actions --------------
interface Actions {
  hasMinimumRole: (user: User, role: USER_ROLE) => boolean
  selectUser: (user?: User) => void
  selectGroup: (group?: Group) => void

  // Server
  getMyUser: () => Promise<void>
  getAllUsers: () => Promise<void>
  getAllGroups: () => Promise<void>
  createUser: () => Promise<User>
  updateUser: (user: User) => Promise<void>
  syncUserGroupsToGamesPlayers: (user: User) => Promise<void>

  createGroup: () => Promise<void>
  updateGroup: (group: Group) => Promise<void>
  deleteGroup: (group: Group) => Promise<void>

  // Disk
  setCordovaPath: (userID: string) => void
  loadData: () => Promise<void>
  saveData: () => Promise<void>
}
const actions = {
  // Retrieve from server the user details (called after login when online & not mobile)
  getMyUser: async function (): Promise<void> {
    appActions.setLoading(true)
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      route: '/api/user',
    }
    const response: UserData = await apiRequest<UserData>(payload)
    state.value.myUser.update(response)
    state.value.selectedUser.update(state.value.myUser)
    state.value.allUsers.push(new User(response))
    state.value.cordovaPath = [CordovaPathName.users, state.value.myUser._id]
    const newLocalUser: LocalUser = {
      _id: state.value.myUser._id,
      name: state.value.myUser.profile.fullName,
      lastLogin: new Date(),
      jwt: localStorage.getItem('jwt') || '',
      pin: '',
      selected: true,
    }
    appActions.setCurrentLocalUser(newLocalUser)
    appActions.setLoading(false)
    return Promise.resolve()
  },
  // Supply void to set an empty group
  selectGroup: function (group?: Group): void {
    if (group) {
      state.value.selectedGroup = group
    } else state.value.selectedGroup = undefined
  },
  getAllGroups: async function (): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      route: '/api/groups',
      query: { mode: 'all' },
    }
    return apiRequest<GroupData[]>(payload).then((response) => {
      const groups = response.map((g: GroupData) => new Group(g))
      state.value.allGroups = groups
      appActions.setLoading(false)
    })
  },
  createGroup: function (): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.POST,
      credentials: true,
      route: '/api/group',
    }
    return apiRequest<GroupData>(payload).then((response: GroupData) => {
      const newGroup = new Group(response)
      state.value.allGroups.push(newGroup)
    })
  },
  deleteGroup: function (group: Group): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.DELETE,
      credentials: true,
      route: '/api/group',
      body: group,
    }
    return apiRequest(payload).then(() => {
      const deletedGroupIndex = state.value.allGroups.findIndex((g: Group) => g._id === group._id)
      if (deletedGroupIndex > -1) state.value.allGroups.splice(deletedGroupIndex, 1)
    })
  },
  updateGroup: function (group: Group): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.PUT,
      credentials: true,
      route: '/api/group',
      body: group,
    }
    return apiRequest(payload).then(() => {
      const modifiedGroup = state.value.allGroups.find((g) => g._id === group._id)
      if (modifiedGroup) modifiedGroup.update(group)
    })
  },
  // Ensure Players and Games have the correct Group allocations
  // Called from Monitor only
  syncUserGroupsToGamesPlayers: function (user: User) {
    appActions.setLoading(true)
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.POST,
      credentials: true,
      route: '/api/group/sync',
      body: user,
    }
    return apiRequest<void[]>(payload).then(() => appActions.setLoading(false))
  },

  // Retrieve from server a listing of all users
  getAllUsers: async function (): Promise<void> {
    appActions.setLoading(true)
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      route: '/api/users',
    }
    const response: UserData[] = await apiRequest<UserData[]>(payload)
    const users = response.map((u: UserData) => new User(u))
    state.value.allUsers = users
    appActions.setLoading(false)
  },

  createUser: async function (): Promise<User> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.POST,
      credentials: true,
      route: '/api/user',
    }
    const uData: UserData = await apiRequest<UserData>(payload)
    const newU = new User(uData)
    state.value.allUsers.push(newU)
    return Promise.resolve(newU)
  },

  // Update a given user at server, and locally if it exists in allUsers
  updateUser: async function (user: User): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.PUT,
      credentials: true,
      route: '/api/user',
      body: user,
    }
    return apiRequest(payload).then(() => {
      // Also update the user in local list
      const modifiedUser = state.value.allUsers.find((u) => u._id === user._id)
      if (modifiedUser) {
        modifiedUser.update(user)
      }
    })
  },

  // Check that the selected user has at least the role requested
  // Direct reference to (utility function)
  hasMinimumRole,

  // Supply void to select no User
  selectUser: function (user?: User) {
    if (user) {
      const u = state.value.allUsers.find((us) => us._id === user._id)
      if (u) {
        state.value.selectedUser = u
      }
    } else state.value.selectedUser = new User()
  },
  setCordovaPath: function (userID: string): void {
    state.value.cordovaPath = [CordovaPathName.users, userID]
  },
  loadData: function (): Promise<void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName: 'user.json',
      dataType: CordovaDataType.text,
      json: true,
      path: state.value.cordovaPath,
    })
    return new Promise((resolve) => {
      deviceActions.loadFromStorage<UserData>(cd).then((data) => {
        if (data) {
          state.value.myUser.update(data)
          state.value.selectedUser.update(state.value.myUser)
          resolve()
        }
      })
    })
  },
  saveData: function (): Promise<void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName: 'user.json',
      data: state.value.myUser.asPOJO(),
      dataType: CordovaDataType.text,
      json: true,
      path: state.value.cordovaPath,
    })
    return deviceActions.saveToStorage(cd)
  },
}

interface ServiceInterface {
  actions: Actions
  getters: Getters
}
// This defines the interface used externally
export function useUserStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type UserStoreType = ReturnType<typeof useUserStore>
