/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import moment from 'moment'
import { uuid } from '@/utilities'
import { PROJECT_NAME, PROJECT_TYPE, USER_ROLE, taskColours, LanguageCodes, LanguageNames } from '@/constants'
import { SVGRendererConfig } from 'lottie-web'
import { ColDef, ColGroupDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community'

// ---------------  Utility -----------------
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WkWebView: any
    handleOpenURL: unknown
  }
  interface String {
    toPascalCase(): string
    toCamelCase(): string
    padZero(length: number): string
  }
  interface MediaFile {
    localURL: string
  }
}
String.prototype.toPascalCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toUpperCase() + text.substr(1)
}

String.prototype.toCamelCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toLowerCase() + text.substr(1)
}

String.prototype.padZero = function (length: number): string {
  let s = String(this)
  while (s.length < length) {
    s = '0' + s
  }
  return s
}

export interface ColumnDef {
  headerName: string
  field: string

  children?: {
    field: string
    headerName: string
    columnGroupShow?: string
    // eslint-disable-next-line
    [x: string]: any
  }[]
  hide?: boolean | unknown
  editable?: boolean | unknown
  // eslint-disable-next-line
  [x: string]: any
}
interface Dictionary<T> {
  [Key: string]: T
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U
}

/* class Time extends MyDate {
  [Symbol.toPrimitive](hint: 'default'): string
  [Symbol.toPrimitive](hint: 'string'): string
  [Symbol.toPrimitive](hint: 'number'): number
  [Symbol.toPrimitive](hint: string): string | number {
    switch (hint) {
      case 'number':
        return this.valueOf()
      case 'string':
        return this.toString()
      default:
        return this.toString()
    }
  }
} */

export { Dictionary, EnumDictionary }

export interface LottieOptions {
  loop?: boolean
  autoplay?: boolean
  // eslint-disable-next-line
  animationData?: string | object
  path?: string
  src?: string
  rendererSettings?: SVGRendererConfig
}

// ---------------  Models -----------------

type LC<T> = {
  [key in LanguageCodes]: T
}

export interface CallbackOneParam<T, U = void> {
  (arg?: T): U
}
export interface Callback {
  (...args: unknown[]): unknown
}

// This defined the additional functions available on a Question Type component
// This allows Question.vue to control the question type child
// The child should implement appropriate code that runs when these functions are called
/* export interface AugmentedQuestionType extends Vue {
  forwardInternal: () => void // Called when the user clicks the white 'forward' arrow
  onIntroductionStart: () => void // Called when introduction begins
  onIntroductionEnd: () => void // Called when introduction ends
} */

export interface LocalUser extends Record<string, unknown> {
  _id: string
  jwt: string
  lastLogin: Date
  pin: string
  name: string
  selected: boolean
}
// General App settings that should be saved to disk
export interface PersistedAppState extends Record<string, unknown> {
  localUsers: Record<string, LocalUser>
}

export interface DialogConfig {
  title: string
  text: string
  visible: boolean
  confirm: Callback
  confirmText: string
  cancel: Callback
  cancelText: string
}

interface ProjectData {
  _id?: string
  projectName: PROJECT_NAME
  projectType: PROJECT_TYPE
  interventionName: string
  tsdGroupName: string
  tsdGroupID: string
  cmsClient: string
  cmsSecret: string
  // Front-end use only
  selected?: boolean
}
export class Project implements ProjectData {
  _id: string
  projectName: PROJECT_NAME
  projectType: PROJECT_TYPE
  interventionName: string
  tsdGroupName: string
  tsdGroupID: string
  cmsClient: string
  cmsSecret: string

  // Front-end use only
  selected: boolean

  constructor(data?: ProjectData) {
    this._id = ''
    this.projectName = PROJECT_NAME.none
    this.projectType = PROJECT_TYPE.none
    this.interventionName = ''
    this.tsdGroupName = ''
    this.tsdGroupID = ''
    this.cmsClient = ''
    this.cmsSecret = ''
    this.selected = false

    if (data) this.update(data)
  }

  update(data: Project | ProjectData): void {
    this._id = data._id ? data._id : ''
    this.projectName = data.projectName
    if (data.projectType) this.projectType = data.projectType
    this.interventionName = data.interventionName
    this.tsdGroupName = data.tsdGroupName
    this.tsdGroupID = data.tsdGroupID
    this.cmsClient = data.cmsClient
    this.cmsSecret = data.cmsSecret

    // Front end variables
    this.selected = !!data.selected
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    return { ...this }
  }
}

// ---------------  Base CMS model classes ------------------
// --- Extend these to represent real Squidex model types ---

export enum DISPLAY_MODE {
  linear = 'linear',
  shuffle = 'shuffle',
  mastery = 'mastery',
}

export interface SetData extends CmsGQLData {
  data: {
    index: LC<number>
    name: LC<string>
    title: LC<string>
    word: LC<string>
    description: LC<string>
    subtitle: LC<string>
    displayMode: LC<DISPLAY_MODE>
    consolidation: LC<boolean>
    wordImage?: string | LC<[{ url: string }] | null>
  }
}

// Sett class should be extended to represent a Project's actual Sett shape
export class Sett {
  _id: string
  index: number = 0
  displayMode: DISPLAY_MODE = DISPLAY_MODE.linear
  name: string = ''
  title = ''
  subtitle = ''
  description: string = ''
  sets: Sett[] = []
  questions: Question[] = []
  consolidation?: boolean

  // Frontend only
  disabled = false
  parent?: Sett
  thumbnail = ''

  constructor(spec?: SetData, parent?: Sett, language?: LanguageCodes) {
    const l = language || LanguageCodes.iv
    this._id = (spec && spec.id) || ''
    this.parent = parent ? parent : undefined
    const data = spec?.data
    if (data) {
      this.index = (data.index && data.index.iv) || 0
      this.name = (data.name && data.name.iv) || ''
      this.title = (data.title && data.title[l]) || this.name
      this.description = (data.description && data.description.iv) || ''
      if (!this.description) this.description = (data.subtitle && data.subtitle.iv) || ''
      this.displayMode = data.displayMode.iv || DISPLAY_MODE.linear
      this.consolidation = (data.consolidation && data.consolidation.iv) || false
      this.thumbnail = typeof data.wordImage === 'string' ? data.wordImage : data.wordImage?.iv?.[0].url || ''
      this.questions = []
    }
  }

  get root(): Sett {
    return this.parent ? this.parent : this
  }

  addQuestion(q: Question): void {
    this.questions.push(q)
  }
  clearQuestions(): void {
    this.questions.length = 0
  }
}

export interface QuestionData {
  id: string
  __typename: unknown
  type?: QUESTION_TYPES
  name: string | { iv: string }
  thumbnail?: string
  recordAudio?: boolean
  flatData?: unknown
  data?: unknown
}

// When TypeScript can extend enums, this can be made generic..
export enum QUESTION_TYPES {
  question = 'question',
  mastery = 'mastery',
  picturebook = 'picturebook',
}

// Question class should be extended to represent a Project's actual Sett shape
export class Question {
  _id: string
  __typename: unknown // This string is used to generate components. The component name must match it
  type: string // Frontend only - subclass type of this Question instance
  disabled = false
  parent?: Sett
  name: string
  word = ''
  thumbnail: string
  recordAudio: boolean

  constructor(spec: QuestionData, parent?: Sett) {
    this._id = spec.id
    this.__typename = spec.__typename
    this.type = spec.type ? spec.type : QUESTION_TYPES.question
    this.parent = parent ? parent : undefined
    if (typeof spec.name === 'string') {
      this.name = spec.name
    } else {
      this.name = spec.name.iv
    }
    this.recordAudio = !!spec.recordAudio
    this.thumbnail = spec.thumbnail || ''
  }
}

// ----------  Squidex response shapes --------------

// Data level of a Squidex GraphQL response. Can be supplied as single or array
// Extend this interface to represent different responses for various Sett and Question types
export interface CmsGQLData {
  __typename: string
  id?: string
  flatData?: unknown
  data?: unknown
}
// Shape of the Sett -> Question response
export interface CmsQuestionData extends CmsGQLData {
  data: {
    questions: LC<CmsGQLData[]>
  }
}

// Top level of a Squidex GrapghQL response
export interface CmsGQLQuery {
  data?: {
    results: CmsGQLData[] | CmsGQLData | CmsQuestionData
    M400?: CmsGQLData[]
    M401?: CmsGQLData[]
    M402?: CmsGQLData[]
    items?: CmsGQLData[]
  }
  errors?: []
  access_token?: string
}

// ---------------  User & Player -----------------

interface ProgressData {
  itemId: string
  parentId: string
  completed?: boolean
  completions?: Array<string | Date>
  attempts?: Array<string | Date>
}
// Progress follows the flattened shape of CMS Sett and Question data
// meaning: Progress tracks the Player's completion status on a particular Sett or Question
export class Progress {
  itemId: string // CMS ID of the tracked item
  parentId: string // CMS ID of the current parent of this item
  completed = false
  completions: Date[] = [] // Completions marked only if item was not previously completed
  attempts: Date[] = [] // Attempts on this item (increments if already completed, may be larger than completions[])

  constructor(data: ProgressData) {
    this.itemId = data.itemId
    this.parentId = data.parentId
    this.completed = !!data.completed
    if (data.parentId) this.parentId = data.parentId
    if (data.completions && data.completions.length > 0) {
      data.completions.forEach((cp: string | Date) => {
        this.completions.push(new Date(cp))
      })
    }
    if (data.attempts && data.attempts.length > 0) {
      data.attempts.forEach((cp: string | Date) => {
        this.attempts.push(new Date(cp))
      })
    }
  }

  // Return the most recent completion
  get latestCompletion(): Date {
    return this.completions[this.completions.length - 1]
  }

  // Return the most recent attempt
  get latestAttempt(): Date {
    return this.attempts[this.attempts.length - 1]
  }

  // Set this Progress to be 'completed'
  // Add a new timestamp for this completion
  // Returns the total current number of completions
  complete(): number {
    const newDate = new Date()
    if (!this.completed) {
      this.completed = true
      this.completions.push(newDate)
    }
    this.attempts.push(newDate)
    return this.completions.length
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): ProgressData {
    const pojo = { ...this }
    return pojo
  }
}
export interface TrackingDetailsTableLayout {
  oid: string
  type: TRACKING_TYPE
  created: Date
  gameID: string
  itemID: string
  playerIDs: string[]
  duration: string
  audioFile: string
  audioFileSize: string
  user: string
  project: string
  groupName: string
  rootName: string
  rootIndex: number
  rootID: string
  setID: string
  taskAttempts: number
  setCompletions: number

  taskType: string
  taskInfo: string
  isCorrect: boolean
  word: string
  page: number
  results: string
}
export enum TRACKING_TYPE {
  interaction = 'interaction',
  question = 'question',
  set = 'set',
  mastery = 'mastery',
  picturebook = 'picturebook',
  subtask = 'subtask',
  all = 'all',
}
// TrackingData will store information about a 'usage' of a Question, Picture Book etc
export interface TrackingData {
  itemID: string // ID of the associated question or picturebook etc. (from Squidex CMS)
  gameID: string // ID of the associated Game
  playerIDs: string[] // IDs of associated Players
  projectID: string // ID of the associated Project
  type: string
  isMedia?: boolean

  // All other items are optional
  oid?: string // Unique key used to map this item
  createdAt?: Date | string // should mark the start of the tracking (used when supplied from server - MongoDB)
  created?: Date | string // (used when de-serialising from local tablet disk)
  duration?: number // should mark the end of the tracking in seconds, starting from 'created'
  audioFile?: string
  videoFile?: string
  details?: Record<string, unknown> // Holds any kind of extra data needed for the Tracking. Extend this with a more specific Type in generalModels.ts

  audioFileSize?: number // Size of the uploaded file
  videoFileSize?: number // Size of the uploaded file

  localSynced?: boolean // saved to disk locally
  serverSynced?: boolean // saved to our server
  storageSynced?: boolean // saved to TSD
  resent?: number // has this tracking been resent (needed for problem solving unsent trakcings)
}

export interface TrackingSearch {
  itemID?: string // ID of the associated question, set or picturebook etc. (from Squidex CMS)
  gameID?: string // ID of the associated Game
  playerIDs?: string[] // IDs of associated Players
  isMedia: boolean // Tracking is usable as a 'media' item
}

export class Tracking {
  itemID: string = '' // ID of the associated question, set or picturebook etc. (from Squidex CMS)
  gameID: string = '' // ID of the associated Game
  playerIDs: string[] = [] // IDs of associated Players
  projectID: string = '' // ID of the associated Project
  type: TRACKING_TYPE = TRACKING_TYPE.question
  isMedia: boolean = false

  oid: string = '' // Unique key used to map this item
  created: Date = new Date() // the start of the tracking
  duration: number = 0 // should mark the end of the tracking in seconds, starting from 'created'
  audioFile?: string
  videoFile?: string
  details?: Record<string, unknown> // Holds any kind of data specific to the question type

  audioFileSize?: number // Size of the uploaded file
  videoFileSize?: number // Size of the uploaded file

  // Status
  localSynced: boolean = false // saved to disk locally
  serverSynced: boolean = false // saved to our server successfully
  storageSynced: boolean = false // sent to TSD successfully
  resent: number = 0

  constructor(trackingdata?: TrackingData) {
    if (trackingdata) {
      this.oid = trackingdata.oid ? trackingdata.oid : uuid()
      this.itemID = trackingdata.itemID
      this.gameID = trackingdata.gameID
      this.playerIDs = trackingdata.playerIDs
      this.projectID = trackingdata.projectID
      this.type = trackingdata.type as TRACKING_TYPE
      this.isMedia = !!trackingdata.isMedia
      if (trackingdata.createdAt) this.created = new Date(trackingdata.createdAt)
      else if (trackingdata.created) this.created = new Date(trackingdata.created)
      this.duration = trackingdata.duration ? trackingdata.duration : 0

      // Optional
      this.audioFile = trackingdata.audioFile
      this.videoFile = trackingdata.videoFile
      this.audioFileSize = trackingdata.audioFileSize
      this.videoFileSize = trackingdata.videoFileSize

      this.localSynced = !!trackingdata.localSynced
      this.serverSynced = !!trackingdata.serverSynced
      this.storageSynced = !!trackingdata.storageSynced
      if (trackingdata.resent && trackingdata.resent > 0) this.resent = trackingdata.resent
    }
  }

  // Complete this Tracking by setting its duration and possibly 'data'
  complete(): void {
    const startDate = moment(this.created)
    const endDate = moment()
    this.duration = endDate.diff(startDate, 'seconds')
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    const pojo = { ...this }
    return pojo
  }

  duplicate(): Tracking {
    return { ...this } as Tracking
  }
  static columnDefs(): (ColDef | ColGroupDef)[] {
    return []
  }
}

export interface TrackingDetailsData extends TrackingData {
  details: {
    user?: string // The current User
    groupName?: string // The User's Group
    project?: string // The current project
    rootName?: string // The name of the current 'root' e.g. 'Sortere', the current week
    rootIndex?: number // The index of the current root
    rootID?: string // The ID (Squdiex) of the current root
    setID?: string // The ID of the current Sett (week, day, episode, session etc.)
    taskAttempts?: number // How many times the same item (Task / Question) has been attempted
    setCompletions?: number // How many times the current 'sett' (session, day, etc.) has been completed

    taskType?: string // The type of challenge e.g. Retell, Enig etc.
    taskInfo?: string // Arbitrary information
    isCorrect?: boolean // true or false, if this is appropriate
    word?: string // The current 'word' e.g. 'Sortere'
    playerStorage1?: string // Arbritary data placeholder 1
    playerStorage2?: string // Arbritary data placeholder 2
    page?: number // Used for 'retell' task - the current page, allows reloading the same audio to play back
    results?: Record<string, unknown> // Used for 'enig' and 'horer' task
    // For 'enig' this is { answer: Thumbs; correct: boolean }
    // For 'horer' this is { throws: { [participantID]: number of throws }, sentence: index of the current sentence }
  }
}

export class TrackingDetails extends Tracking {
  details: TrackingDetailsData['details'] = {}

  constructor(data?: TrackingDetailsData) {
    super(data)
    if (data && data.details) this.updateData(data)
  }

  updateData(data: TrackingDetailsData): void {
    this.details = {
      // Used for 'Mastery'
      user: data.details.user,
      groupName: data.details.groupName,
      project: data.details.project,
      rootName: data.details.rootName,
      rootIndex: data.details.rootIndex,
      rootID: data.details.rootID,
      setID: data.details.setID,
      taskAttempts: data.details.taskAttempts,
      setCompletions: data.details.setCompletions,

      taskType: data.details.taskType,
      taskInfo: data.details.taskInfo,
      isCorrect: data.details.isCorrect,
      word: data.details.word,
      playerStorage1: data.details.playerStorage1,
      playerStorage2: data.details.playerStorage2,
      page: data.details.page, // Used for 'retell'
      results: data.details.results,
    }
  }
  // Deliberately compares date but not time!
  static dateComparator(date1: Date, date2: Date): number {
    return date2.getTime() - date1.getTime()
  }
  static dateGetter(params: ValueGetterParams): string {
    return params.data.created
  }
  static dateFormatter(params: ValueFormatterParams): string {
    return (params.value as Date).toLocaleString()
  }
  static audioSizeComparator(a1: number, a2: number): number {
    return a2 - a1
  }
  public static columnDefs(): (ColDef | ColGroupDef)[] {
    return [
      {
        headerName: 'Main',
        children: [
          { headerName: 'ID', field: 'oid' },
          {
            headerName: 'Created',
            field: 'created',
            sortable: true,
            comparator: TrackingDetails.dateComparator,
            valueGetter: TrackingDetails.dateGetter,
            valueFormatter: TrackingDetails.dateFormatter,
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: (date1: Date, date2: Date) => {
                date2.setHours(0, 0, 0, 0) // Compare dates, but not time for this filter
                if (date1.getTime() == date2.getTime()) {
                  return 0
                }
                return date2.getTime() - date1.getTime()
              },
              browserDatePicker: true,
              minValidYear: 2022,
              maxValidYear: 2030,
              inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            },
          },
          {
            field: 'user',
            headerName: 'User',
            filter: true,
          },
          { headerName: 'Task Duration', field: 'duration' },
          { headerName: 'Category', field: 'type', filter: true },
          { headerName: 'Audio Size', field: 'audioFileSize' },
          { headerName: 'Audio File', field: 'audioFile' },
          { headerName: 'Task Type', field: 'taskType', filter: true },
          { headerName: 'Kindergarten', field: 'groupName', sortable: true, filter: true },
        ],
      },
      {
        headerName: 'Details',
        children: [
          {
            field: 'detail',
            headerName: 'Detail',
            columnGroupShow: 'closed',
          },
          {
            field: 'project',
            headerName: 'Project',
            columnGroupShow: 'open',
          },
          {
            field: 'rootName',
            headerName: 'Root Name',
            columnGroupShow: 'open',
          },
          {
            field: 'rootIndex',
            headerName: 'Root Index',
            columnGroupShow: 'open',
          },
          { field: 'rootID', headerName: 'Root ID', columnGroupShow: 'open' },
          {
            field: 'setID',
            headerName: 'Set ID',
            columnGroupShow: 'open',
          },
          {
            field: 'itemID',
            headerName: 'Item ID',
            columnGroupShow: 'open',
          },
          { headerName: 'Game ID', field: 'gameID', columnGroupShow: 'open' },
          { headerName: 'Player IDs', field: 'playerIDs', columnGroupShow: 'open' },
          {
            field: 'taskAttempts',
            headerName: 'Task Attempts',
            columnGroupShow: 'open',
          },
          {
            field: 'setCompletions',
            headerName: 'Set Completions',
            columnGroupShow: 'open',
          },
          {
            field: 'taskInfo',
            headerName: 'Task Info',
            columnGroupShow: 'open',
          },
          {
            field: 'isCorrect',
            headerName: 'Is Correct',
            columnGroupShow: 'open',
          },
          {
            field: 'word',
            headerName: 'Word',
            columnGroupShow: 'open',
          },
          {
            field: 'results',
            headerName: 'Results',
            columnGroupShow: 'open',
          },
          {
            field: 'page',
            headerName: 'Page (gjenfortelling)',
            columnGroupShow: 'open',
          },
        ],
      },
    ]
  }
  // Use to get data for a Table
  public get asTableData(): TrackingDetailsTableLayout {
    return {
      oid: this.oid,
      type: this.type,
      gameID: this.gameID,
      playerIDs: this.playerIDs,
      created: this.created,
      duration: this.duration + 's',
      groupName: this.details.groupName || '(unknown)',
      audioFile: this.audioFile || '',
      audioFileSize: this.audioFileSize ? Math.trunc(this.audioFileSize / 1024) + 'KB' : 'unknown',
      user: this.details.user || '',
      project: this.details.project || '',
      rootName: this.details.rootName || '',
      rootIndex: this.details.rootIndex || 0,
      rootID: this.details.rootID || '',
      setID: this.details.setID || '',
      itemID: this.itemID || '',
      taskAttempts: this.details.taskAttempts || 0,
      setCompletions: this.details.setCompletions || 0,
      taskType: this.details.taskType || '',
      taskInfo: this.details.taskInfo || '',
      isCorrect: this.details.isCorrect || false,
      word: this.details.word || '',
      page: (this.details.page || 1) + 1,
      results: JSON.stringify(this.details.results),
    }
  }

  // Mastery-specific Tracking entries
  complete(data?: TrackingDetailsData): void {
    if (data && data.details) this.updateData(data)
    super.complete()
  }
}

export interface GroupData {
  _id: string
  name: string
  location: string
}
export class Group {
  _id: string
  name: string
  location: string

  constructor(data?: GroupData | Group) {
    this._id = ''
    this.name = ''
    this.location = ''
    if (data) this.update(data)
  }

  update(data: GroupData | Group): void {
    this._id = data._id
    this.name = data.name
    this.location = data.location
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    return { _id: this._id, name: this.name, location: this.location }
  }
}

export interface PlayerData {
  _id?: string
  owner: string
  groups: string[]
  consent: {
    id: string
    state: string
  }
  profile: {
    ref: string
    name: string
    thumbnail: string
    colour: string
    avatar: AvatarLayout
    deleted: boolean
  }
  data: Record<string, unknown>
}
export class Player {
  _id = ''
  owner: string // User ID
  groups: string[]
  consent: {
    state: string
    id: string
  }
  profile: {
    ref: string
    name: string
    avatar: AvatarLayout
    colour: string
    thumbnail: string
    deleted: boolean
  }
  data: Record<string, unknown>

  // Front end control
  selected = false

  constructor(data?: PlayerData | Player) {
    this._id = ''
    this.consent = {
      id: '',
      state: '',
    }
    this.profile = {
      ref: '',
      name: 'unknown ID',
      colour: taskColours[Math.floor(Math.random() * taskColours.length)],
      thumbnail: '',
      avatar: {
        eyeShape: '0',
        eyeColour: '#000000',
        hairShape: '13',
        hairColour: '#010300',
        skinColour: '#EFD5CE',
        noseShape: '2',
        lipColour: '#000000',
        accessories: '13',
        backgroundColour: '#999999',
      },
      deleted: false,
    }
    this.owner = ''
    this.groups = []
    this.data = {}

    if (data) this.update(data)
  }

  getName(data: PlayerData | Player): string {
    if (data.profile.name) return data.profile.name
    else if (data.profile.ref) return data.profile.ref
    else if (data._id) return data._id.substring(0, 6) + '...'
    else return 'unknown ID'
  }

  update(data: PlayerData | Player): void {
    if (data._id) this._id = data._id
    if (data.profile) {
      this.profile.ref = data.profile.ref
      this.profile.thumbnail = data.profile.thumbnail
      if (data.profile.colour) this.profile.colour = data.profile.colour
      this.profile.deleted = !!data.profile.deleted
      this.profile.name = this.getName(data)
      if (data.profile.avatar) {
        this.profile.avatar = {
          eyeShape: data.profile.avatar.eyeShape || '0',
          eyeColour: data.profile.avatar.eyeColour || '#000000',
          hairShape: data.profile.avatar.hairShape || '13',
          hairColour: data.profile.avatar.hairColour || '#010300',
          skinColour: data.profile.avatar.skinColour || '#EFD5CE',
          noseShape: data.profile.avatar.noseShape || '2',
          lipColour: data.profile.avatar.lipColour || '#000000',
          accessories: data.profile.avatar.accessories || '13',
          backgroundColour: data.profile.avatar.backgroundColour || '#999999',
        }
      }
    }
    this.consent.state = data.consent.state
    this.consent.id = data.consent.id
    this.owner = data.owner
    this.groups = data.groups
    if (data.data) this.data = data.data
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    return { _id: this._id, consent: this.consent, profile: this.profile, owner: this.owner, groups: this.groups }
  }
}
export interface GameData {
  _id?: string
  players: string[] // References or populated Player model
  activePlayers: string[] // References or populated Player model currently active
  owner: string
  name: string
  project: {
    id: string
    shuffleTopLevel: boolean
    lastSync: string
    topLevelOrder: string[] // Order of top level item IDs (excluding mastery), as they may be shuffled before first use
  }
  progress: Record<string, ProgressData>
  status: {
    controlActive: boolean // If true, settings in this section will affect the Game (adjust using Monitor)
    redoTasks: boolean // If true, players in the Game can re-do already completed tasks. NOTE: This affects judgement of 'Set completion'
    skipTasks: boolean // If true, the Participant can open tasks that come after the current incomplete task
    allowedSets: string[] // IDs of sets (as strings) Game is allowed to access. Used in combination with 'active'.
    lastAdjustedByAdmin: string | undefined
  }
  sharing: {
    // Deactivated if empty
    groups: string[] // Group IDs
    users: string[] // User IDs
    pinCode: string // Unique PIN to this game.
    url: string // Unique link to this game.
  }
}
export interface SpecialRequestData {
  game: GameData
  data: Record<string, Record<number, { total: number; correct: number }>>
}

export enum SPECIAL_REQUEST_TYPE {
  successresults = 'successresults',
}

export class Game {
  _id: string
  players: string[] // References to Player model
  activePlayers: string[] // References to Players currently active
  owner: string
  name: string
  project: {
    id: string // ID of project
    shuffleTopLevel: boolean
    lastSync: Date
    topLevelOrder: string[] // Order of top level item IDs (excluding mastery), as they may be shuffled before first use
  }
  progress: Map<string, Progress>
  status: {
    controlActive: boolean // If true, settings in this section will affect the Participant (adjust using Monitor)
    redoTasks: boolean // If true, the Participant can re-do already completed tasks. NOTE: This affects judgement of 'Set completion'
    skipTasks: boolean // If true, the Participant can open tasks that come after the current incomplete task
    allowedSets: string[] // IDs of sets (as strings) Participant is allowed to access. Used in combination with 'active'.
    lastAdjustedByAdmin: Date | undefined
  }
  sharing: {
    // Deactivated if empty
    groups: string[] // Group IDs
    users: string[] // User IDs
    pinCode: string // Unique PIN to this game.
    url: string // Unique link to this game.
  }

  // Front end control
  selected = false
  deleted = false

  // PRIVATE member to update progress class attribute
  // using 'private' keyword causes problems with TS compile..
  updateProgress(data: GameData | Game): void {
    this.status.lastAdjustedByAdmin = data.status.lastAdjustedByAdmin ? new Date(data.status.lastAdjustedByAdmin) : undefined
    if (data instanceof Game) this.progress = data.progress
    else {
      for (const pKey in data.progress) {
        if (data.progress[pKey]) {
          const d = data.progress[pKey]
          this.progress.set(pKey, new Progress(d))
        }
      }
    }
  }

  updateData(data: GameData | Game): void {
    if (data._id) this._id = data._id
    this.players = data.players
    this.activePlayers = data.activePlayers
    this.owner = data.owner
    this.name = data.name

    this.status.controlActive = data.status.controlActive
    this.status.allowedSets = data.status.allowedSets
    this.status.lastAdjustedByAdmin = data.status.lastAdjustedByAdmin ? new Date(data.status.lastAdjustedByAdmin) : undefined
    this.status.redoTasks = data.status.redoTasks
    this.status.skipTasks = data.status.skipTasks

    this.project.id = data.project.id
    this.project.shuffleTopLevel = data.project.shuffleTopLevel
    this.project.topLevelOrder = data.project.topLevelOrder
    this.project.lastSync = new Date(data.project.lastSync)

    this.sharing.groups = data.sharing.groups
    this.sharing.users = data.sharing.users
    this.sharing.pinCode = data.sharing.pinCode
    this.sharing.url = data.sharing.url
  }

  constructor(data?: GameData | Game) {
    this._id = ''
    this.players = []
    this.activePlayers = []
    this.owner = ''
    this.name = ''
    this.progress = new Map()
    this.status = {
      controlActive: false,
      allowedSets: [],
      lastAdjustedByAdmin: undefined,
      redoTasks: false,
      skipTasks: false,
    }
    this.project = {
      id: '',
      shuffleTopLevel: true,
      topLevelOrder: [],
      lastSync: new Date(),
    }
    this.sharing = {
      groups: [],
      users: [],
      pinCode: '',
      url: '',
    }
    if (data) {
      this.updateData(data)
      this.updateProgress(data)
    }
  }

  get restrictProgress(): boolean {
    return this.status.controlActive
  }
  get allowRedoTasks(): boolean {
    return this.status.redoTasks
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    const progress: Record<string, ProgressData> = {}
    const progressArray = Array.from(this.progress.entries())
    progressArray.forEach((p) => {
      const [key, prog] = p
      progress[key] = prog.asPOJO()
    })
    return { ...this, progress }
  }

  // Server response or Monitor update
  update(data: GameData | Game): void {
    this.updateProgress(data)
    this.updateData(data)
  }

  // Return the current number of completions for a given item
  // supplied IDs should be the ids of CMS objects
  itemAttempts(itemId: string, parentId: string): number {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.attempts.length
    } else return 0
  }

  // Return the current number of completions for a given item
  // supplied IDs should be the ids of CMS objects
  itemCompletions(itemId: string, parentId: string): number {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.completions.length
    } else return 0
  }

  // Return the 'completed' boolean status for a given item
  itemIsComplete(itemId: string, parentId: string): boolean {
    const id = itemId + (parentId ? ':' + parentId : '')
    return !!(this.progress.has(id) && this.progress.get(id)?.completed)
  }

  // Return the most recent completion date for the given ID
  // itemId & parentId should be the IDs of CMS Sett or Question objects
  latestCompletionDate(itemId: string, parentId: string): Date | undefined {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.latestCompletion
    } else return
  }

  // Return the most recent attempt date for the given item
  // itemId & parentId should be the IDs of CMS Sett or Question objects
  latestAttemptDate(itemId: string, parentId: string): Date | undefined {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.latestAttempt
    } else return
  }

  // Get or create a Progress, and return it
  // itemId: the CMS ID of a Sett or Question
  // returns: Current number of attempts at this item
  createProgress(itemId: string, parentId: string, attempts?: Date[]): Progress {
    let p: Progress
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      p = this.progress.get(id) as Progress
    } else {
      p = new Progress({ itemId, parentId, attempts })
      this.progress.set(id, p)
    }
    return p
  }

  // Get a Progress item and mark as completed
  // itemId: the CMS ID of a Sett or Question
  // returns: Current number of attempts at this item
  completeProgress(itemId: string, parentId: string): number {
    const p = this.createProgress(itemId, parentId)
    return p.complete()
  }
}

export interface UserData {
  _id: string
  status: {
    lastLogin: string
    lastUpdate: string
    browserLanguage: string
    currentProjectId: string
    canEditPlayers: boolean // User can make changes to Players (add, edit, remove)
    canEditGames: boolean // User can make changes to Games
  }
  profile: {
    username: string
    fullName: string
    email: string
    mobil: string
    language: string
    role: string
  }
  // DB IDs of related Models
  groups: GroupData[]
  projects: ProjectData[]
}
export class User {
  _id: string
  status: {
    lastLogin: Date
    lastUpdate: Date
    browserLanguage: string
    currentProjectId: string
    canEditPlayers: boolean // User can make their own Players
    canEditGames: boolean
  }
  profile: {
    username: string
    fullName: string
    email: string
    mobil: string
    language: LanguageNames // Use a two letter code as the browser does
    role: USER_ROLE
  }
  groups: Group[] // Populated during User request
  projects: Project[] // Populated during User request

  constructor(data?: UserData | User) {
    this._id = ''
    this.status = {
      lastLogin: new Date(),
      lastUpdate: new Date(),
      currentProjectId: '',
      browserLanguage: 'no',
      canEditPlayers: false,
      canEditGames: false,
    }
    this.profile = {
      username: 'initial user',
      fullName: 'initial user',
      email: '',
      mobil: '',
      language: LanguageNames.system, // Use a two letter code as the browser does
      role: USER_ROLE.user,
    }
    this.groups = []
    this.projects = []

    if (data) this.update(data)
  }

  public update(data: UserData | User): void {
    this._id = data._id
    this.profile = {
      username: data.profile.username,
      fullName: data.profile.fullName,
      mobil: data.profile.mobil,
      email: data.profile.email,
      language: (data.profile.language as LanguageNames) || LanguageNames.system,
      role: data.profile.role as USER_ROLE,
    }
    this.status = {
      lastLogin: new Date(data.status.lastLogin),
      lastUpdate: new Date(data.status.lastUpdate),
      browserLanguage: data.status.browserLanguage,
      currentProjectId: data.status.currentProjectId,
      canEditPlayers: data.status.canEditPlayers,
      canEditGames: data.status.canEditGames,
    }
    const newGroups: Group[] = []
    data.groups.forEach((group: Group | GroupData) => {
      const g = this.groups.find((gr) => gr._id === group._id)
      if (g) {
        g.update(group)
        newGroups.push(g)
      } else newGroups.push(new Group(group))
    })
    this.groups = newGroups
    const newProjects: Project[] = []
    data.projects.forEach((project: Project | ProjectData) => {
      const p = this.projects.find((pr) => pr._id === project._id)
      if (p) {
        p.update(project)
        newProjects.push(p)
      } else newProjects.push(new Project(project))
    })
    this.projects = newProjects
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    const projects = this.projects.map((p) => p.asPOJO())
    const groups = this.groups.map((g) => g.asPOJO())
    return { ...this, projects, groups }
  }

  // Duration since the last server activity until now (s) based on 'lastLogin'
  get lastActive(): number {
    const startDate = moment(this.status.lastUpdate)
    const endDate = moment()
    return endDate.diff(startDate, 'seconds')
  }
}

// -------------- Other UI Types ---------------

export interface AvatarLayout {
  eyeShape: string
  eyeColour: string
  hairShape: string
  hairColour: string
  skinColour: string
  noseShape: string
  lipColour: string
  accessories: string
  backgroundColour: string
}
// ---------------  API -----------------

enum XHR_REQUEST_TYPE {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

enum XHR_CONTENT_TYPE {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded',
}

// Augment the Error class with message and status
class HttpException extends Error {
  status: number
  message: string
  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }
}

interface APIRequestPayload {
  method: XHR_REQUEST_TYPE
  route: string
  credentials?: boolean
  body?: unknown | string | User | Player | Project | PlayerData | TrackingDetailsData | FormData
  headers?: Record<string, string>
  query?: Record<string, string>
  contentType?: string
  baseURL?: string
}

interface XHRPayload {
  url: string
  headers: Record<string, string>
  credentials: boolean
  body: string | FormData
  method: XHR_REQUEST_TYPE
}

export { XHR_REQUEST_TYPE, APIRequestPayload, XHRPayload, HttpException, XHR_CONTENT_TYPE }
