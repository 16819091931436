
import { defineComponent } from 'vue'
import { useAppStore } from '../../store/useAppStore'
import { useUserStore } from '../../store/useUserStore'
import MonitorGroupItem from '@/components/admin/MonitorGroupItem.vue'
import SlButton from '@/components/base/SlButton.vue'

export default defineComponent({
  name: 'MonitorProjects',
  components: {
    MonitorGroupItem,
    SlButton,
  },
  setup() {
    const { getters: appGetters } = useAppStore()
    const { getters: userGetters, actions: userActions } = useUserStore()

    const addGroup = () => {
      userActions.createGroup()
    }

    userActions.getAllGroups()

    return {
      // Computed
      status: appGetters.status,
      groups: userGetters.allGroups,

      // Methods
      addGroup,
    }
  },
})
