
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { fetchToken } from '@/api/cmsService'
import { LanguageCodes, USER_ROLE } from '@/constants'
import { LocalUser } from '@/types/main'
import { useAppStore } from '@/store/useAppStore'
import { useUserStore } from '@/store/useUserStore'
import { useProjectStore } from '@/store/useProjectStore'
import { useGameStore } from '@/store/useGameStore'
import { useDeviceService } from '@/composition/useDevice'
import SlButton from '@/components/base/SlButton.vue'

// This component completes setup of the app after login
export default defineComponent({
  name: 'AfterLogin',
  components: {
    SlButton,
  },
  setup() {
    const { locale } = useI18n({ useScope: 'global' })
    const { getters: appGetters, actions: appActions } = useAppStore()
    const { actions: projectActions } = useProjectStore()
    const { actions: gameActions } = useGameStore()
    const { getters: userGetters, actions: userActions } = useUserStore()
    const { getters: deviceGetters, actions: deviceActions } = useDeviceService()

    const isMobileDevice: boolean = deviceGetters.deviceReady.value
    const isOnline: boolean = deviceGetters.deviceOnline.value
    const currentLocalUser: LocalUser | undefined = appGetters.currentLocalUser.value

    // This should re-calculate when the user is loaded
    const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))

    // We have just fulfilled Sync sequence Stage A - Login. Moving on to the next sync steps..
    // NOTE: Refer to docs/syncing.md

    // Sync sequence Stage B. Load Data from server and/or disk
    const getData = async (): Promise<void> => {
      if (isMobileDevice && !isOnline && currentLocalUser) {
        userActions.setCordovaPath(currentLocalUser._id)
        await userActions.loadData() // Load user, project and players from local disk
      } else {
        // Load user from server, select it (including user.participants) then save it locally
        await userActions.getMyUser()
        const user = userGetters.myUser.value
        projectActions.clearProjects()
        user.projects.forEach((p) => projectActions.addProject(p))
        await userActions.saveData()
      }

      // Setup language - default to system language
      const myUser = userGetters.myUser.value
      const languageCode = myUser.profile.language
      if (languageCode !== 'system') locale.value = languageCode as string
      appActions.setLanguageCode(locale.value as LanguageCodes)

      // Mobile device storage path. Must be set before requesting games, trackings etc.
      gameActions.setCordovaPath(myUser._id)

      // Get and Set the user's current Project from User store to where it's needed in other areas
      const project = userGetters.selectedUserProject.value
      if (project) {
        projectActions.selectProject(project)
        projectActions.overrideCMS() // Ensure we refresh CMS content upon login
        fetchToken(project).then((newToken) => {
          console.log('Fetched new CMS token')
          localStorage.setItem('squidex-token', newToken)
        })
      }

      // Load the Games & Players for this user
      // 1. From server
      // 2. From disk (replacing any matches from server)
      await gameActions.getPlayers()
      await gameActions.loadPlayers() // If players have been removed server-side, this could add old incorrect Players back again..
      // Stage F. sync should remove a Player if it doesn't exist on server
      await gameActions.getGames()
      // Games must be loaded before Trackings as Trackings are stored in 'user/userID/games/gameID/trackings.json'
      await gameActions.loadGames() // If games have been removed server-side or User was removed from a Group, incorrect Games may be loaded here..
      // Stage F. sync should remove a Game if it doesn't exist on server
      await gameActions.loadTrackings()

      return Promise.resolve()
    }

    // Synce sequence Stage E. and F. Post Trackings and Update Games
    // updateGameProgress() also saves to disk Stage D.
    const updateGames = (): Promise<void> => {
      // We don't need to wait for trackings post to complete, just let in run
      gameActions.sendTrackings()
      if (isOnline && isMobileDevice) {
        // Synchronise any locally stored Game data with the server
        return gameActions.updateGameProgress(true)
      } else return Promise.resolve()
    }

    getData()
      .then(async () => {
        await updateGames()
        await deviceActions.loadMediaCache()
        router.push('/dashboard/games')
      })
      .catch(() => router.push('/'))

    return {
      // Computed
      status: appGetters.status,
      user: userGetters.selectedUser,
      hasMonitorRole,

      // Methods
      monitor: () => router.push('/monitor'),
    }
  },
})
