
import { defineComponent, inject, watch, ref } from 'vue'

interface CarouselNav {
  slideTo: () => void
  next: () => void
  prev: () => void
}

// Place this component as a child to the Carousel component
// call next(), prev() and slideTo() from the parent to change slides
export default defineComponent({
  name: 'CarouselTrigger', // Type name should be lower case! Squidex does not allow uppercase for "schemaName"
  emits: ['new-page'],
  setup(props, context) {
    const nav: CarouselNav = inject('nav', {} as CarouselNav)
    const currentSlide = inject('currentSlide', ref(1))

    watch(
      () => currentSlide.value,
      (newIndex) => {
        context.emit('new-page', newIndex)
      },
    )

    let next = nav.next
    let prev = nav.prev
    let slideTo = nav.slideTo

    return {
      next,
      prev,
      slideTo,
    }
  },
})
