import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "relative flex flex-col justify-center items-center" }
const _hoisted_2 = { class: "flex flex-row justify-center rounded-full p-2 mt-12 z-10" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "rounded-full filter drop-shadow-centered w-36 h-36",
        style: {"background-color":"#fdfae6"},
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click(_ctx.Thumbs.down)))
      }, [
        (_ctx.answer !== _ctx.Thumbs.down)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.no,
              alt: "glasses image",
              class: "w-36 p-5"
            }, null, 8 /* PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", {
        class: "rounded-full filter drop-shadow-centered ml-4 w-36 h-36",
        style: {"background-color":"#fdfae6"},
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.click(_ctx.Thumbs.up)))
      }, [
        (_ctx.answer !== _ctx.Thumbs.up)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.imageByCode,
              alt: "thumbs up image",
              class: "w-36 p-5"
            }, null, 8 /* PROPS */, _hoisted_4))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}