/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { reactive } from 'vue'
import {
  TrackingDetails,
  TrackingDetailsData,
  TrackingSearch,
  APIRequestPayload,
  XHR_REQUEST_TYPE,
  XHR_CONTENT_TYPE,
  Game,
  GameData,
  User,
  SpecialRequestData,
  SPECIAL_REQUEST_TYPE,
  TRACKING_TYPE,
  Player,
  PlayerData,
  //XHR_CONTENT_TYPE,
} from '@/types/main'
import { apiRequest } from '../api/apiRequest'
import { useDeviceService, CordovaOptions, CordovaPathName, CordovaDataType } from '../composition/useDevice'
import { ref, Ref, computed, ComputedRef } from 'vue'
const { actions: deviceActions, getters: deviceGetters } = useDeviceService()

// A list of IDs that should be resent once
const recoverTrackingIDs = [
  'd0c35d8c-9d87-46f9-3fa3-039c4c0fe31e',
  '64014a13-c7d2-49a9-0ba9-a6478ff2a428',
  '0043c7e1-c2d3-4cd8-362a-efc547a9285a',
  '7d4d976e-4716-4770-0dd1-2d5824f78544',
  '14da1c93-a8bf-41ec-0f8a-0daf4a04914c',
  '4a700360-3a8e-46f7-04ba-851e4547cda3',
  '7f4e5f0c-3d06-46dc-2716-60b6a9edd515',
  '43a22344-cd87-4c54-3bf8-1acd15f2935b',
  '46af4251-6868-42ed-1eaa-b4a09a76d039',
  '711a432b-4676-4e09-2786-9ea86db94f67',
  '31988716-76b7-43a7-20f0-079fa663a6ee',
  '8171d382-35a5-4f5b-1f9b-8e66d051caf5',
  'e2451bc7-bcf4-48f1-0186-dbfb176d5a20',
  '70b59f21-e9b8-40ff-2401-9a1d3d1931a1',
  'ade79564-c3ba-49f7-0bf1-4b077a6d30fa',
  '5873f496-f9d6-42cf-021e-7c9bdce90513',
  'ac69fe6a-293a-4a77-3296-be34be486938',
  'cc645e10-769a-4167-35e7-4c428ea865bc',
  '7604380e-41d5-4acb-29f2-3d0b976d84af',
  '4db78b5b-08bf-4c03-33c5-2cf8f40ec8d5',
  '01578964-abb4-4178-0771-a71778fcadc9',
  '6b5047b9-b0e9-4822-1fc2-fbea0f337c18',
  '6c290d05-4d9b-43eb-205f-66eb8dd24452',
  '8493da90-b4b9-44bb-27e7-1d49fe3a25ca',
  '0dc1aaa7-877b-4821-064a-ba6379c4d019',
  '22f73d98-41a2-4bbd-0e5a-3ccca8c9ab49',
  'a0714002-0068-468a-0e9a-6ba95fcdc9b8',
  '4a2ced63-555f-49d0-0a21-22cd40ee7d30',
  '46053bec-f507-4a26-0cbf-51f1cd923925',
  '347cb40e-366c-47d1-1f7d-9ae856d9564b',
  'f613fe81-c9ab-41f5-0aef-927417c1805a',
  '75dcf6aa-b34e-4952-1c28-e8e34f659adb',
  'e9fe3a33-595c-46cd-1809-dc7c46136b15',
  '347e3c03-5b34-43c7-10a3-99b897278ca9',
  '25f319f7-0d94-40d5-2253-1c956552da18',
  '8fc8469f-41fb-4d5a-1d4c-68d93948ed1e',
  'ced2cd1f-d38d-4ddd-24bd-526e64b4b29e',
  '30010d89-0e63-4a0a-25a9-51813dd4f141',
  '33e33c1a-e3de-4651-1689-e0ba3b4aa4d5',
  '676b1c12-23eb-44fe-27e7-ea5b252710f8',
  '6e6553bd-e150-4769-1255-b54ccf400605',
  '04e7e3df-f17b-4178-1b18-32e98a0b2eb8',
  'ecb8e791-5525-4f91-221d-bde3a1d8e68f',
  '3f603444-fa72-4bf2-0b1b-e7ebab4ea6bf',
  '386d2cfb-84c9-49e3-0487-c7385068b2d2',
  'f10fc202-071f-4e3d-3ebb-3ff337c0588c',
  '45c21561-7de1-481d-00d9-90ce2ad5215b',
  'b0afc53b-93e1-4e89-2f74-614b80c2eac5',
  'ce005677-ec68-472b-1c3d-107a1fdb1073',
  '87c278f0-d898-4309-1642-f449e06d5eac',
  '19dae3ca-5d92-4407-0c3d-80fcb5f514e0',
  'a9d9d4dd-8a8a-4a6f-1f39-c58f295d046c',
  'a9d9d4dd-8a8a-4a6f-1f39-c58f295d046c',
  'a9d9d4dd-8a8a-4a6f-1f39-c58f295d046c',
  'a9d9d4dd-8a8a-4a6f-1f39-c58f295d046c',
  'a9d9d4dd-8a8a-4a6f-1f39-c58f295d046c',
  'b99fc4ab-09fe-4d8b-02cb-d5a57ad3ef89',
  'b99fc4ab-09fe-4d8b-02cb-d5a57ad3ef89',
  'b99fc4ab-09fe-4d8b-02cb-d5a57ad3ef89',
  'cb10960f-3136-4e05-28b9-b48de9540e45',
  'cb10960f-3136-4e05-28b9-b48de9540e45',
  'b3cdf939-98d7-4a2e-3b42-af477c13c9e6',
  'b3cdf939-98d7-4a2e-3b42-af477c13c9e6',
  '41092673-43b3-4980-196a-8cb30c554f21',
  '41092673-43b3-4980-196a-8cb30c554f21',
  '09c70072-0d2c-46a4-2242-43ce3dbbe809',
  '09c70072-0d2c-46a4-2242-43ce3dbbe809',
  '3e19e123-563b-4c8c-07c7-9db5f7549dd5',
  'b6c7d605-799f-45c1-238d-b09ad2a03386',
  'b65ceffc-7c63-46bb-0a89-b91b9e2d1443',
  '60e8ea57-484b-4caa-3cef-473454201236',
  '209a7063-e1e0-4ea9-1867-c0b06a3b3baa',
  '2b6be664-3624-429b-3a03-0083f34b0920',
  '040d7935-cf4e-42e7-2b0e-d256b5b26d6b',
  '3b6582ea-8365-4232-2a1b-313a4fa0c27c',
  '2deaa224-3e2b-4754-1e39-1d8c56241123',
  'c7de45da-2a24-47c7-104f-f177f82d9a1e',
  '6f13787e-54a3-45ec-152a-d8bce4b3a60a',
  'db2633f4-f420-41dc-028b-c56659aa71b7',
  '57c64ae8-f593-4146-1d67-48176ce4656a',
  '68616f07-0b22-4db7-34be-97c00dcf2063',
  '1f5ca68c-1d16-4c6a-3773-817e2e6919a6',
  '19eecfff-68c3-4354-02d2-d8a3d50cc7a7',
  '0550515a-57d7-43c7-0102-6a4612d2398d',
  '3513b722-6896-47a5-33e3-ff4e337d3bca',
  '0de67d22-92ee-47a6-0413-80980a883584',
  '2856e20a-f3e3-43bb-3340-07c06ee53eae',
  'b94b1658-1474-453b-1689-e169028b48b8',
  '0bd6145b-b687-49e5-08c4-cc5c8b6c7f26',
  '22e0d18f-a9a0-4f68-3b70-82b4f4c9ab9d',
  '2cc26a92-137e-41ae-1cd1-db46bb5b5770',
  'fcb49690-2f22-4acd-173c-c3e9412542e7',
  'e0534416-dd10-4140-2244-771844753f46',
  '0e28b7bc-3e8d-416f-07b8-c727ffb6c806',
  '7d647b83-e7de-48f4-2cb9-4e1c8b708a1e',
  '22e416fd-7fa7-4120-1628-1f322bcf52af',
  'a645128a-9871-4b38-0527-b25c5c62c73c',
  '76db50d1-9b87-4644-26d1-4c8b107c91a5',
  '7004ca98-2dbb-4105-1e2d-8159799cff9b',
  '41e776dd-afbb-4108-196b-fa9ace0488c7',
  '039740e8-1c32-4d31-2b4e-3ca4db192b92',
  '9f6c370b-4895-438f-0d03-9d07e874c7a1',
  'e7552b05-6a4b-4ba0-108f-65bc094a1b49',
  '2dc1baa9-32a8-402f-2402-47d3d489f216',
  '676d8b61-5639-4f5b-350c-c67b26d8d385',
  '036368ed-5b69-4250-28aa-68f57b03bcd0',
  '8244c2a8-965c-4dda-0913-a65236a09544',
  '8920eb6c-0622-4c96-1a55-cee485f862d5',
  'f2cde265-bd52-40ea-39cb-21823d4327d6',
  '736ac597-2ac3-42eb-19f4-30bb5ba0522d',
  '0d292b42-4900-429f-3f8a-e6813c925704',
  '6b00e518-fe9a-485c-26d8-6e4a8ccb3a55',
  'd7c52431-aae9-4bbd-2f75-eb5eb7a7aae7',
  'c724a75d-2c3e-4f58-1eb0-52e44eefa36e',
  'e9f93de7-65fc-40d6-2783-a4b8a6a02750',
  '6b1ad051-44ac-4df6-2449-bc0132ee1a3c',
  '24561264-c32f-4a10-0f76-dd82b65be28a',
  '638e4d7e-f244-4c6e-0d53-e9eedd947bc8',
  '5ba77148-15ea-447e-2d9e-672b579e7990',
  '1bfa6cf9-327f-4b1f-2fe6-6db9fbc67d67',
  '8bd985e2-4564-490a-0c8b-93b4db5cd86c',
  'a9f356c5-56cd-4aff-2bdb-e7a66c4bffed',
  'a206aa1c-7a78-43b0-24ad-43b5c85c240a',
  '26dc1d75-a667-4b40-279a-848b1315be07',
  'ec481492-d571-4453-04b0-0bd99ccccb16',
]

// ------------  State (internal) --------------
interface State {
  games: Map<string, Game>
  selectedGame?: Game
  trackings: Map<string, TrackingDetails>
  selectedLocation: string
  locations: string[]
  players: Player[] // ALl players found for the current user
  selectedPlayer?: Player
  cordovaPath: string[] // This hsould refer to 'users/userID/
  allTrackings: TrackingDetails[]
}

const state: Ref<State> = ref({
  games: new Map(), // The list of Participants for a User
  selectedGame: undefined, // The currently selected individual Game. Also needed for Avatar editing etc.
  trackings: new Map(),
  locations: [],
  selectedLocation: '',
  players: [], // ALl players found for the current user
  selectedPlayer: undefined,
  cordovaPath: [],
  allTrackings: [],
})

// ------------  Server-side data ------------

// Syncronise Progress of Games with the server
async function updateGamesProgress(games: Game[]): Promise<void> {
  while (games.length > 0) {
    const g = games.pop()
    if (g) {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        route: '/api/game/progress',
        body: g.asPOJO(),
      }
      let gameData
      try {
        gameData = await apiRequest<GameData>(payload)
      } catch (error: unknown) {
        console.log(`Error syncing game: ${g._id}: ${error ? (error as Error).toString() : ''}`)
      }
      // After updating at server, update locally
      const localG = state.value.games.get(g._id)
      // If the server's copy of the Game has changed, the local Game is updated here
      if (localG && gameData) {
        localG.update(gameData)
        // Ensure 'active players' match the available players
        const newActivePlayers = localG.activePlayers.filter((ap) => localG.players.includes(ap))
        localG.activePlayers = [...newActivePlayers]
      }
      // If a local game exists, but not at server, it was deleted at server
      else if (localG) localG.deleted = true
    }
  }
  return Promise.resolve()
}

// Get a Game's Progress and Trackings from server
// id: Game ID
// trackingtype: Type of tracking to filter for (if needed)
async function fetchGameDetails(id: string): Promise<GameData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/game/details',
    query: { id },
  }
  return apiRequest<GameData>(payload)
}

async function fetchTrackingDetails(): Promise<TrackingDetailsData[]> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/trackings',
    query: {},
  }
  return apiRequest<TrackingDetailsData[]>(payload)
}
async function fetchSpecialRequest(gameID: string, requestType: SPECIAL_REQUEST_TYPE, trackingType: TRACKING_TYPE): Promise<SpecialRequestData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/tracking/special',
    query: { gameID, requestType, trackingType },
  }
  return apiRequest<SpecialRequestData>(payload)
}

function loadTrackingAttachment(path: string[], fileName: string): Promise<BlobPart | void> {
  const cd: CordovaOptions = new CordovaOptions({
    fileName,
    dataType: CordovaDataType.arrayBuffer,
    path,
  })
  return deviceActions.loadFromStorage<BlobPart>(cd).then((blob) => blob)
}

async function syncTracking(tracking: TrackingDetails, resend: boolean) {
  const path = [...state.value.cordovaPath, CordovaPathName.games, tracking.gameID]
  let trackingAudioFile
  let trackingVideoFile
  if (tracking.audioFile) trackingAudioFile = await loadTrackingAttachment(path, tracking.audioFile)
  if (tracking.videoFile) trackingVideoFile = await loadTrackingAttachment(path, tracking.videoFile)

  const formData = new FormData()

  const data = JSON.stringify(tracking.asPOJO())
  formData.append('data', data)

  if (trackingAudioFile) {
    const blob = new Blob([trackingAudioFile], { type: 'audio/mp4' })
    formData.append('audio', blob)
  }
  if (trackingVideoFile) {
    const blob = new Blob([trackingVideoFile], { type: 'video/mp4' })
    formData.append('video', blob)
  }

  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: '/api/tracking',
    body: formData,
    contentType: XHR_CONTENT_TYPE.MULTIPART,
  }

  // Wait for the request to return and see that it succeeded
  try {
    const trackingData = await apiRequest<TrackingDetails>(payload)
    if (trackingData) {
      tracking.serverSynced = !!trackingData.serverSynced
      tracking.storageSynced = !!trackingData.storageSynced
      if (trackingData.audioFileSize !== undefined) tracking.audioFileSize = trackingData.audioFileSize
      if (resend) tracking.resent = trackingData.resent
    } else console.log(`TrackingDetails POST failed! Tracking ID: ${tracking.itemID}`)
  } catch (error: unknown) {
    console.log(`Error posting tracking data: ${error}`)
  }
}

// Sent currently loaded Trackings to the server if not already sent
// We can only send trackings owned by the current user, as we must know the Game IDs
async function sendTrackings(): Promise<void> {
  if (!deviceGetters.deviceOnline.value) return Promise.resolve()

  // Iterate over all tracking selecting those that are not marked 'serverSynced'
  const it = state.value.trackings.values()
  let t = it.next()

  // Using async-await, multiple tracking posts should be sent in series
  while (!t.done) {
    const tracking: TrackingDetails = t.value
    // Determine if we need to re-send this tracking (e.g. if audio was not received)
    const resend = tracking.resent === 0 && recoverTrackingIDs.includes(tracking.oid)
    if (!tracking.serverSynced || resend) await syncTracking(tracking, resend)
    t = it.next()
  }
  return actions.saveTrackings()
}

// ------------  Getters (Read only / Immutable)! --------------
interface Getters {
  selectedGame: ComputedRef<Game | undefined>
  selectedLocation: string
  locations: string[]
  games: ComputedRef<Game[]>
  players: ComputedRef<Player[]> // All players owned by the current User, or in the User's Group
  selectedPlayer: ComputedRef<Player | undefined> // Currently selected Player
  playersInSelectedGame: ComputedRef<Player[]> // All active players in the current game
  allTrackings: ComputedRef<TrackingDetails[]>
}
const getters = {
  get allTrackings(): ComputedRef<TrackingDetails[]> {
    return computed(() => state.value.allTrackings)
  },
  get selectedGame(): ComputedRef<Game | undefined> {
    return computed(() => state.value.selectedGame)
  },
  get selectedLocation(): string {
    return ref(state.value.selectedLocation).value
  },
  get locations(): string[] {
    return reactive(state.value.locations)
  },
  get games(): ComputedRef<Game[]> {
    const g = state.value.games.values()
    return computed(() => Array.from(g).filter((ga) => !ga.deleted))
  },
  get players(): ComputedRef<Player[]> {
    return computed(() => state.value.players) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get selectedPlayer(): ComputedRef<Player | undefined> {
    return computed(() => state.value.selectedPlayer) // This is the 'currently selected' user and can change, must change by calling User.update()
  },
  get playersInSelectedGame(): ComputedRef<Player[]> {
    return computed(() => {
      const g = state.value.selectedGame ?? new Game()
      return state.value.players.filter((p) => g.activePlayers.includes(p._id))
    })
  },
}
// ------------  Actions --------------
interface Actions {
  selectGame: (game?: Game) => void
  selectPlayer: (player?: Player) => void
  setGames: (games: Game[]) => void
  setPlayers: (players: Player[]) => void
  completeProgressForItem: (itemId: string, parentId: string) => number
  registerAttemptForItem: (itemId: string, parentId: string) => void
  /*   findTrackingByData: (
    dataKey: string,
    dataValue: string | number | boolean
  ) => TrackingDetails | undefined */
  commitNewTracking: (tracking: TrackingDetails) => void
  findMatchingTrackings: (search: TrackingSearch) => TrackingDetails[] // Find a unique TrackingDetails based on given search params
  getCompletedChildren: (parentId: string) => string[]
  getPlayers: (groupid?: string, userid?: string) => Promise<void>
  updatePlayer: (player: Player) => Promise<void>
  setCordovaPath: (userID: string) => void

  // Server
  getGames: (groupId?: string, userId?: string) => Promise<void>
  getGameDetails: (id: string) => Promise<Game>
  getSpecialRequest: (gameID: string, requestType: SPECIAL_REQUEST_TYPE, trackingType: TRACKING_TYPE) => Promise<SpecialRequestData>
  getLocations: () => Promise<void>
  updateGame: (p: Game) => Promise<void>
  updateGameProgress: (updateAll?: boolean, game?: Game) => Promise<void>
  updateGameControl: (p: Game) => Promise<void>
  deleteGame: (g: Game) => Promise<void>
  sendTrackings: () => Promise<void>
  addGame: (user?: User) => Promise<Game>
  addPlayer: (u?: User) => Promise<Player>
  getAllTrackingDetails: () => Promise<TrackingDetailsData[]>

  // Disk
  loadGames: () => Promise<void>
  saveGames: () => Promise<void>
  loadPlayers: () => Promise<void>
  savePlayers: () => Promise<void>
  loadTrackings: () => Promise<void>
  saveTrackings: () => Promise<void>
}

const actions = {
  // Select a given Game
  // If no Game is supplied, un-select all Games
  selectGame: function (game: Game | undefined): void {
    if (!game) {
      state.value.games.forEach((g) => (g.selected = false))
      state.value.selectedGame = undefined
    } else {
      if (state.value.selectedGame) state.value.selectedGame.selected = false
      game.selected = true
      state.value.selectedGame = game
      // Place file data (e.g. recordings) for this Game inside: games/<gameID>/
      deviceActions.setCordovaPath([...state.value.cordovaPath, CordovaPathName.games, game._id])
    }
  },
  // Select a given player
  // If no player is supplied, un-select all players
  selectPlayer: function (player: Player | undefined): void {
    if (!player) {
      state.value.players.forEach((p) => (p.selected = false))
      state.value.selectedPlayer = undefined
    } else {
      if (state.value.selectedPlayer) state.value.selectedPlayer.selected = false
      player.selected = true
      state.value.selectedPlayer = player
    }
  },
  // Replace the current list of games with another
  setGames: function (games: Game[]): void {
    state.value.games.clear()
    games.forEach((p: Game) => {
      state.value.games.set(p._id, p)
    })
  },
  // Replace the current list of players with another
  setPlayers: function (players: Player[]): void {
    state.value.players.length = 0
    players.forEach((p: Player) => state.value.players.push(p))
  },
  setLocations(locations: string[]): void {
    state.value.locations.splice(0)
    Object.values(locations).forEach((l) => state.value.locations.push(l))
  },
  setCordovaPath: function (userID: string): void {
    state.value.cordovaPath = [CordovaPathName.users, userID]
  },
  // Complete this item and return current number of completions for this item
  completeProgressForItem: function (itemId: string, parentId: string): number {
    let completions = 0
    if (state.value.selectedGame) completions = state.value.selectedGame.completeProgress(itemId, parentId)
    return completions
  },
  registerAttemptForItem: function (itemId: string, parentId: string): void {
    const attemptDate = new Date()
    if (state.value.selectedGame) state.value.selectedGame.createProgress(itemId, parentId, [attemptDate])
  },
  // Get Details for the currently selected Game
  // Sets them in the store and also returns them to the component
  getGameDetails: async function (id: string): Promise<Game> {
    const response: GameData = await fetchGameDetails(id)
    // This includes Progress information
    return new Game(response)
  },

  // Get all tracking details from Database
  getAllTrackingDetails: async function (): Promise<TrackingDetails[]> {
    const response: TrackingDetailsData[] = await fetchTrackingDetails()
    if (response.length != 0) state.value.allTrackings = response.map((td) => new TrackingDetails(td))
    return state.value.allTrackings
  },

  // Call for specical response data for use in mastery / visuals / ets
  getSpecialRequest: async function (gameID: string, requestType: SPECIAL_REQUEST_TYPE, trackingType: TRACKING_TYPE): Promise<SpecialRequestData> {
    const response: SpecialRequestData = await fetchSpecialRequest(gameID, requestType, trackingType)
    return { game: response.game, data: response.data }
  },
  // Add a new TrackingDetails for this game
  commitNewTracking: function (tracking: TrackingDetails): void {
    state.value.trackings.set(tracking.oid, tracking)
  },
  // For a given Set parent ID, get the IDs of completed children
  getCompletedChildren: function (parentId: string): string[] {
    if (state.value.selectedGame) {
      return Array.from(state.value.selectedGame.progress.values())
        .filter((p) => p.parentId === parentId)
        .map((p) => p.itemId)
    } else return []
  },

  // -------------   Server activities -----------------

  // Add a Player to a User
  // If not the logged in User, then User must be an 'admin'
  async addPlayer(u?: User): Promise<Player> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.POST,
      credentials: true,
      route: '/api/player',
      body: u ? u.asPOJO() : {},
    }
    const pData: PlayerData = await apiRequest<PlayerData>(payload)
    const newP = new Player(pData)
    state.value.players.push(newP)
    return Promise.resolve(newP)
  },

  // Retrieve User's Players or Players for a given user / group (if approved as admin)
  getPlayers: async function (
    groupid?: string, // Supply the ID of the group
    userid?: string,
  ): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      query: {},
      route: '/api/players',
    }
    // If both are left empty and we are admin, request will return all players
    if (userid && payload.query) payload.query.userid = userid
    if (groupid && payload.query) payload.query.group = groupid
    return apiRequest<PlayerData[]>(payload).then((response: PlayerData[]) => {
      const ps: Player[] = response.map((p) => new Player(p))
      actions.setPlayers(ps)
    })
  },
  // Update a given user at server, and locally if it exists in allUsers
  updatePlayer: async function (player: Player): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.PUT,
      credentials: true,
      route: '/api/player',
      body: player,
    }
    return apiRequest(payload).then(() => {
      // Also update the user in local list
      const modifiedPlayer = state.value.players.find((p) => p._id === player._id)
      if (modifiedPlayer) modifiedPlayer.update(player)
    })
  },
  // Retrieve this user's Games from server
  // This call DOES NOT include their Progress data
  // For a particular Game's Progress data use fetchGameDetails()
  async getGames(
    groupId?: string, // Supply the ID of the group
    userId?: string,
  ): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      query: {},
      route: '/api/games/list',
    }
    if (userId && payload.query) payload.query.userId = userId
    if (groupId && payload.query) payload.query.groupId = groupId
    return apiRequest<GameData[]>(payload).then((response: GameData[]) => {
      const gs: Game[] = response.map((g) => new Game(g))
      actions.setGames(gs)
    })
  },

  // Fetch the current list of locations
  getLocations(): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      route: '/api/game/locations',
    }
    return apiRequest<string[]>(payload).then((response: string[]) => actions.setLocations(response))
  },

  // Update Game at the server (Not including Mastery or Progress)
  async updateGame(p: Game): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.PUT,
      credentials: true,
      route: '/api/game',
      body: p.asPOJO(),
    }
    let gameData
    try {
      gameData = await apiRequest<GameData>(payload)
    } catch (error: unknown) {
      console.log(`Error updating participant Mastery details: ${error}`)
    }
    // After updating at server, update locally
    const localP = state.value.games.get(p._id)
    if (localP && gameData) localP.update(gameData)
    return Promise.resolve()
  },

  // Save updated data to server and disk including synchronising Progress
  // Given game, selected game, or all Games if updateAll == true
  // After the server response, local game is saved by saveGames()
  updateGameProgress(updateAll = false, game?: Game): Promise<void> {
    let ps: Game[] = []
    if (updateAll) ps = Array.from(state.value.games.values())
    else if (game) ps.push(game)
    else if (state.value.selectedGame) ps.push(state.value.selectedGame)
    if (deviceGetters.deviceOnline.value) {
      return updateGamesProgress(ps).then(() => this.saveGames())
    }
    return Promise.resolve()
  },

  // Update Mastery details ONLY for a Game at the server
  async updateGameControl(p: Game): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.PUT,
      credentials: true,
      route: '/api/game/control',
      body: p.asPOJO(),
    }
    let gameData
    try {
      gameData = await apiRequest<GameData>(payload)
    } catch (error: unknown) {
      console.log(`Error updating participant Mastery details: ${error}`)
    }
    // After updating at server, update locally
    const localP = state.value.games.get(p._id)
    if (localP && gameData) localP.update(gameData)
    return Promise.resolve()
  },

  // Delete a Game at the server
  async deleteGame(g: Game): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.DELETE,
      credentials: true,
      route: '/api/game',
      query: {
        gameId: g._id,
      },
    }
    try {
      await apiRequest<GameData>(payload)
      state.value.games.delete(g._id)
      if (state.value.selectedGame && state.value.selectedGame._id === g._id) state.value.selectedGame = undefined
    } catch (error: unknown) {
      console.log(`Error deleting Game: ${error}`)
    }
    return Promise.resolve()
  },

  async addGame(user?: User): Promise<Game> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.POST,
      credentials: true,
      route: '/api/game',
    }
    if (user) payload.body = user.asPOJO()
    const gData: GameData = await apiRequest<GameData>(payload)
    const newG = new Game(gData)
    state.value.games.set(newG._id, newG)
    return Promise.resolve(newG)
  },

  sendTrackings,

  // -------------   Disk activities -----------------

  // Load participant JSON files under the current user's directory
  loadPlayers: async function (): Promise<void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName: 'players.json',
      path: state.value.cordovaPath,
      dataType: CordovaDataType.text,
      json: true,
    })

    deviceActions.loadFromStorage<PlayerData[]>(cd).then((data) => {
      if (data) {
        state.value.players.length = 0
        data.forEach((player) => {
          const p = new Player(player)
          state.value.players.push(p)
        })
      }
    })
    return Promise.resolve()
  },
  savePlayers: async function (): Promise<void> {
    // Collect Players to be saved
    const ps: Player[] = Array.from(state.value.players.values())
    const data = ps.map((p) => p.asPOJO())
    // Save each Player to its own subdirectory
    const cd: CordovaOptions = new CordovaOptions({
      fileName: 'players.json',
      data,
      dataType: CordovaDataType.text,
      json: true,
      path: state.value.cordovaPath,
    })
    await deviceActions.saveToStorage(cd)
    return Promise.resolve()
  },

  // Load participant JSON files based on Game IDs stored in User model
  loadGames: async function (): Promise<void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName: 'games.json',
      path: state.value.cordovaPath,
      dataType: CordovaDataType.text,
      json: true,
    })

    deviceActions.loadFromStorage<GameData[]>(cd).then((data) => {
      if (data) {
        data.forEach((game) => {
          const g = new Game(game)
          // Overwrite any matching server-downloaded Participants
          // Intending to sync with server properly in next stage
          state.value.games.set(g._id, g)
        })
      }
    })
    return Promise.resolve()
  },
  // Games will be saved in a single JSON file under the user's directory e.g. 'users/userID/games.json'
  saveGames: async function (): Promise<void> {
    // Collect Participants to be saved as regular objects
    const gs: Game[] = Array.from(state.value.games.values())
    const data = gs.map((g) => g.asPOJO())
    // Save each Player to its own subdirectory
    const cd: CordovaOptions = new CordovaOptions({
      fileName: 'games.json',
      data,
      dataType: CordovaDataType.text,
      json: true,
      path: state.value.cordovaPath,
    })
    await deviceActions.saveToStorage(cd)
    return Promise.resolve()
  },
  // Load tracking from each Game folder owned by this User, merge them into the store
  loadTrackings: async function (): Promise<void> {
    const cd: CordovaOptions = new CordovaOptions({
      fileName: 'trackings.json',
      dataType: CordovaDataType.text,
      json: true,
    })
    state.value.trackings.clear()
    const games: Game[] = Array.from(state.value.games.values())
    for (const g of games) {
      cd.path = [...state.value.cordovaPath, CordovaPathName.games, g._id]
      const data = await deviceActions.loadFromStorage<TrackingDetailsData[]>(cd)
      if (data) {
        data.forEach((tracking) => {
          if (tracking.oid) state.value.trackings.set(tracking.oid, new TrackingDetails(tracking))
        })
      }
    }
    return Promise.resolve()
  },
  // Trackings are saved under each game directory e.g. 'users/userID/games/gameID/trackings.json'
  saveTrackings: async function (): Promise<void> {
    // Convert Map to Object keyed by Game ID
    const trackingsByGame: Record<string, unknown[]> = {}
    state.value.trackings.forEach((t) => {
      if (!trackingsByGame[t.gameID]) trackingsByGame[t.gameID] = []
      trackingsByGame[t.gameID].push(t.asPOJO())
    })
    for (const [gID, trackings] of Object.entries(trackingsByGame)) {
      const cd: CordovaOptions = new CordovaOptions({
        fileName: 'trackings.json',
        data: trackings,
        dataType: CordovaDataType.text,
        json: true,
        path: [...state.value.cordovaPath, CordovaPathName.games, gID],
      })
      await deviceActions.saveToStorage(cd)
    }
  },

  findMatchingTrackings: function (search: TrackingSearch): TrackingDetails[] {
    const tArray = Array.from(state.value.trackings.values())
    return tArray
      .sort((a, b) => b.created.getTime() - a.created.getTime()) // Reverse sort by date
      .filter((t) => {
        return (
          (!search.playerIDs ||
            (search.playerIDs && search.playerIDs.length === 0) ||
            (search.playerIDs && search.playerIDs.every((i) => t.playerIDs.includes(i)))) &&
          (!search.gameID || t.gameID === search.gameID) &&
          (!search.itemID || t.itemID === search.itemID) &&
          (!search.isMedia || t.isMedia === search.isMedia)
        )
      })
  },
}
// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
  state: Ref<State>
}
export function useGameStore(): ServiceInterface {
  return {
    getters,
    actions,
    state,
  }
}

export type GameStoreType = ReturnType<typeof useGameStore>
