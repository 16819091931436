import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4365535c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col justify-evenly w-full h-full sl-intro-background text-center text-white" }
const _hoisted_2 = {
  key: 0,
  class: "text-sm uppercase"
}
const _hoisted_3 = { class: "flex flex-row justify-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mt-12 text-5xl font-bold max-w-lg text-center m-auto" }
const _hoisted_6 = { class: "text-md mt-8" }
const _hoisted_7 = { class: "flex flex-row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.exerciseIndex)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createCommentVNode("{{ exerciseIndex }}")
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "h-96",
          src: _ctx.image,
          style: _normalizeStyle({ height: _ctx.imageHeight }),
          alt: "intoduction main image"
        }, null, 12 /* STYLE, PROPS */, _hoisted_4)
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.title), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1 /* TEXT */)
    ]),
    _createCommentVNode(" Get started button goes here"),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}