
import { defineComponent, onMounted } from 'vue'

import hmmSound from '@/assets/tasks/horerduordet/739_hmm_ordet_hørerduordet_alex.mp3'
import varDerSound from '@/assets/tasks/horerduordet/741_hørerduordet_ordetvarder_alex.mp3'
import youpiImage from '@/assets/tasks/horerduordet/party.svg'
import bgTextImage from '@/assets/tasks/sevenseas/Flat_3.svg'

export default defineComponent({
  name: 'CelebratePage',
  props: {
    mode: {
      type: String,
      default: 'straight',
    },
    conclusionAudio: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    onMounted(() => {
      if (props.conclusionAudio) new Audio(props.conclusionAudio).play()
      else if (props.mode === 'zigzag') {
        new Audio(varDerSound).play()
      } else {
        new Audio(hmmSound).play()
      }
    })
    const options = {
      fullScreen: {
        enable: true,
      },
      particles: {
        number: {
          value: 0,
        },
        color: {
          value: ['#00FFFC', '#FC00FF', '#fffc00'],
        },
        shape: {
          type: ['circle', 'square'],
        },
        opacity: {
          value: 1,
          animation: {
            enable: true,
            minimumValue: 0,
            speed: 2,
            startValue: 'max',
            destroy: 'min',
          },
        },
        size: {
          value: 6,
          random: {
            enable: true,
            minimumValue: 2,
          },
        },
        links: {
          enable: false,
        },
        life: {
          duration: {
            sync: true,
            value: 8,
          },
          count: 1,
        },
        move: {
          enable: true,
          gravity: {
            enable: true,
            acceleration: 10,
          },
          speed: {
            min: 10,
            max: 20,
          },
          decay: 0.1,
          direction: 'none',
          straight: false,
          outModes: {
            default: 'destroy',
            top: 'none',
          },
        },
        rotate: {
          value: {
            min: 0,
            max: 360,
          },
          direction: 'random',
          move: true,
          animation: {
            enable: true,
            speed: 60,
          },
        },
        tilt: {
          direction: 'random',
          enable: true,
          move: true,
          value: {
            min: 0,
            max: 360,
          },
          animation: {
            enable: true,
            speed: 60,
          },
        },
        roll: {
          darken: {
            enable: true,
            value: 25,
          },
          enable: true,
          speed: {
            min: 15,
            max: 25,
          },
        },
        wobble: {
          distance: 30,
          enable: true,
          move: true,
          speed: {
            min: -15,
            max: 15,
          },
        },
      },
      emitters: {
        direction: 'none',
        domId: 'btnSingleConfetti',
        life: {
          count: 3,
          duration: 0.1,
          delay: 0.8,
        },
        rate: {
          delay: 0.1,
          quantity: 150,
        },
        size: {
          width: 0,
          height: 0,
        },
      },
    }

    return {
      options,
      youpiImage,
      bgTextImage,
    }
  },
})
