import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full bg-gray-background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.disableDelays)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "absolute right-2 top-2 z-50 bg-white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.completed(true)))
        }, "Complete"))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.selectedQuestion)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedQuestion.__typename), {
          id: _ctx.selectedQuestion._id,
          ref: "selectedQuestionTemplateRef",
          key: _ctx.selectedQuestion._id,
          question: _ctx.selectedQuestion,
          set: _ctx.selectedSet,
          "my-index": _ctx.questionIndex,
          onSetupAudioIntroduction: _ctx.setupAudioIntroduction,
          onStartIntroduction: _ctx.startIntroduction,
          onDisableInteraction: _ctx.disableUserInteraction,
          onShowInternalForward: _ctx.showInternalForward,
          onCompleted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.completed(true))),
          onNotCompleted: _cache[2] || (_cache[2] = ($event: any) => (_ctx.completed(false)))
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["id", "question", "set", "my-index", "onSetupAudioIntroduction", "onStartIntroduction", "onDisableInteraction", "onShowInternalForward"]))
      : _createCommentVNode("v-if", true)
  ]))
}