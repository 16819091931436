import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ddcb54c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex flex-row h-64 justify-center",
  style: {"background-color":"#1e56b6"}
}
const _hoisted_2 = { class: "h-32 flex flex-col mt-6" }
const _hoisted_3 = { class: "flex flex-row align-middle items-center" }
const _hoisted_4 = { class: "rounded-full bg-green-highlight p-2 text-white text-xl h-12 w-12 text-center font-bold" }
const _hoisted_5 = { class: "text-2xl text-white pl-2" }
const _hoisted_6 = { class: "flex flex-row mt-2" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex flex-col flex-grow relative place-content-center justify-between h-full bg-no-repeat bg-bottom" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "text-white text-6xl font-playful font-bold text-center mt-12" }
const _hoisted_12 = { class: "mb-8 flex flex-row justify-center z-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative flex flex-col w-full h-full overflow-hidden",
    style: _normalizeStyle({ 'background-color': $props.omarWasCorrect ? '#fd8cb7' : '#fda13a' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString($setup.totalCorrect), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_5, _toDisplayString($setup.t('correctGuesses')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.answerImages, (a, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'answer-' + i,
              class: "h-24 w-24 relative pl-2"
            }, [
              _createElementVNode("img", {
                src: a?.src,
                class: "h-24"
              }, null, 8 /* PROPS */, _hoisted_7),
              a
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["w-8 h-8 p-2 rounded-full absolute -top-2 -right-2", a.correct ? 'bg-sl-green-button' : 'bg-red-button'])
                  }, [
                    _createElementVNode("img", {
                      src: a.correctSrc
                    }, null, 8 /* PROPS */, _hoisted_8)
                  ], 2 /* CLASS */))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("img", {
        class: "absolute -bottom-10 left-1/2 transform -translate-x-1/2 w-80",
        src: $props.omarWasCorrect ? $setup.omarYummyImage : $setup.omarHappyGlassesImage
      }, null, 8 /* PROPS */, _hoisted_10),
      _createElementVNode("p", _hoisted_11, _toDisplayString($props.omarWasCorrect ? $setup.t('omarWasRight') : $setup.t('omarWasWrong')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_12, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 4 /* STYLE */))
}