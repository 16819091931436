
import { defineComponent, ref, Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { AvatarLayout, Player } from '@/types/main'
import Avatar from '@/components/base/Avatar.vue'
import ItemSelection from '@/components/base/ItemSelection.vue'
import SlButton from '@/components/base/SlButton.vue'
import Button from '@/components/base/Button.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import { useGameStore } from '@/store/useGameStore'
import { useUserStore } from '@/store/useUserStore'
import empty from '@/assets/avatar/empty.svg'

const messages = {
  no: {
    selectPlayers: 'Velg spillere å rediger',
    playersInGame: 'Deltager i dette spillet',
    otherPlayersAvailable: 'Andre spillere tilgjengelig',
    newPlayer: 'Ny spliller',
    begin: 'Begynne!',
    whatsYourName: 'Hva heter du?',
    eyeShapes: 'Øyeformer',
    eyeColour: 'Øyefarge',
    skinColour: 'Hudfarge',
    lipColour: 'Leppefarge',
    hairShapes: 'Hårformer',
    hairColour: 'Hårfarge',
    nose: 'Nese',
    accessories: 'Tilbehør',
    background: 'Bakgrunn',
    customise: 'Tilpass avataren din',
  },
  en: {
    selectPlayers: 'Select a Player to edit',
    playersInGame: 'Players in this Game',
    otherPlayersAvailable: 'Other players available',
    newPlayer: 'New player',
    begin: 'Begin!',
    whatsYourName: 'What is your name?',
    eyeShapes: 'Eye shapes',
    eyeColour: 'Eye colour',
    skinColour: 'Skin colour',
    lipColour: 'Lip colour',
    hairShapes: 'Hair shapes',
    hairColour: 'Hair colour',
    nose: 'Nose',
    accessories: 'Accessories',
    background: 'Background',
    customise: 'Customise your avatar',
  },
}

export default defineComponent({
  name: 'AvatarCreator',
  components: {
    Avatar,
    ItemSelection,
    SlButton,
    Button,
    AnswerInput,
  },
  setup() {
    const { t } = useI18n({ messages })
    const { actions: gameActions, getters: gameGetters } = useGameStore()
    const { getters: userGetters } = useUserStore()
    const page = ref(0)
    const mode = ref('select')
    const name = ref('')
    const editDetailsPlayer: Ref<Player> = ref(new Player())
    const selectedPlayer = gameGetters.selectedPlayer
    const emptyImage = empty
    const transitionName = ref('slidenext')
    const user = userGetters.selectedUser
    let layout: Ref<AvatarLayout> = ref({
      eyeShape: '0',
      eyeColour: '#000000',
      hairShape: '13',
      hairColour: '#010300',
      skinColour: '#EFD5CE',
      noseShape: '2',
      lipColour: '#000000',
      accessories: '13',
      backgroundColour: '',
    })

    const eyeShapes = [require('@/assets/avatar/eyes/1.svg'), require('@/assets/avatar/eyes/2.svg'), require('@/assets/avatar/eyes/3.svg')]
    const eyeColours = ['#000000', '#485B30', '#00A03D', '#045eb9', '#459EFA', '#97b8d8', '#E2C372', '#714D2E']
    const hairShapes = [
      require('@/assets/avatar/hair/1.svg'),
      require('@/assets/avatar/hair/2.svg'),
      require('@/assets/avatar/hair/13.svg'),
      require('@/assets/avatar/hair/4.svg'),
      require('@/assets/avatar/hair/5.svg'),
      require('@/assets/avatar/hair/6.svg'),
      require('@/assets/avatar/hair/7.svg'),
      require('@/assets/avatar/hair/8.svg'),
      require('@/assets/avatar/hair/9.svg'),
      // require('@/assets/avatar/hair/10.svg'),
      require('@/assets/avatar/hair/12.svg'),
      require('@/assets/avatar/hair/11.svg'),
      // require('@/assets/avatar/hair/3.svg'),
      require('@/assets/avatar/empty.svg'),
    ]
    const hairColours = ['#010300', '#995521', '#FFDD33', '#F9A10B']
    const skinColours = ['#EFD5CE', '#FFC9AD', '#F3BBB4', '#D69081', '#E2C372', '#c49c78', '#C08549', '#714C2E', '#AC7967', '#5D543C']
    const lipColours = ['#000000', '#EA2F27']
    const noseShapes = [require('@/assets/avatar/nose/1.svg'), require('@/assets/avatar/nose/2.svg'), require('@/assets/avatar/empty.svg')]
    const accessories = [
      require('@/assets/avatar/accessories/1.svg'),
      require('@/assets/avatar/accessories/2.svg'),
      require('@/assets/avatar/accessories/3.svg'),
      require('@/assets/avatar/accessories/4.svg'),
      require('@/assets/avatar/accessories/5.svg'),
      require('@/assets/avatar/accessories/12.svg'),
      require('@/assets/avatar/accessories/13.svg'),
      require('@/assets/avatar/accessories/8.svg'),
      require('@/assets/avatar/accessories/9.svg'),
      require('@/assets/avatar/accessories/10.svg'),
      require('@/assets/avatar/accessories/11.svg'),
      // require('@/assets/avatar/accessories/6.svg'),
      // require('@/assets/avatar/accessories/7.svg'),
      require('@/assets/avatar/empty.svg'),
    ]
    const backgrounds = ['#ffff1f', '#ffd21f', '#ffccdf', '#ff8ab7', '#e44f2e', '#a66d6a', '#66ad5f', '#99ad5f', '#4286fa']

    const setStyle = (type: keyof AvatarLayout, choice: string | number) => {
      layout.value[type] = choice.toString()
    }

    function selectplayer(p: Player) {
      gameActions.selectPlayer(p)
      name.value = p.profile.name
      layout.value = Object.assign({}, p.profile.avatar)
    }
    function editDetails(p: Player) {
      editDetailsPlayer.value = p
    }

    const filteredAccessories = computed(() => {
      const acc = accessories.filter((a, i) => !gameGetters.players.value.some((p) => p.profile.avatar.accessories === i.toString()))
      console.log(acc)
      return acc
    })

    const accessoryAlreadyInUse = (a: number) => gameGetters.players.value.some((p) => p.profile.avatar.accessories === a.toString())

    const forward = () => {
      transitionName.value = 'slidenext'
      if (mode.value == 'select') {
        mode.value = user.value.status.canEditPlayers ? 'name' : 'avatar'
      } else if (mode.value == 'name') {
        mode.value = 'avatar'
      } else {
        if (page.value === 4 && selectedPlayer.value) {
          const newPlayer = new Player(selectedPlayer.value)
          newPlayer.profile.avatar = layout.value
          newPlayer.profile.name = name.value
          gameActions.updatePlayer(newPlayer)
          mode.value = 'select'
          page.value = 0
        } else {
          page.value++
        }
      }
    }
    const back = () => {
      transitionName.value = 'slideprev'
      if (mode.value == 'select') {
        router.push('/dashboard/games')
      } else if (mode.value == 'name') {
        mode.value = 'select'
      } else if (mode.value == 'avatar' && page.value == 0) {
        mode.value = user.value.status.canEditPlayers ? 'name' : 'select'
      } else {
        page.value--
      }
    }

    function newPlayer() {
      gameActions.addPlayer()
    }

    gameActions.selectPlayer()

    return {
      t,
      layout,
      page,
      mode,
      accessories,
      eyeColours,
      eyeShapes,
      skinColours,
      noseShapes,
      hairShapes,
      hairColours,
      lipColours,
      backgrounds,

      setStyle,
      transitionName,
      name,
      emptyImage,

      players: gameGetters.players,
      filteredAccessories,
      accessoryAlreadyInUse,
      user,
      selectplayer,
      editDetails,
      editDetailsPlayer,
      selectedPlayer,

      back,
      forward,
      newPlayer,
    }
  },
})
