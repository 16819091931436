/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, h } from 'vue'
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw, RouteLocation } from 'vue-router'
import { PROJECT_TYPE, baseUrl } from '@/constants'
import { EnumDictionary } from '@/types/main'
import { useUserStore } from '@/store/useUserStore'

import Landing from '../views/landing/Landing.vue'
import AfterLogin from '../views/landing/AfterLogin.vue'

import Monitor from '../views/admin/Monitor.vue'
import MonitorProfile from '../views/admin/MonitorProfile.vue'
import MonitorProjects from '../views/admin/MonitorProjects.vue'
import MonitorGroups from '../views/admin/MonitorGroups.vue'
import MonitorGames from '../views/admin/MonitorGames.vue'
import MonitorLogs from '../views/admin/MonitorLogs.vue'

import SLPLUS_Dashboard from '../templates/slplus/views/Dashboard.vue'
import SLPLUS_Games from '../templates/slplus/views/SelectGame.vue'
import SLPLUS_Players from '../templates/slplus/views/SelectPlayers.vue'
import SLPLUS_Layout from '../templates/slplus/views/Layout.vue'
import SLPLUS_AvatarCreator from '../templates/slplus/views/AvatarCreator.vue'
import SLPLUS_Question from '../templates/slplus/views/Question.vue'
import SLPLUS_MonitorMastery from '../templates/slplus/views/admin/MonitorMastery.vue'

const { getters: userGetters } = useUserStore()
let userProjectType = PROJECT_TYPE.none

interface ComponentBlock {
  dashboard: Component
  games: Component
  players: Component
  layout: Component
  avatar: Component
  question: Component
  mastery: Component
}

const projects: EnumDictionary<PROJECT_TYPE, ComponentBlock> = {
  [PROJECT_TYPE.slplus]: {
    dashboard: SLPLUS_Dashboard,
    games: SLPLUS_Games,
    players: SLPLUS_Players,
    layout: SLPLUS_Layout,
    avatar: SLPLUS_AvatarCreator,
    question: SLPLUS_Question,
    mastery: SLPLUS_MonitorMastery,
  },
  [PROJECT_TYPE.kapteinmorf]: {
    dashboard: SLPLUS_Dashboard,
    games: SLPLUS_Games,
    players: SLPLUS_Players,
    layout: SLPLUS_Layout,
    avatar: SLPLUS_AvatarCreator,
    question: SLPLUS_Question,
    mastery: SLPLUS_MonitorMastery,
  },
  [PROJECT_TYPE.none]: {
    dashboard: SLPLUS_Dashboard,
    games: SLPLUS_Games,
    players: SLPLUS_Players,
    layout: SLPLUS_Layout,
    avatar: SLPLUS_AvatarCreator,
    question: SLPLUS_Question,
    mastery: SLPLUS_MonitorMastery,
  },
}
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
  },
  { path: '/index.html', redirect: '/' },
  {
    path: '/postlogin',
    name: 'Login',
    component: AfterLogin,
  },
  {
    path: '/monitor',
    redirect: '/monitor/profile',
    name: 'Monitor',
    component: Monitor,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/profile is matched
        path: 'profile',
        name: 'MonitorProfile',
        component: MonitorProfile,
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/groups is matched
        path: 'groups',
        name: 'MonitorGroups',
        component: MonitorGroups,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/games is matched
        path: 'games',
        name: 'MonitorGames',
        component: MonitorGames,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/projects is matched
        path: 'projects',
        name: 'MonitorProjects',
        component: MonitorProjects,
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/projects is matched
        path: 'mastery',
        name: 'MonitorMastery',
        component: {
          render() {
            const element = projects[userProjectType].mastery
            return h(element)
          },
        },
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/projects is matched
        path: 'logs',
        name: 'MonitorLogs',
        component: MonitorLogs,
      },
    ],
  },
  {
    path: '/dashboard',
    redirect: '/dashboard/games',
    name: 'Dashboard',
    component: {
      render() {
        const element = projects[userProjectType].dashboard
        return h(element)
      },
    },
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/profile is matched
        path: 'layout/:reloaded?',
        name: 'Layout',
        component: {
          render() {
            const element = projects[userProjectType].layout
            return h(element)
          },
        },
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/groups is matched
        path: 'games',
        name: 'Games',
        component: {
          render() {
            const element = projects[userProjectType].games
            return h(element)
          },
        },
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/games is matched
        path: 'players',
        name: 'Players',
        component: {
          render() {
            const element = projects[userProjectType].players
            return h(element)
          },
        },
      },
    ],
  },
  {
    path: '/avatar',
    name: 'Avatar',
    component: {
      render() {
        const element = projects[userProjectType].avatar
        return h(element)
      },
    },
  },
  {
    path: '/question/:projecttype?/:projectname?/:schema?/:id?/:language?',
    props: (route) => {
      return {
        projecttype: route.params.projecttype,
        projectname: route.params.projectname,
        schema: route.params.schema,
        id: route.params.id,
        language: route.params.language,
      }
    },
    name: 'Question',
    component: {
      render() {
        const element = projects[userProjectType].question
        return h(element)
      },
    },
  },
  {
    path: '/sample/:projecttype/:projectname/:schema/:id/:language',
    name: 'Sample',
    redirect: (to: RouteLocation) => {
      const projecttype = to.params.projecttype.toString()
      if (projecttype in PROJECT_TYPE) userProjectType = to.params.projecttype as PROJECT_TYPE
      return {
        name: 'Question',
        path: `/question/${to.params.projecttype}/${to.params.projectname}/${to.params.schema}/${to.params.id}/${to.params.language}`,
        params: to.params,
      }
    },
  },
]

const isEngagelab = baseUrl.includes('engagelab') || baseUrl.includes('dev.slplus')
const baseURL = baseUrl.includes('engagelab') ? process.env.BASE_URL : '/'
const routerType = isEngagelab // As engagelab server uses proxying for multiple apps, this is the easiest way..
  ? createWebHashHistory()
  : createWebHistory(baseURL)
const router = createRouter({
  history: routerType,
  routes,
})

router.beforeEach((to, from, next) => {
  const isSample = to.fullPath.includes('/sample/') || to.redirectedFrom?.fullPath.includes('/sample/')
  if (userProjectType === PROJECT_TYPE.none) userProjectType = userGetters.selectedUserProject.value.projectType
  if (!(from.name || to.redirectedFrom?.name) && to.name !== 'Login' && to.name !== 'Landing' && !isSample) {
    next({ name: 'Login' })
  } else if (to.name === 'Landing') {
    userProjectType = PROJECT_TYPE.none
    next()
  } else if (from.name === 'Sample') next({ name: 'Question', params: from.params })
  else next()
})

export default router
