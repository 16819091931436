import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@/assets/icons/svg/speaker-off.svg'
import _imports_1 from '@/assets/icons/svg/speaker.svg'


const _hoisted_1 = {
  class: "relative flex flex-row justify-center w-full h-full text-center text-white",
  style: {"background-color":"#b3e9ff"}
}
const _hoisted_2 = { class: "absolute top-6 left-12" }
const _hoisted_3 = { class: "relative flex flex-row items-center" }
const _hoisted_4 = {
  class: "rounded-l-full p-3 text-center flex flex-col justify-center h-12 -m-6 -mt-4 pl-6 pr-8",
  style: {"background-color":"#48b6ff"}
}
const _hoisted_5 = { class: "uppercase font-bold" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  class: "relative flex flex-col justify-center",
  style: {"width":"60vmin"}
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "relative" }
const _hoisted_10 = { class: "text-white text-lg md:text-5xl font-black p-8 md:p-16" }
const _hoisted_11 = { class: "relative text-left" }
const _hoisted_12 = {
  key: 0,
  class: "w-6 h-6",
  src: _imports_0,
  alt: "close"
}
const _hoisted_13 = {
  key: 1,
  class: "w-6 h-6",
  src: _imports_1,
  alt: "close"
}
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  class: "absolute bottom-5 z-20 right-6 rounded-full flex flex-col justify-center p-5 cursor-pointer",
  style: {"background-color":"#01c755"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Omar's lightbub placement for Word Focus mode "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('letsDiscuss')), 1 /* TEXT */)
        ]),
        _createElementVNode("img", {
          src: _ctx.omarChat,
          class: "z-10 w-16",
          alt: "Discussion Time icon"
        }, null, 8 /* PROPS */, _hoisted_6)
      ])
    ]),
    _createCommentVNode(" Main bubble and item image "),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", {
        class: "absolute pointer-events-none w-full bg-center bg-no-repeat",
        style: _normalizeStyle([{"top":"20%"}, { backgroundImage: 'url(' + _ctx.bgFishedImage + ')' }])
      }, [
        _createElementVNode("img", {
          src: _ctx.item.image,
          class: "p-24 md:p-32 lg:p-36",
          alt: "item image"
        }, null, 8 /* PROPS */, _hoisted_8)
      ], 4 /* STYLE */),
      _createCommentVNode(" Text bubble "),
      _createElementVNode("div", {
        class: "absolute z-10 pointer-events-none w-full bg-no-repeat bg-center",
        style: _normalizeStyle([{"top":"15%"}, { backgroundImage: 'url(' + _ctx.bgTextImageBlue + ')' }])
      }, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.word), 1 /* TEXT */)
        ])
      ], 4 /* STYLE */)
    ]),
    _createCommentVNode(" Teacher guidance "),
    _createElementVNode("div", {
      class: "absolute z-10 pointer-events-none bg-left bg-no-repeat bg-fill p-4 bottom-2 left-1/2 transform -translate-x-1/2",
      style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.bgTextImage + ')' })
    }, [
      _createElementVNode("div", _hoisted_11, [
        _createCommentVNode(" Sound replay button "),
        _createVNode(_component_Button, {
          class: "absolute pointer-events-auto bg-white p-2 rounded-full -top-6 -right-6",
          childclass: 'w-12 h-12',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.soundIsPlaying ? _ctx.stopSounds() : _ctx.playSoundAgain()))
        }, {
          default: _withCtx(() => [
            (_ctx.soundIsPlaying)
              ? (_openBlock(), _createElementBlock("img", _hoisted_12))
              : (_openBlock(), _createElementBlock("img", _hoisted_13))
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", {
          class: "text-black text-sm p-4",
          innerHTML: _ctx.item.guidance
        }, null, 8 /* PROPS */, _hoisted_14)
      ])
    ], 4 /* STYLE */),
    _createCommentVNode(" Avatars "),
    (_ctx.players.length >= 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "absolute pointer-events-none bg-center bg-no-repeat top-64 left-28",
          style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.bgAvatar1 + ')' })
        }, [
          _createVNode(_component_Avatar, {
            layout: _ctx.players[0].profile.avatar,
            class: "w-44 h-44"
          }, null, 8 /* PROPS */, ["layout"])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    (_ctx.players.length >= 3)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "absolute pointer-events-none bg-center bg-no-repeat bottom-32 left-32",
          style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.bgAvatar2 + ')' })
        }, [
          _createVNode(_component_Avatar, {
            layout: _ctx.players[2].profile.avatar,
            class: "w-44 h-44"
          }, null, 8 /* PROPS */, ["layout"])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    (_ctx.players.length >= 4)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "absolute pointer-events-none bg-center bg-no-repeat top-64 right-32",
          style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.bgAvatar3 + ')' })
        }, [
          _createVNode(_component_Avatar, {
            layout: _ctx.players[3].profile.avatar,
            class: "w-44 h-44"
          }, null, 8 /* PROPS */, ["layout"])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    (_ctx.players.length >= 2)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "absolute pointer-events-none bg-center bg-no-repeat bottom-32 right-48",
          style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.bgAvatar4 + ')' })
        }, [
          _createVNode(_component_Avatar, {
            layout: _ctx.players[1].profile.avatar,
            class: "w-44 h-44"
          }, null, 8 /* PROPS */, ["layout"])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_15, [
      _renderSlot(_ctx.$slots, "default", { class: "pointer-events-none text-lg text-white capitalize" })
    ])
  ]))
}