
import { defineComponent, PropType, computed, Ref, ref } from 'vue'
import { Question, Sett } from '@/types/main'
import { taskColours } from '@/constants'
import { Picturebook } from '@/templates/slplus/structure/picturebookModels'
import { useDeviceService } from '@/composition/useDevice'
import PulseAnimation from '@/components/base/PulseAnimation.vue'
import BookImage from '@/assets/icons/svg/book.svg'
import MasteryImage from '@/assets/icons/svg/mastery.svg'
const { actions: deviceActions } = useDeviceService()

export default defineComponent({
  components: {
    PulseAnimation,
  },
  props: {
    data: { type: Object as PropType<Picturebook | Question | Sett>, required: true },
    index: { type: Number, default: -1 },
    disabled: Boolean,
    completed: Boolean,
    level: { type: Number, default: 0 },
  },
  emits: ['clickgriditem'],
  setup(props, context) {
    let thumbnail: Ref<string | ArrayBuffer> = ref('')

    const getThumbnail = async (url: string) => {
      const media = await deviceActions.getCachedMedia(url)
      thumbnail.value = media
    }
    if (props.data?.thumbnail) {
      getThumbnail(props.data?.thumbnail)
    } else thumbnail.value = ''

    function click() {
      if (!props.disabled) {
        context.emit('clickgriditem', { item: props.data, index: props.index })
      }
    }
    const backgroundThumbnail = computed(() => {
      return `background-image: url(${thumbnail.value})`
    })
    const backgroundSecondLevel = computed(() => {
      if (props.completed) return 'bg-teal-mediumbackground'
      else if (props.disabled) return 'bg-gray-disabled'
      else return 'bg-blue-button'
    })
    const randomTaskColor = taskColours[Math.floor(Math.random() * taskColours.length)]
    const itemType = computed(() => {
      if (props.data instanceof Picturebook) {
        return 'picturebook'
      } else if (props.data instanceof Sett) {
        return 'sett'
      } else return 'question'
    })

    return {
      Sett,
      Question,
      itemType,
      click,
      backgroundThumbnail,
      backgroundSecondLevel,
      randomTaskColor,
      BookImage,
      MasteryImage,
    }
  },
})
