import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a899dbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "triangle ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-24 w-24 rounded-full flex flex-row justify-center items-center", { 'border-2 border-solid border-gray-500': !_ctx.transparent }])
  }, [
    _createElementVNode("div", {
      class: "trans flex flex-row justify-center items-center",
      style: _normalizeStyle(_ctx.buttonStyle),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchMode(true)))
    }, [
      (_ctx.buttonMode === _ctx.MODES.play)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("v-if", true)
    ], 4 /* STYLE */)
  ], 2 /* CLASS */))
}